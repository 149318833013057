import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Section from './Section';
import VideoBackground from './VideoBackground';
import Fade from 'react-reveal/Fade';
import SobreCarousel from './SobreCarousel';
import ClientsPartnersSection from '../Screens/Home/ClientsPartnersSection';  // Novo componente importado
import UpdatesSection from '../Screens/Home/UpdatesSection';
import Footer from '../Screens/Home/Footer';

const Home = () => {
  const navigate = useNavigate();
  // Criando uma referência para a seção de produtos
  const productsSectionRef = useRef(null);

  const [videoUrl, setVideoUrl] = useState('https://storage.googleapis.com/videos-aer/Principal/vitrine.mp4');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [backgroundImage, setBackgroundImage] = useState('https://storage.googleapis.com/videos-aer/Principal/inicio.png'); // Adicione a URL da imagem aqui

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Executa ao carregar e adiciona o listener para redimensionamento
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleExploreClick = () => {
    productsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleViewProduct = (category) => {
    let productName = '';
    switch (category) {
      case 'Balcões':
        productName = 'BALCÃO PORTO HORIZONTAL';
        break;
      //case 'Estantes':
      //productName = 'DEPOSITO';
      //break;
      case 'Expositores':
        productName = 'EXPOSITOR PORTO VISEU';
        break;
      case 'Vitrines':
        productName = 'VITRINE PORTO GALICIA';
        break;
      default:
        productName = ''; // Caso não encontre
    }

    if (productName) {
      // Rola a página para o topo
      window.scrollTo(0, 0);

      // Redireciona para a página do produto específico
      navigate(`/product/${productName}`);
    }
  };

  const handleArrowClick = () => {
    const section = productsSectionRef.current;
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  };


  return (
    <Container>
      <HeroSectionContainer>
        <HeroSection>
          <Fade bottom>
            <TextContainer>
              <SmallTitle>Soluções em Refrigeração</SmallTitle>
              <Title>Inovação que Transforma seu Negócio</Title>
              <Button onClick={handleExploreClick}>Explorar</Button>
            </TextContainer>
          </Fade>
        </HeroSection>

        <VideoBackgroundWrapper>
          {isMobile ? (
            <BackgroundImage src={backgroundImage} alt="Background" />
          ) : (
            <VideoBackgroundStyled
              src="https://storage.googleapis.com/videos-aer/Principal/vitrine.mp4"
              autoPlay
              muted
              loop
            />
          )}
          <DarkOverlay />
          <DownArrow src="/images/down-arrow.svg" alt="Down Arrow" onClick={handleExploreClick} />
        </VideoBackgroundWrapper>
        <BlackOverlay />
      </HeroSectionContainer>

      {/* Seção de Produtos */}
      <SectionContainer ref={productsSectionRef}>
        <Fade bottom>
          <SectionTitleDest>Produtos em Destaque</SectionTitleDest>
        </Fade>
        {sections.map((section, index) => (
          <WhiteBackground key={index}>
            <Row>
              {section.items.map((item, idx) => (
                <Fade bottom key={idx}>
                  <HalfSection>
                    <ImageWrapper>
                      <ProductImage src={item.image} alt={item.title} />
                      <GradientOverlay />
                      <TextWrapper>
                        <ProductTitle>{item.title}</ProductTitle>
                        <ProductDescription>{item.description}</ProductDescription>
                        <ButtonWrapper>
                          <ProductButtonFilled onClick={() => handleViewProduct(item.title)}>
                            {item.leftBtn}
                          </ProductButtonFilled>
                        </ButtonWrapper>
                      </TextWrapper>
                    </ImageWrapper>
                  </HalfSection>
                </Fade>
              ))}
            </Row>


          </WhiteBackground>
        ))}
      </SectionContainer>

      <SectionContainer>
        <SobreCarousel />
      </SectionContainer>

      {/* Clientes e Parceiros */}
      <SectionContainer2>
        <ClientsPartnersSection /> {/* Novo componente aqui */}
      </SectionContainer2>

      {/* Atualizações */}
      <Fade bottom>
        <SectionContainer3>
          <UpdatesSection />
        </SectionContainer3>
      </Fade>

      {/* Rodapé com Contato */}
      <SectionContainer4>
        <Footer />
      </SectionContainer4>
    </Container>
  );
};

export default Home;


// Styled Components and Animations


const fadeOutOverlay = keyframes`
  0% {
    opacity: 1;  // Começa com o fundo completamente preto
  }
  100% {
    opacity: 0;  // Fica totalmente transparente, revelando o vídeo
  }
`;

// Defina a animação de fade-in suave para o vídeo
const fadeInVideo = keyframes`
  0% {
    opacity: 0;  // Começa invisível
  }
  100% {
    opacity: 1;  // Termina completamente visível
  }
`;

const fadeInAndBounce = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);  // Começa mais abaixo e invisível
  }
  50% {
    opacity: 1;
    transform: translateY(0);  // Fade in e chega na posição original
  }
  70% {
    transform: translateY(-10px);  // Sobe um pouco para o efeito de pulo
  }
  100% {
    transform: translateY(0);  // Volta à posição original
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const animateDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  

`;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;  // Alinha os elementos à esquerda
  height: 100vh;
  text-align: left;
  color: white;
  z-index: 2;
  padding-left: 50px;  // Certifique-se que há espaço da margem esquerda
`;



const HeroSectionContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-family: 'Brmalls';
  z-index: 3;
  padding-left: 20px;
  margin-top: 10vh; /* Adiciona espaço superior para posicionar o texto mais para cima */

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-left: 0;
    margin-top: -35vh; /* Ajusta o espaço superior no mobile */
    margin-right: 5vh;
  }
`;


const SmallTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: 'Ginza Heavy', sans-serif;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
`;


const VideoBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  overflow: hidden;
  background-color: black;
`;

const VideoBackgroundStyled = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    object-fit: cover;
  }
`;


const BlackOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;  // Começa com um fundo preto
  z-index: 1;  // Fica acima do vídeo
  pointer-events: none;
  animation: ${fadeOutOverlay} 2s ease-in-out forwards;  // Aplica a animação de fade-out
`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`;

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Escurece o fundo para destacar o texto */
  z-index: 1;
  pointer-events: none; /* Isso garante que ele não bloqueie cliques */
`;

const Button = styled.div`
  background-color: rgba(23, 26, 32, 0.8);
  height: 40px;
  width: 120px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  opacity: 0.85;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Brmalls';
  margin-top: 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 15px;
    height: 35px;
    width: 100px;
    font-size: 10px;
  }
`;

const DownArrow = styled.img`
  height: 50px;
  position: absolute;
  bottom: 20px; /* Posiciona a seta perto da parte inferior da tela */
  left: 48.9%;
  transform: translateX(-50%);
  animation: ${animateDown} infinite 1.5s;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto; /* Permite cliques */
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  width: 100%;
  margin-top: 50px; /* Diminui a margem superior */
  font-family: 'Brmalls';
`;


const SectionContainer2 = styled.div`
  margin-top: 120px;

`;
const SectionContainer3 = styled.div`
  margin-top: 120px;

`;

const SectionContainer4 = styled.div`
  margin-top: 5px;
`;

const WhiteBackground = styled.div`
  background-color: white;
  padding: 50px 0;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colunas para desktop */
  gap: 20px;
  grid-row-gap: 40px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Altera para 1 coluna em telas menores */
    padding: 10px;
  }
`;

const SectionTitleDest = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-top: 60px;

  margin-bottom: 40px; /* Adiciona um espaçamento maior entre o título e os produtos */
  font-family: 'Ginza Heavy', sans-serif;
`;


const HalfSection = styled.div`
  position: relative;
  width: 500px;
  height: 700px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%; /* Ajusta a largura para quase toda a tela em dispositivos móveis */
    height: auto; /* Ajusta a altura automaticamente */
    margin: 10px auto; /* Centraliza o item na tela */
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  color: white;
  padding: 10px;
`;

const ProductTitle = styled.h1`
  font-size: 1.2rem;
  margin: 5px 0;
  font-family: 'Brmalls', sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
const ProductDescription = styled.p`
  font-size: 0.9rem;
  margin: 5px 0;
  font-family: 'Brmalls', sans-serif;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

const ProductButton = styled.button`
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Brmalls', sans-serif;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const ProductButtonFilled = styled.button`
  background-color: #fff;
  color: #000;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Brmalls', sans-serif;
  font-size: 1rem;

  &:hover {
    background-color: #e6e6e6;
  }

  @media (max-width: 768px) {
    padding: 10px 15px; /* Ajusta o padding para telas menores */
    font-size: 0.9rem; /* Reduz o tamanho da fonte em telas menores */
  }
`;

const ProductButtonOutlined = styled.button`
  background-color: transparent; /* Fundo transparente */
  color: #fff; /* Texto branco */
  border: 2px solid #fff; /* Borda branca */
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Brmalls', sans-serif;
  font-size: 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Leve fundo branco transparente ao hover */
  }
`;


const sections = [
  {
    items: [
      {
        title: 'Vitrines',
        description: 'Design inovador para refrigerar e expor.',
        image: '/images/image/vitrine_home.png',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },
      {
        title: 'Expositores',
        description: 'Elegante e acessível para destacar produtos.',
        image: '/images/image/NextSection/Expositor/Viseu/sobre.png',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },
      {
        title: 'Balcões',
        description: 'Moderno e funcional para exibir produtos.',
        image: '/images/image/NextSection/Balcao/boxhz/2.png',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },
      // {
      //   title: 'Estantes',
      //   description: 'Estrutura forte para armazenagem eficiente.',
      //   image: '/images/image/img2.jpg',
      //   leftBtn: 'Visualizar produto',
      //   rightBtn: 'Ver detalhes',
      // },


    ],
  },
];


