import React, { useState, useEffect } from 'react';
import { useGLTF, Environment } from '@react-three/drei';
import * as THREE from 'three';

export function Vitrine({ color }) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Vitrines/PP/12.glb');

  // Estado para rastrear se a tela está em resolução de celular
  const [isMobile, setIsMobile] = useState(false);

  // Hook para verificar o tamanho da tela
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Definindo a largura para determinar resolução de celular
    };

    // Verificação inicial e adicionar listener para resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remover listener ao desmontar o componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Atualizando a cor do material com `useEffect` para evitar re-renderizações desnecessárias
  useEffect(() => {
    if (materials['black low gloss plastic']) {
      materials['black low gloss plastic'].color = new THREE.Color(color);
    }
  }, [color, materials]);

  // Calculando o centro do objeto para garantir que ele esteja centralizado
  const boundingBox = new THREE.Box3().setFromObject(nodes.Mesh_0);
  const center = boundingBox.getCenter(new THREE.Vector3());

  // Ajuste do eixo y dependendo da resolução
  const yOffset = isMobile ? center.y - 1.3 : center.y - 0.2; // Maior deslocamento em resolução de celular

  return (
    <group position={[-center.x - 0.1, -yOffset, -center.z]}>
      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0.geometry}
          material={materials.Material_0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_1.geometry}
          material={materials['black low gloss plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_2.geometry}
          material={materials['red low gloss plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_3.geometry}
          material={materials['white low gloss plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_4.geometry}
          material={materials['light grey low gloss plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_5.geometry}
          material={materials['clear thick glass']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_6.geometry}
          material={materials['matte steel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_7.geometry}
          material={materials['beige low gloss plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_8.geometry}
          material={materials.color}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_9.geometry}
          material={materials['clear glass']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_10.geometry}
          material={materials['matte aluminum']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_11.geometry}
          material={materials['black low gloss plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0_12.geometry}
          material={materials['white led']}
        />
      </group>
    </group>
  );
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Vitrines/PP/12.glb');
