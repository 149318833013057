import React, { useState, useRef, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF, Environment, OrthographicCamera } from "@react-three/drei";
import { ChromePicker } from "react-color";
import * as THREE from 'three';
import { useSpring as useThreeSpring, animated as threeAnimated } from '@react-spring/three';
import { Box } from '@mui/material';
import { useSpring as useWebSpring, animated as webAnimated } from '@react-spring/web';
import { Vitrine } from "./jsx_glb/Vitrine";
import { Suspense } from "react";
import { Html } from "@react-three/drei";
import { GLTFLoader } from 'three-stdlib';
import { DRACOLoader } from 'three-stdlib';
import ColorWheel from "@uiw/react-color-wheel";
import { useMediaQuery } from 'react-responsive';
// Importações
import { BalcaoHz } from '../../assets/GLB/Balcao/BalcaoHz';
import { BalcaoVt } from '../../assets/GLB/Balcao/BalcaoVt';
import { Cremalheiras } from '../../assets/GLB/Estantes/Cremalheiras';
import { Deposito } from '../../assets/GLB/Estantes/Deposito';
import { Estocagem } from '../../assets/GLB/Estantes/Estocagem';
import { Higienizacao } from '../../assets/GLB/Estantes/Higienizacao';
import { ExpositorPV } from '../../assets/GLB/Expositores/ExpositorPV';
import { Buffet } from '../../assets/GLB/Mobiliario/Buffet';
import { Lavadora } from '../../assets/GLB/Mobiliario/Lavadora';
import { Lavatorio } from '../../assets/GLB/Mobiliario/Lavatorio';
import { Mesa } from '../../assets/GLB/Mobiliario/Mesa';
import { MesaCmPia } from '../../assets/GLB/Mobiliario/MesaCmPia';
import { MesaGav } from '../../assets/GLB/Mobiliario/MesaGav';
import { MesaLixo } from '../../assets/GLB/Mobiliario/MesaLixo';
import { PrateleiraP } from '../../assets/GLB/Mobiliario/PrateleiraP';
import { VitrinePG } from '../../assets/GLB/Vitrines/VitrinePG';
import { VitrinePP } from '../../assets/GLB/Vitrines/VitrinePP';


// Componente Urso - modelo GLB
export function Urso(props) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);

    const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Urso/ursobranco.glb'); // Verifique o caminho do GLB
    const groupRef = useRef();

    useFrame(() => {
        if (groupRef.current) {
            groupRef.current.rotation.y += 0.005; // Reduza a velocidade de rotação para algo mais pesado
        }
    });


    return (
        <group ref={groupRef} {...props} dispose={null} scale={[15, 15, 15]}>
          <OrthographicCamera
            makeDefault={false}
            far={100}
            near={0.01}
            position={[-1.24, 0.332, 3.052]}
            rotation={[-0.114, -0.359, 0.318]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.URSO_VIZUALIZADOR_3D.geometry}
            material={materials['white high gloss plastic']}
          />
        </group>
      );
    }

useGLTF.preload('https://storage.googleapis.com/glb-aer/Urso/ursobranco.glb'); // Pré-carregar o GLB

export default function Rotate({ setMenuVisible, selectedProduct }) {
    const [color, setColor] = useState("#ffffff");
    const [loaded, setLoaded] = useState(false);
    const [animated, setAnimated] = useState(false);
    const [lightsOn, setLightsOn] = useState(false);
    const [exiting, setExiting] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);
    const [interacting, setInteracting] = useState(false);
    const [cursorStyle, setCursorStyle] = useState("default");
    const [isInteracting, setIsInteracting] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const isMobile = window.innerWidth <= 768;
    const [inputColor, setInputColor] = useState("#000000");
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Foca no campo de entrada assim que o componente for montado
        }
    }, []); // Executa apenas na montagem

    const controls = useRef();



    let scrollPosition = 0; // Variável para armazenar a posição atual do scroll

    const productModels = {
        "VITRINE PORTO PARIS": (
            <Vitrine
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "VITRINE PORTO GALICIA": (
            <VitrinePG
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "BALCÃO PORTO VERTICAL": (
            <BalcaoVt
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "BALCÃO PORTO HORIZONTAL": (
            <BalcaoHz
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "DEPOSITO": (
            <Deposito
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "CREMALHEIRAS": (
            <Cremalheiras
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "ESTOCAGEM": (
            <Estocagem
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "HIGIENIZAÇÃO": (
            <Higienizacao
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "EXPOSITOR PORTO VISEU": (
            <ExpositorPV
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "BUFFET": (
            <Buffet
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "LAVADORA": (
            <Lavadora
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "LAVATORIO": (
            <Lavatorio
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "MESA": (
            <Mesa
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "MESA COM PIA": (
            <MesaCmPia
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "MESA GAVETEIRO": (
            <MesaGav
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "MESA LIXEIRA": (
            <MesaLixo
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        ),
        "PRATELEIRA DE PAREDE": (
            <PrateleiraP
                color={color}
                castShadow={true}
                receiveShadow={true}
            />
        )
    };

    // productTexts
    const productTexts = {
        "VITRINE PORTO PARIS": {
            title: "Vitrine",
            description: "Porto Paris"
        },
        "VITRINE PORTO GALICIA": {
            title: "Vitrine",
            description: "Porto Galicia"
        },
        "BALCÃO PORTO VERTICAL": {
            title: "Balcão",
            description: "Porto Vertical"
        },
        "BALCÃO PORTO HORIZONTAL": {
            title: "Balcão",
            description: "Porto Horizontal"
        },
        "DEPOSITO": {
            title: "Estantes",
            description: "Depósito"
        },
        "CREMALHEIRAS": {
            title: "Estantes",
            description: "Cremalheiras"
        },
        "ESTOCAGEM": {
            title: "Estantes",
            description: "Estocagem"
        },
        "HIGIENIZAÇÃO": {
            title: "Estantes",
            description: "Higienização"
        },
        "EXPOSITOR PORTO VISEU": {
            title: "Expositor",
            description: "Porto Viseu"
        },
        "BUFFET": {
            title: "Mobiliário",
            description: "Buffet"
        },
        "LAVADORA": {
            title: "Mobiliário",
            description: "Lavadora"
        },
        "LAVATORIO": {
            title: "Mobiliário",
            description: "Lavatorio"
        },
        "MESA": {
            title: "Mobiliário",
            description: "Mesa"
        },
        "MESA COM PIA": {
            title: "Mobiliário",
            description: "Mesa com Pia"
        },
        "MESA GAVETEIRO": {
            title: "Mobiliário",
            description: "Mesa Gaveteiro"
        },
        "MESA LIXEIRA": {
            title: "Mobiliário",
            description: "Mesa Lixeira"
        },
        "PRATELEIRA DE PAREDE": {
            title: "Mobiliário",
            description: "Prateleira de Parede"
        }
    };



    const selectedText = productTexts[selectedProduct] || { title: "Produto", description: "Descrição do produto" };


    // Animação de entrada e desaparecimento para o texto e o Color Picker

    const textAndPickerAnimation = useWebSpring({
        opacity: interacting || !lightsOn ? 0 : 1,  // Opacidade será 0 durante a interação ou enquanto as luzes não estiverem acesas
        config: { duration: interacting ? 150 : 300 },  // Fade in suave, fade out mais rápido
    });


    const textAndPickerOpacity = useWebSpring({
        opacity: isInteracting ? 0 : 1, // 0 quando o usuário interage (fade out), 1 quando não interage (fade in)
        config: { duration: 300 }, // Duração suave do fade
    });


    // Animação para o GLB (substituindo o Lottie)
    const glbAnimation = useWebSpring({
        opacity: fadeOut ? 0 : (showAnimation ? 1 : 0),
        transform: fadeOut ? 'translateY(30px)' : 'translateY(0px)',
        config: { duration: 1000 },
    });

    // Animação para a entrada (descida) da vitrine
    const entryAnimation = useThreeSpring({
        vitrinePosition: animated && !exiting ? [0, 0, 0] : [0, 10, 0],
        lightIntensity: lightsOn && !exiting ? 2 : 0,  // Quando as luzes acendem
        config: {
            mass: 2,
            tension: 180,
            friction: 70,
        },
        onRest: () => {
            if (!exiting) {
                setLightsOn(true);  // Ativa as luzes, iniciando o fade in do texto e ColorPicker
            } else {
                setLoaded(false);
            }
        },
    });

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Define resolução de 768px ou menos como "pequena"
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Verifica a largura inicial

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const cylinderArgs = isSmallScreen ? [0.8, 0.8, 0.1, 64] : [1.7, 1.7, 0.15, 64]; // Tamanho menor para telas pequenas
    const scale = isSmallScreen
    ? (selectedProduct === "VITRINE PORTO GALICIA" ? [0.46, 0.46, 0.46] 
       : selectedProduct === "BALCÃO PORTO VERTICAL" ? [0.48, 0.48, 0.48] 
       : [0.5, 0.5, 0.5])
    : [1, 1, 1];





    // Controle de exibição do 3D após o placeholder
    useEffect(() => {
        const section = document.getElementById('rotate-section');
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowAnimation(true);
                    observer.unobserve(section);
                }
            },
            { threshold: 0.2 }
        );
        if (section) observer.observe(section);
        return () => {
            if (section) observer.unobserve(section);
        };
    }, []);

    const handlePointerDown = () => {
        setIsInteracting(true); // Desaparece o texto e o picker
        setCursorStyle("grabbing");
    };

    const handlePointerUp = () => {
        setIsInteracting(false); // Reaparece o texto e o picker
        setCursorStyle("default");
    };

    const handleColorChange = (newColor) => {
        setColor(newColor.hex); // Atualiza a cor do GLB e também o estado do ColorWheel
        setInputColor(newColor.hex); // Sincroniza o campo de entrada com a nova cor
    };

    const handleInputChange = (e) => {
        const hex = e.target.value;
        setInputColor(hex); // Atualiza o valor exibido no input
        if (/^#([0-9A-F]{3}){1,2}$/i.test(hex)) { // Valida o formato hexadecimal
            setColor(hex); // Se a cor for válida, atualiza a cor do GLB
        }
    };

    useEffect(() => {
        setInputColor(color); // Garante que o campo de entrada tenha a cor atualizada
    }, [color]);


    const handleLoad3D = () => {
        console.log('Clicado em Visualizar 3D');  // Verificar o clique
        setMenuVisible(false);  // Ocultar o menu quando iniciar o 3D

        // Desativa temporariamente o scroll do usuário
        const disableScrollTemporarily = () => {
            document.addEventListener('wheel', preventScroll, { passive: false });
            document.addEventListener('touchmove', preventScroll, { passive: false });
        };

        // Reativa o scroll (caso queira reativar em algum momento)
        const enableScroll = () => {
            document.removeEventListener('wheel', preventScroll, { passive: false });
            document.removeEventListener('touchmove', preventScroll, { passive: false });
        };

        // Previne o scroll durante o evento
        const preventScroll = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        // Trava o scroll permanentemente ao terminar de rolar
        const lockScreen = () => {
            scrollPosition = window.scrollY;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPosition}px`; // Travar na posição atual
            document.body.style.width = '100%';
            console.log('Tela travada permanentemente');
        };

        // Realiza a rolagem suave até a seção 'rotate-section'
        const section = document.getElementById('rotate-section');
        const targetPosition = section.offsetTop; // Posição exata da seção

        // Função para rolar suavemente até a posição desejada
        const smoothScroll = () => {
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        };

        // Primeiro, desativa temporariamente o scroll do usuário
        disableScrollTemporarily();

        // Inicia a rolagem suave
        setTimeout(() => {
            smoothScroll();

            // Após o tempo estimado para a rolagem, trava a tela e desativa o scroll permanentemente
            setTimeout(() => {
                lockScreen();  // Trava a tela após a rolagem
                setAnimated(true);
                setLoaded(true);
                setExiting(false);
                setShowAnimation(false);
                console.log('Menu oculto');

                // Desabilita o scroll manual enquanto estiver na visualização 3D
                enableScroll(); // Pode ser removido se você não quiser reabilitar o scroll em nenhum momento
            }, 1000);  // Tempo para garantir que a rolagem suave terminou
        }, 100); // Pequeno atraso para garantir que o scroll foi desativado
    };




    // Função para rolar suavemente até uma posição específica
    const scrollToPosition = (targetPosition) => {
        const currentPosition = window.scrollY;

        // Se a posição atual estiver muito próxima da posição desejada, não faz nada
        if (Math.abs(currentPosition - targetPosition) < 10) return;

        // Usa o método window.scrollTo com comportamento 'smooth'
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth' // Isso garante que a rolagem será animada
        });
    };


    const handleUnload3D = () => {
        setInteracting(true); // Ativa o fade out e move os elementos para cima

        // Libera o scroll
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';

        // Volta para a sessão original
        const section = document.getElementById('rotate-section');
        const targetPosition = section.getBoundingClientRect().top + window.scrollY;
        window.scrollTo(0, targetPosition);

        setLightsOn(false);
        setExiting(true);

        setTimeout(() => {
            setLoaded(false);
            setShowAnimation(false);
            setMenuVisible(true); // Mostra o menu de produtos novamente
        }, 500);

        setTimeout(() => {
            setFadeOut(false);
            setShowAnimation(true);
            setInteracting(false);
        }, 1000);
    };


    return (
        <>
            <style>
                {`
        @keyframes gradienteMove {
            0% { background-position: 0% 0%; }
            50% { background-position: 100% 100%; }
            100% { background-position: 0% 0%; }
        }

        .animated-bg {
            background: linear-gradient(180deg, #0a0a0a 20%, #1c1c1c 60%, #0a0a0a 100%);
            animation: gradienteMove 5s ease-in-out infinite;
            width: 100vw;
            height: 100vh;
            position: relative;
            overflow: hidden;
        }

        .info-text {
            color: white;
            font-size: 18px;
            margin-left: 20px;
            font-family: Arial, sans-serif;
        }

        /* Evitar seleção de texto e elementos */
        .no-select {
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        /* Estilos de cursor ao interagir com o GLB */
        .glb-cursor {
            cursor: grab;
        }

        .glb-cursor:active {
            cursor: grabbing;
        }
    `}
            </style>

            <div
                id="rotate-section"
                style={{
                    background: 'linear-gradient(180deg, #0a0a0a 20%, #1c1c1c 60%, #0a0a0a 100%)',
                    zIndex: loaded ? 1006 : 1004, // Condicional para z-index
                    width: '100vw',
                    minHeight: '120vh', // Altura mínima para ocupar a tela inteira
                    maxHeight: '100vh', // Limita para não ultrapassar o viewport
                    top: isMobile ? 0 : 0, // Define top como 60 apenas em telas de celular
                    position: 'relative',
                    overflowY: 'auto', // Permite rolagem, se necessário
                    cursor: cursorStyle
                }}
                onPointerDown={handlePointerDown}  // Somente muda ao clicar
                onPointerUp={handlePointerUp}      // E volta ao soltar o clique
            >

                {productModels[selectedProduct] && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1001
                    }}>
                        <Canvas
                            shadows={{ type: THREE.PCFSoftShadowMap }}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                cursor: cursorStyle
                            }}
                            gl={{
                                antialias: true,
                                toneMapping: THREE.ACESFilmicToneMapping,
                            }}
                        >
                            {/* Luz ambiente para iluminação geral */}
                            <ambientLight intensity={1.0} />

                            {/* Luz hemisférica para iluminação suave de cima (céu) e de baixo (solo) */}
                            <hemisphereLight
                                skyColor={new THREE.Color(0xffffff)}
                                groundColor={new THREE.Color(0x444444)}
                                intensity={1.0}
                            />

                            {/* Luz direcional frontal bem em frente ao modelo */}
                            <threeAnimated.directionalLight
                                position={[0, 0.3, 4]}  // Luz posicionada bem em frente ao modelo
                                intensity={entryAnimation.lightIntensity}
                                castShadow
                                shadow-mapSize-width={4096}
                                shadow-mapSize-height={4096}
                                shadow-bias={-0.0001}
                            />

                            {/* Outras luzes direcionais de diferentes posições */}
                            <threeAnimated.directionalLight
                                position={[-5, 5, 5]}  // Luz da esquerda
                                intensity={entryAnimation.lightIntensity}
                                castShadow
                                shadow-mapSize-width={4096}
                                shadow-mapSize-height={4096}
                                shadow-bias={-0.0001}
                            />
                            <threeAnimated.directionalLight
                                position={[5, 5, -5]}  // Luz da direita
                                intensity={entryAnimation.lightIntensity}
                                castShadow
                                shadow-mapSize-width={4096}
                                shadow-mapSize-height={4096}
                                shadow-bias={-0.0001}
                            />
                            <threeAnimated.directionalLight
                                position={[-5, 5, -5]}  // Luz de trás
                                intensity={entryAnimation.lightIntensity}
                                castShadow
                                shadow-mapSize-width={4096}
                                shadow-mapSize-height={4096}
                                shadow-bias={-0.0001}
                            />
                            <threeAnimated.directionalLight
                                position={[0, 10, 0]}  // Luz de cima
                                intensity={entryAnimation.lightIntensity}
                                castShadow
                                shadow-mapSize-width={4096}
                                shadow-mapSize-height={4096}
                                shadow-bias={-0.0001}
                            />

                            {/* Controle de câmera e rotação */}
                            <OrbitControls
                                ref={controls}
                                target={[0, 0, 0]}
                                maxPolarAngle={
                                    selectedProduct === "BALCÃO PORTO VERTICAL" || selectedProduct === "BALCÃO PORTO HORIZONTAL"
                                        ? Math.PI / 2 // Limita a subida para ambos os balcões
                                        : Math.PI / 2 // Ângulo padrão para outros produtos
                                }
                                minPolarAngle={
                                    selectedProduct === "BALCÃO PORTO VERTICAL" || selectedProduct === "BALCÃO PORTO HORIZONTAL"
                                        ? Math.PI / 2.5 // Limita a descida para ambos os balcões
                                        : 0 // Ângulo padrão para outros produtos
                                }
                                minAzimuthAngle={
                                    selectedProduct === "BALCÃO PORTO VERTICAL" || selectedProduct === "BALCÃO PORTO HORIZONTAL"
                                        ? -Math.PI / 3 // Limitação para rotação horizontal (esquerda)
                                        : -Infinity // Sem limite para outros produtos
                                }
                                maxAzimuthAngle={
                                    selectedProduct === "BALCÃO PORTO VERTICAL" || selectedProduct === "BALCÃO PORTO HORIZONTAL"
                                        ? Math.PI / 4 // Limitação para rotação horizontal (direita)
                                        : Infinity // Sem limite para outros produtos
                                }
                                minDistance={1.5}
                                maxDistance={2.7}
                                enableDamping={true}
                                dampingFactor={0.05}
                                autoRotate={false}
                                enableZoom={false}
                                enablePan={false}
                                enableRotate={true}
                                onPointerDown={handlePointerDown}  // Evento para iniciar a interação
                                onPointerUp={handlePointerUp}      // Evento para finalizar a interação
                                makeDefault
                            />

                            {/* Base para o modelo */}
                            {!isSmallScreen && (
                                <mesh
                                    position={[0, -0.7, -0.15]} // Posiciona o cilindro
                                    rotation={[0, 0, 0]}
                                    receiveShadow={false}
                                    castShadow={false}
                                >
                                    <cylinderGeometry args={cylinderArgs} />
                                    <meshStandardMaterial color={"#000"} roughness={0.7} metalness={0.2} />
                                </mesh>
                            )}
                            {/* Grupo com o modelo GLB e sua posição */}
                            {(selectedProduct === "BALCÃO PORTO VERTICAL" || selectedProduct === "BALCÃO PORTO HORIZONTAL") ? (
                                <threeAnimated.group
                                    position={isSmallScreen ? [0, 0.5, 0] : entryAnimation.vitrinePosition} // Manipula a posição no eixo Y apenas para os balcões
                                    rotation={[0, 4.6, 0]} // Rotação específica para os balcões
                                    scale={scale}
                                >
                                    {productModels[selectedProduct]}
                                </threeAnimated.group>
                            ) : (
                                <threeAnimated.group
                                    position={[0, 0, 0]} // Posição padrão para outros produtos
                                    rotation={[0, 0, 0]} // Rotação padrão para outros produtos
                                    scale={scale}
                                >
                                    {productModels[selectedProduct]}
                                </threeAnimated.group>
                            )}

                        </Canvas>
                    </div>
                )}

                {!loaded || exiting ? (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1002,
                            ...(window.innerWidth <= 768 ? { width: '100%', height: '100%', margin: 'auto', borderRadius: '8px', marginTop: '0%' } : {})  // Estilos para celular
                        }}
                    >
                        <div>
                            <button
                                onClick={handleLoad3D}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                }}
                            >
                                <webAnimated.div style={glbAnimation}>
                                    {/* Substituindo o Lottie por um modelo GLB (Urso) */}
                                    <Canvas style={{ height: 150, width: 150 }} shadows>
                                        {/* Luz ambiente suave para iluminar o urso de todos os lados */}
                                        <ambientLight intensity={1.2} />

                                        {/* Luzes direcionadas para iluminar o urso de diferentes ângulos */}
                                        <spotLight
                                            intensity={1.5}
                                            position={[5, 5, 5]}
                                            angle={0.5}
                                            penumbra={1}
                                            castShadow
                                            shadow-mapSize-width={1024}
                                            shadow-mapSize-height={1024}
                                        />
                                        <spotLight
                                            intensity={1.5}
                                            position={[5, 5, 5]}
                                            angle={0.5}
                                            penumbra={1}
                                            castShadow
                                            shadow-mapSize-width={1024}
                                            shadow-mapSize-height={1024}
                                        />
                                        <spotLight
                                            intensity={1.5}
                                            position={[5, 5, 5]}
                                            angle={0.5}
                                            penumbra={1}
                                            castShadow
                                            shadow-mapSize-width={1024}
                                            shadow-mapSize-height={1024}
                                        />

                                        {/* Adicionando um Environment para o urso */}
                                        <Environment preset="city" background={false} />

                                        <Suspense fallback={<Html>Carregando...</Html>}>
                                            <Urso />
                                        </Suspense>
                                    </Canvas>
                                    <p className="no-select" style={{ color: 'white', marginTop: '10px' }}>
                                        Visualizar 3D
                                    </p>
                                </webAnimated.div>
                            </button>
                        </div>
                    </div>
                ) : null}

                {loaded && !exiting && (
                    <>
                        {/* Botão de descarregar (Sair do 3D) */}
                        <div
                            style={{
                                position: 'absolute',
                                top: isMobile ? '860px' : '40px',
                                right: isMobile ? '4%' : '-2%',
                                transform: 'translateX(-50%)',
                                zIndex: 1006,
                            }}
                        >
                            <button
                                onClick={handleUnload3D}
                                style={{
                                    backgroundColor: '#242525',
                                    border: 'none',
                                    color: 'white',
                                    padding: '16px 60px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    userSelect: 'none', // Adicionado para prevenir seleção
                                    WebkitUserSelect: 'none', // Para navegadores WebKit (Safari e Chrome)
                                    MozUserSelect: 'none', // Para Firefox
                                    msUserSelect: 'none', // Para IE/Edge
                                }}
                            >
                                Sair do 3D
                            </button>
                        </div>


                        {/* Texto com animação de entrada e desaparecimento */}
                        <webAnimated.div
                            style={{
                                ...textAndPickerOpacity, // Aplica a animação da opacidade
                                position: 'absolute',
                                bottom: isMobile ? '210px' : '440px',
                                right: isMobile ? '115px' : '60px',
                                zIndex: 1003,
                                userSelect: 'none',
                                transform: isMobile ? 'scale(0.75)' : 'scale(1)',
                                transformOrigin: 'center',
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 10 }}>
                                    {/* Roda de cores com ColorWheel */}
                                    <ColorWheel
                                        color={color} // Usa o estado 'color' que está sincronizado com o GLB
                                        onChange={handleColorChange}
                                        style={{ width: 200, height: 200 }}
                                        onPointerDown={(e) => e.stopPropagation()}  // Evita a propagação de eventos
                                        onPointerUp={(e) => e.stopPropagation()}    // Evita a propagação de eventos
                                    />

                                    {/* Campo de entrada para hexadecimal */}
                                    <input
                                        ref={inputRef} // Atribui a referência ao campo de entrada
                                        type="text"
                                        value={inputColor} // Usa 'inputColor' para permitir edição sem interferências
                                        onChange={handleInputChange}
                                        maxLength={7}
                                        placeholder="#000000"
                                        style={{
                                            marginTop: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            textAlign: 'center',
                                            width: '100px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc'
                                        }}
                                    />
                                </Box>
                            </div>

                        </webAnimated.div>

                        <webAnimated.div
                            style={{
                                ...textAndPickerOpacity,
                                position: isMobile ? 'absolute' : 'fixed',
                                top: isMobile ? '20px' : '350px', // Posiciona no topo da tela em mobile
                                left: isMobile ? '50%' : '40px', // Centraliza horizontalmente em mobile
                                transform: isMobile ? 'translateX(-50%)' : 'none', // Centraliza o texto em mobile
                                textAlign: isMobile ? 'center' : 'left', // Centraliza o texto
                                zIndex: 2,
                                userSelect: 'none',
                            }}
                        >
                            <div style={{ userSelect: 'none' }}>
                                <h1 style={{
                                    color: '#393c41',
                                    fontWeight: 80,
                                    lineHeight: 1.2,
                                    fontSize: isMobile ? '60px' : '100px' // Reduz o tamanho em mobile
                                }}>
                                    {selectedText.title}
                                </h1>
                                <p style={{
                                    fontSize: isMobile ? '21px' : '30px', // Reduz o tamanho em mobile
                                    color: '#FFF'
                                }}>
                                    {selectedText.description}
                                </p>
                            </div>
                        </webAnimated.div>

                    </>
                )}
            </div>
        </>
    );
}
