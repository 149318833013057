/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Estocagem(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Estantes/Estocagem/ESTANTE%20ESTOCAGEM.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 5.56]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0015.geometry}
          material={nodes.Mesh_0015.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0015_1.geometry}
          material={materials['pw-mt11040-4.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0015_2.geometry}
          material={nodes.Mesh_0015_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0015_3.geometry}
          material={materials['satin finish nickel.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0015_4.geometry}
          material={materials['white low gloss plastic-1']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0015_5.geometry}
          material={materials.pintar}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ESTANTE_ESTOCAGEM001.geometry}
        material={materials.pintar}
        position={[0, 0, 5.56]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
      />
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Estantes/Estocagem/ESTANTE%20ESTOCAGEM.glb')
