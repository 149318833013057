import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes, css  } from 'styled-components';
import { GoDownload } from "react-icons/go";
import { useParams } from 'react-router-dom';

const Vitrine = () => {
  const { productName } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isDividerVisible, setIsDividerVisible] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const sectionRef = useRef(null);
  const dividerRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden"; // Desabilita o scroll
  };
  
  const closeModal = () => {
    setIsClosing(true); // Inicia a animação de saída
    setTimeout(() => {
      setIsModalOpen(false); // Fecha o modal
      setIsClosing(false); // Reseta o estado de fechamento para a próxima vez
      document.body.style.overflow = "auto"; // Reabilita o scroll
      
    }, 500); // Tempo deve coincidir com a duração da animação de saída
  };


  
  
  // JSON com informações de várias vitrines
  const vitrinesData = {
    "BALCÃO PORTO HORIZONTAL": {
      title: "ESPECIFICAÇÕES DO BALCÃO PORTO HORIZONTAL",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.5m / 2.0m / 1.5m / 1.0m (Largura) x 0.60m / 0.7m / 0.85m (Profundidade) x 0.7m / 0.9m / 1.1m (Altura)" },
        { title: "TEMPERATURA", value: "REFRIGERADA, FREZZER" },
        { title: "MODELOS", value: "FECHADO, GAVETAS" },
        { title: "FECHAMENTO", value: "VIDRO DUPLO TEMPERADO" },
        { title: "ESTRUTURA", value: "Aço Inox 304" },
        { title: "GARANTIA", value: "12 Meses" },
      ],
      downloadableImage: "/images/image/balcao_horizontal.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "2.5 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "300W"
      },
      dimensions: { height: "1.20m", width: "2.00m", depth: "0.80m", weight: "150kg" }
    },
    "DEPOSITO": {
      title: "ESPECIFICAÇÕES DO DEPÓSITO",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.00m (Largura) x 0.80m (Profundidade) x 1.20m (Altura)" },
        { title: "CAPACIDADE", value: "500 Litros" },
        { title: "TEMPERATURA", value: "-2°C a 8°C" },
        { title: "CONSUMO DE ENERGIA", value: "2.5 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Ventilada" },
        { title: "MATERIAL", value: "Aço Inoxidável" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/deposito.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "2.5 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "300W"
      },
      dimensions: { height: "2.00m", width: "1.50m", depth: "0.80m", weight: "200kg" }
    },
    "BALCÃO PORTO VERTICAL": {
      title: "ESPECIFICAÇÕES DO BALCÃO PORTO VERTICAL",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.0m / 1.5m / 1.0m / 0.5m (Largura) x 0.70m / 0.9m / 1.05m (Profundidade) x 0.7m / 0.9m / 1.95m (Altura)" },
        { title: "TEMPERATURA", value: "REFRIGERADA, FREZZER" },
        { title: "MODELOS", value: "FECHADO, PASS THRU, BIBARTIDO" },
        { title: "FECHAMENTO", value: "VIDRO DUPLO TEMPERADO" },
        { title: "ESTRUTURA", value: "Aço Inox 304" },
        { title: "GARANTIA", value: "12 Meses" },
      ],
      downloadableImage: "/images/image/balcao_vertical.jpg",
      additionalInformation: {
        material: "Aço Galvanizado",
        energyConsumption: "2.0 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "300W"
      },
      dimensions: { height: "1.05m", width: "2.38m", depth: "0.80m", weight: "170kg" }
    },
    "HIGIENIZAÇÃO": {
      title: "ESPECIFICAÇÕES DA HIGIENIZAÇÃO",
      generalInformation: [
        { title: "DIMENSÕES", value: "1.80m (Largura) x 0.70m (Profundidade) x 1.50m (Altura)" },
        { title: "CAPACIDADE", value: "300 Litros" },
        { title: "TEMPERATURA", value: "-2°C a 8°C" },
        { title: "CONSUMO DE ENERGIA", value: "2.5 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Ventilada" },
        { title: "MATERIAL", value: "Aço Inoxidável" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/higienizacao.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "2.5 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "300W"
      },
      dimensions: { height: "1.50m", width: "1.80m", depth: "0.70m", weight: "120kg" }
    },
    "ESTOCAGEM": {
      title: "ESPECIFICAÇÕES DA ESTOCAGEM",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.00m (Largura) x 0.80m (Profundidade) x 2.00m (Altura)" },
        { title: "CAPACIDADE", value: "800 Litros" },
        { title: "TEMPERATURA", value: "-2°C a 8°C" },
        { title: "CONSUMO DE ENERGIA", value: "3.0 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Ventilada" },
        { title: "MATERIAL", value: "Aço Carbono" },
        { title: "GARANTIA", value: "24 meses" }
      ],
      downloadableImage: "/images/image/estocagem.jpg",
      additionalInformation: {
        material: "Aço Carbono",
        energyConsumption: "3.0 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "400W"
      },
      dimensions: { height: "2.00m", width: "2.00m", depth: "0.80m", weight: "250kg" }
    },
    "CREMALHEIRAS": {
      title: "ESPECIFICAÇÕES DAS CREMALHEIRAS",
      generalInformation: [
        { title: "DIMENSÕES", value: "1.00m (Largura) x 0.40m (Profundidade) x 1.20m (Altura)" },
        { title: "CAPACIDADE", value: "100 Litros" },
        { title: "TEMPERATURA", value: "-2°C a 8°C" },
        { title: "CONSUMO DE ENERGIA", value: "1.0 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Natural" },
        { title: "MATERIAL", value: "Aço Inoxidável" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/cremalheiras.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "1.0 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "100W"
      },
      dimensions: { height: "1.20m", width: "1.00m", depth: "0.40m", weight: "80kg" }
    },
    "VITRINE PORTO GALICIA": {
      title: "ESPECIFICAÇÕES DA VITRINE PORTO GALICIA",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.38m / 1.96m / 1.19m / 0.98m (Largura) x 0.80m (Profundidade) x 1.30m (Altura)" },
        { title: "TEMPERATURA", value: " ESTUFA, SECA, REFRIGERADA" },
        { title: "MODELOS", value: "ABERTO E FECHADO" },
        { title: "FECHAMENTO", value: "VIDRO DUPLO TEMPERADO" },
        { title: "ESTRUTURA", value: "Aço Inox 304" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/vitrine_galicia.jpg",
      additionalInformation: {
        material: "Aço Galvanizado",
        energyConsumption: "2.5 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "300W"
      },
      dimensions: { height: "1.10m", width: "2.20m", depth: "0.80m", weight: "160kg" }
    },
    "VITRINE PORTO PARIS": {
      title: "ESPECIFICAÇÕES DA VITRINE PORTO PARIS",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.38m (Comprimento) x 0.80m (Profundidade) x 1.05m (Altura)" },
        { title: "CAPACIDADE", value: "450 Litros" },
        { title: "TEMPERATURA", value: "-2°C a 8°C" },
        { title: "CONSUMO DE ENERGIA", value: "2.0 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Estática" },
        { title: "MATERIAL", value: "Aço Galvanizado" },
        { title: "GARANTIA", value: "24 meses" }
      ],
      downloadableImage: "/images/image/vitrine_paris.jpg",
      additionalInformation: {
        material: "Aço Galvanizado",
        energyConsumption: "2.0 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "250W"
      },
      dimensions: { height: "1.05m", width: "2.38m", depth: "0.80m", weight: "170kg" }
    },
    "EXPOSITOR PORTO VISEU": {
      title: "ESPECIFICAÇÕES DO EXPOSITOR PORTO VISEU",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.38m / 1.78m / 1.19m / 0.8m (Largura) x 0.80m (Profundidade) x 2.08m (Altura)" },
        { title: "TEMPERATURA", value: "ESTUFA, SECA, REFRIGERADA, FREZZER" },
        { title: "MODELOS", value: "ABERTO E FECHADO" },
        { title: "FECHAMENTO ", value: "VIDRO DUPLO TEMPERADO" },
        { title: "ESTRUTURA ", value: "Aço Inox 304" },
        { title: "MATERIAL", value: "Aço Inoxidável" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/expositor_viseu.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "1.5 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "200W"
      },
      dimensions: { height: "1.00m", width: "1.80m", depth: "0.60m", weight: "130kg" }
    },
    "MESA": {
      title: "ESPECIFICAÇÕES DA MESA",
      generalInformation: [
        { title: "DIMENSÕES", value: "2.00m (Largura) x 0.80m (Profundidade) x 0.75m (Altura)" },
        { title: "CAPACIDADE", value: "300 Litros" },
        { title: "TEMPERATURA", value: "Ambiente" },
        { title: "CONSUMO DE ENERGIA", value: "0 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Nenhuma" },
        { title: "MATERIAL", value: "Aço Inoxidável" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/mesa.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "0 kWh/dia",
        temperatureRange: "Ambiente",
        compressorPower: "N/A"
      },
      dimensions: { height: "0.75m", width: "2.00m", depth: "0.80m", weight: "100kg" }
    },
    "BUFFET": {
      title: "ESPECIFICAÇÕES DO BUFFET",
      generalInformation: [
        { title: "DIMENSÕES", value: "1.80m (Largura) x 0.70m (Profundidade) x 1.00m (Altura)" },
        { title: "CAPACIDADE", value: "350 Litros" },
        { title: "TEMPERATURA", value: "-2°C a 8°C" },
        { title: "CONSUMO DE ENERGIA", value: "2.0 kWh/dia" },
        { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Ventilada" },
        { title: "MATERIAL", value: "Aço Inoxidável" },
        { title: "GARANTIA", value: "12 meses" }
      ],
      downloadableImage: "/images/image/buffet.jpg",
      additionalInformation: {
        material: "Aço Inoxidável",
        energyConsumption: "2.0 kWh/dia",
        temperatureRange: "-2°C a 8°C",
        compressorPower: "250W"
      },
      dimensions: { height: "1.00m", width: "1.80m", depth: "0.70m", weight: "140kg" }
    },

   "MESA COM PIA": {
    title: "ESPECIFICAÇÕES DA MESA COM PIA",
    generalInformation: [
      { title: "DIMENSÕES", value: "2.00m (Largura) x 0.80m (Profundidade) x 1.20m (Altura)" },
      { title: "CAPACIDADE", value: "400 Litros" },
      { title: "TEMPERATURA", value: "-2°C a 8°C" },
      { title: "CONSUMO DE ENERGIA", value: "2.5 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Ventilada" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/mesa_pia.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "2.5 kWh/dia",
      temperatureRange: "-2°C a 8°C",
      compressorPower: "300W"
    },
    dimensions: { height: "1.20m", width: "2.00m", depth: "0.80m", weight: "140kg" }
  },
  "LAVADORA": {
    title: "ESPECIFICAÇÕES DA LAVADORA",
    generalInformation: [
      { title: "DIMENSÕES", value: "1.80m (Largura) x 0.70m (Profundidade) x 1.10m (Altura)" },
      { title: "CAPACIDADE", value: "300 Litros" },
      { title: "TEMPERATURA", value: "-2°C a 8°C" },
      { title: "CONSUMO DE ENERGIA", value: "1.8 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Estática" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/lavadora.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "1.8 kWh/dia",
      temperatureRange: "-2°C a 8°C",
      compressorPower: "250W"
    },
    dimensions: { height: "1.10m", width: "1.80m", depth: "0.70m", weight: "140kg" }
  },
  "LAVATORIO": {
    title: "ESPECIFICAÇÕES DO LAVATÓRIO",
    generalInformation: [
      { title: "DIMENSÕES", value: "1.50m (Largura) x 0.60m (Profundidade) x 1.00m (Altura)" },
      { title: "CAPACIDADE", value: "200 Litros" },
      { title: "TEMPERATURA", value: "-2°C a 8°C" },
      { title: "CONSUMO DE ENERGIA", value: "1.2 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Refrigeração Natural" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/lavatorio.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "1.2 kWh/dia",
      temperatureRange: "-2°C a 8°C",
      compressorPower: "200W"
    },
    dimensions: { height: "1.00m", width: "1.50m", depth: "0.60m", weight: "110kg" }
  },
  "MESA GAVETEIRO": {
    title: "ESPECIFICAÇÕES DA MESA GAVETEIRO",
    generalInformation: [
      { title: "DIMENSÕES", value: "2.00m (Largura) x 0.80m (Profundidade) x 0.90m (Altura)" },
      { title: "CAPACIDADE", value: "350 Litros" },
      { title: "TEMPERATURA", value: "Ambiente" },
      { title: "CONSUMO DE ENERGIA", value: "0 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Nenhuma" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/mesa_gaveteiro.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "0 kWh/dia",
      temperatureRange: "Ambiente",
      compressorPower: "N/A"
    },
    dimensions: { height: "0.90m", width: "2.00m", depth: "0.80m", weight: "130kg" }
  },
  "MESA LIXEIRA": {
    title: "ESPECIFICAÇÕES DA MESA LIXEIRA",
    generalInformation: [
      { title: "DIMENSÕES", value: "2.00m (Largura) x 0.80m (Profundidade) x 1.00m (Altura)" },
      { title: "CAPACIDADE", value: "300 Litros" },
      { title: "TEMPERATURA", value: "Ambiente" },
      { title: "CONSUMO DE ENERGIA", value: "0 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Nenhuma" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/mesa_lixeira.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "0 kWh/dia",
      temperatureRange: "Ambiente",
      compressorPower: "N/A"
    },
    dimensions: { height: "1.00m", width: "2.00m", depth: "0.80m", weight: "140kg" }
  },
  "PRATELEIRA DE PAREDE": {
    title: "ESPECIFICAÇÕES DA PRATELEIRA DE PAREDE",
    generalInformation: [
      { title: "DIMENSÕES", value: "1.20m (Largura) x 0.40m (Profundidade) x 0.30m (Altura)" },
      { title: "CAPACIDADE", value: "100 Litros" },
      { title: "TEMPERATURA", value: "Ambiente" },
      { title: "CONSUMO DE ENERGIA", value: "0 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Nenhuma" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/prateleira.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "0 kWh/dia",
      temperatureRange: "Ambiente",
      compressorPower: "N/A"
    },
    dimensions: { height: "0.30m", width: "1.20m", depth: "0.40m", weight: "30kg" }
  },
  "CAIXA DE GORDURA": {
    title: "ESPECIFICAÇÕES DA CAIXA DE GORDURA",
    generalInformation: [
      { title: "DIMENSÕES", value: "0.60m (Largura) x 0.40m (Profundidade) x 0.40m (Altura)" },
      { title: "CAPACIDADE", value: "30 Litros" },
      { title: "TEMPERATURA", value: "Ambiente" },
      { title: "CONSUMO DE ENERGIA", value: "0 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Nenhuma" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/caixa_gordura.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "0 kWh/dia",
      temperatureRange: "Ambiente",
      compressorPower: "N/A"
    },
    dimensions: { height: "0.40m", width: "0.60m", depth: "0.40m", weight: "20kg" }
  },
  "GRELHA DE PISO": {
    title: "ESPECIFICAÇÕES DA GRELHA DE PISO",
    generalInformation: [
      { title: "DIMENSÕES", value: "0.50m (Largura) x 0.50m (Profundidade)" },
      { title: "CAPACIDADE", value: "20 Litros" },
      { title: "TEMPERATURA", value: "Ambiente" },
      { title: "CONSUMO DE ENERGIA", value: "0 kWh/dia" },
      { title: "TIPO DE REFRIGERAÇÃO", value: "Nenhuma" },
      { title: "MATERIAL", value: "Aço Inoxidável" },
      { title: "GARANTIA", value: "12 meses" }
    ],
    downloadableImage: "/images/image/grelha_piso.jpg",
    additionalInformation: {
      material: "Aço Inoxidável",
      energyConsumption: "0 kWh/dia",
      temperatureRange: "Ambiente",
      compressorPower: "N/A"
    },
    dimensions: { height: "0.05m", width: "0.50m", depth: "0.50m", weight: "10kg" }
  }
};
  
  

  // Seleciona a vitrine com base no nome do produto
  const vitrineData = vitrinesData[productName] || vitrinesData["Vitrine Refrigerada A"];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsSectionVisible(true);
        }
      },
      {
        threshold: 0.4, // Inicia a animação quando 50% da seção está visível
      }
    );
  
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
  
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  
  useEffect(() => {
    const dividerObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsDividerVisible(true); // Ativa a classe 'visible' para a animação expandir dos lados
        }
      },
      {
        threshold: 0.2, // A animação do divider começa quando 50% do divider está visível
      }
    );
  
    if (dividerRef.current) {
      dividerObserver.observe(dividerRef.current);
    }
  
    return () => {
      if (dividerRef.current) {
        dividerObserver.unobserve(dividerRef.current);
      }
    };
  }, []);
  
  

  return (
    <SectionContainer ref={sectionRef}>
      <Title>{vitrineData.title}</Title>
      <ContentContainer>
        {vitrineData.generalInformation.map((item, index) => (
         <TableContainer key={index} isVisible={isSectionVisible}>
          <Row key={index}>
            <TitleCell>{item.title}</TitleCell>
            <ValueCell>{item.value}</ValueCell>
          </Row>
          </TableContainer>
        ))}
      </ContentContainer>
      {/* <ExpandButton onClick={openModal}>
        Expandir todas as Especificações
      </ExpandButton> */}

      {isModalOpen &&
        ReactDOM.createPortal(
          <ModalOverlay className={isClosing ? 'closing' : ''}>
            <ModalContent className={isClosing ? 'closing' : ''}>
              <CloseButton onClick={closeModal}>X</CloseButton>

              {/* Título centralizado */}
              <ModalHeader>FICHA TÉCNICA</ModalHeader>

              <ModalBody>
                <LeftColumn>
                  {/* Imagem do produto à esquerda */}
                  <ImageWrapper>
                    <ModalImage src="/images/image/img1.jpg" alt="Imagem do Produto" />
                  </ImageWrapper>

                  {/* Informações adicionais abaixo da imagem */}
                  <InfoWrapper>
                    <InfoSectionTitle>Informações Adicionais</InfoSectionTitle>
                    <InfoGrid>
                      <InfoItem>
                        <InfoTitle>Material:</InfoTitle>
                        <InfoValue>{vitrineData.additionalInformation.material}</InfoValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoTitle>Consumo de Energia:</InfoTitle>
                        <InfoValue>{vitrineData.additionalInformation.energyConsumption}</InfoValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoTitle>Faixa de Temperatura:</InfoTitle>
                        <InfoValue>{vitrineData.additionalInformation.temperatureRange}</InfoValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoTitle>Potência do Compressor:</InfoTitle>
                        <InfoValue>{vitrineData.additionalInformation.compressorPower}</InfoValue>
                      </InfoItem>
                    </InfoGrid>
                  </InfoWrapper>
                </LeftColumn>

                {/* Coluna direita contendo dimensões do produto e imagem para download */}
                <RightColumn>
                  <InfoWrapper>
                    <InfoSectionTitle>Dimensões do Produto</InfoSectionTitle>
                    <InfoGrid>
                      <InfoItem>
                        <InfoTitle>Altura:</InfoTitle>
                        <InfoValue>{vitrineData.dimensions.height}</InfoValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoTitle>Largura:</InfoTitle>
                        <InfoValue>{vitrineData.dimensions.width}</InfoValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoTitle>Profundidade:</InfoTitle>
                        <InfoValue>{vitrineData.dimensions.depth}</InfoValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoTitle>Peso:</InfoTitle>
                        <InfoValue>{vitrineData.dimensions.weight}</InfoValue>
                      </InfoItem>
                    </InfoGrid>
                  </InfoWrapper>

                  {/* Imagem para download com ícone sobreposto */}
                  <DownloadableImageWrapper>
                    <DownloadableImage src={vitrineData.downloadableImage} alt="Downloadable Image" />
                    <DownloadIconWrapper href={vitrineData.downloadableImage} download="vitrine-details.png">
                      <GoDownload size={30} color="white" />
                    </DownloadIconWrapper>
                  </DownloadableImageWrapper>
                </RightColumn>
                <VerticalText>DATASHEET</VerticalText>
              </ModalBody>
            </ModalContent>
          </ModalOverlay>,
          document.body
        )}
      <Divider ref={dividerRef} className={isDividerVisible ? 'visible' : ''} />
    </SectionContainer>
  );
};

export default Vitrine;

// Styled Components
const fadeInItem = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
// Animação de fadeIn
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Container principal da seção
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  padding: 60px;
  background-color: #000;
  background-position: 120vh;
  color: white;
  font-family: 'Arial', sans-serif;
  margin: 0;
  transition: all 0.5s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    animation: ${fadeIn} 0.5s ease forwards;
  }
`;

const Divider = styled.div`
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #ffffff00, #ffffff, #ffffff00);
  margin-top: 120px;
  margin-bottom: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  animation: growDivider 1s ease forwards;

  &.visible {
    animation: growDividerFromCenter 1s ease forwards;
  }

  @keyframes growDividerFromCenter {
    0% { width: 0; }
    100% { width: 100%; }
  }
`;

// Título da seção
const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin: 80px 0 100px 0;
  text-align: center;
  color: white;
  position: relative;
`;

// Letra animada do título
const Letter = styled.span`
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: ${({ hasAnimated }) => hasAnimated ? fadeUp : 'none'} 0.5s forwards;
  animation-delay: ${({ delay }) => delay}s;
  letter-spacing: 2px;
`;

// Animação de fadeUp
const fadeUp = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Container de conteúdo
const ContentContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 0 10px; /* Adiciona espaço para dispositivos menores */
`;

// Linha de item
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #333;
  flex-direction: column; /* Alterado para exibir as células em coluna em dispositivos menores */

  @media (min-width: 768px) {
    flex-direction: row; /* Exibe em linha para telas maiores */
  }
`;

// Título de célula
const TitleCell = styled.div`
  font-weight: bold;
  color: white;
  flex: 2;
  text-align: left;
  font-family: 'Brmalls';
  font-size: 16px; /* Tamanho ajustado para responsividade */
  line-height: 1.6;
  margin-bottom: 5px; /* Espaço entre título e valor em dispositivos menores */
  
  @media (min-width: 768px) {
    font-size: 18px;
    font-family: 'Brmalls';
  }
`;

// Valor de célula
const ValueCell = styled.div`
  color: white;
  flex: 1;
  text-align: left;
  font-family: 'Brmalls';
  font-size: 14px;
  white-space: normal; /* Permite a quebra de linha */
  word-wrap: break-word; /* Quebra o texto se necessário */

  @media (min-width: 768px) {
    text-align: right;
    font-family: 'Brmalls';
  }
`;


// Botão de expandir
const ExpandButton = styled.button`
  margin-top: 30px;
  padding: 10px 15px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px; /* Reduzido para dispositivos menores */

  &:hover {
    background-color: #555;
  }

  @media (min-width: 768px) {
    font-size: 16px; /* Aumenta o tamanho do texto em telas maiores */
  }
`;

// Animações de fade para o modal
const fadeInOverlay = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const fadeOutOverlay = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0.8);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const fadeInContent = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutContent = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

// Overlay do modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeInOverlay} 0.5s ease-in-out forwards;

  &.closing {
    animation: ${fadeOutOverlay} 0.5s ease-in-out forwards;
  }
`;

// Conteúdo do modal
const ModalContent = styled.div`
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  max-width: 1000px;
  width: 90%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  animation: ${fadeInContent} 0.5s ease-in-out forwards;

  &.closing {
    animation: ${fadeOutContent} 0.5s ease-in-out forwards;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`;

// Botão de fechar o modal
const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;

  &:hover {
    color: #354A81;
  }
`;

// Título do modal
const ModalHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 15px;
  color: white;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

// Corpo do modal
const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Colunas do modal
const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

// Wrapper da imagem no modal
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

// Imagem do modal
const ModalImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
// Wrapper de informações
const InfoWrapper = styled.div`
  margin-top: 20px;
`;

// Título da seção de informações
const InfoSectionTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
  color: white;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

// Grid de informações
const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Item de informação
const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: #222;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

// Título e valor de item de informação
const InfoTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  font-family: 'Brmalls';
  margin-right: 8px; /* Adiciona espaço entre o título e o valor */
`;

const InfoValue = styled.div`
  font-size: 16px;
  color: white;
  font-family: 'Brmalls';
  margin-left: 8px; /* Adiciona espaço entre o título e o valor */
`;

// Imagem para download e ícone
const DownloadableImageWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const DownloadableImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 250px;
  border-radius: 10px;
  filter: brightness(70%);

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;
const DownloadIconWrapper = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  cursor: pointer;
`;

const VerticalText = styled.div`
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 12px;
  color: #ccc;
  letter-spacing: 2px;
  font-weight: bold;
  position: absolute;
  top: 78%;
  right: 30px;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    position: static;
    writing-mode: horizontal-tb; /* Muda para texto horizontal */
    text-align: center;
    margin-top: 15px; /* Adiciona margem para separação */
  }
`;



const TableContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #000;
  color: white;
  font-family: 'Arial', sans-serif;
  border-collapse: collapse;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;


  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: translateY(0);
      animation: ${fadeInItem} 0.5s ease forwards;
    `}
`;
