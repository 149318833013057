/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function PrateleiraP(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Mobiliario/Prateleira%20de%20parede/PRATELEIRA%20DE%20PAREDE.glb')
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.PRATELEIRA__DE_PAREDE.geometry}
        material={materials['satin finish nickel']}
        position={[0.72, 0, -16.96]}
      />
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Mobiliario/Prateleira%20de%20parede/PRATELEIRA%20DE%20PAREDE.glb')