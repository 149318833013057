import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css"; // Importa o CSS com a customização da scrollbar
import Header from "./components/Header";
import Home from "./components/Home";
import SplashScreen from "./Screens/SplashScreen";
import ProductPage from './components/Product';
import { ThemeProvider } from 'styled-components';
import Equipe from './Screens/SobreNos/Equipe';
import Missao from './Screens/SobreNos/Missao';
import Contato from './components/Menu/Contato';
import Servicos from './components/Menu/Servicos';

const theme = {
  fontFamily: 'Montserrat, sans-serif', 
};

function App() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [menuVisible, setMenuVisible] = useState(true);
  const [is3DActive, setIs3DActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (isModalOpen || is3DActive) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  }, [isModalOpen, is3DActive]);

  return (
    <div className="App">
      {showSplashScreen ? (
        <SplashScreen />
      ) : (
        <Router>
          <Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route 
                path="/product/:productName/:subProductName?" 
                element={
                  <ProductPage 
                    setMenuVisible={setMenuVisible} 
                    setIs3DActive={setIs3DActive}
                    setIsModalOpen={setIsModalOpen}
                  />
                } 
              />
              <Route path="/equipe" element={<Equipe />} />
              <Route path="/servicos" element={<Servicos />} />
              <Route path="/contato" element={<Contato />} />
            </Routes>
          </ThemeProvider>
        </Router>
      )}
    </div>
  );
}

export default App;
