import React, { useRef, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

export function ExpositorPV({ color, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Expositor/Viseu/viseu.glb');

  // Estado para detectar se está em resolução de celular
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Atualizando a cor do material 'VIDRO PINTADO.001'
  useEffect(() => {
    if (materials['VIDRO PINTADO.001']) {
      materials['VIDRO PINTADO.001'].color = new THREE.Color(color);
    }
  }, [color, materials]);

  // Centralizando o objeto
  const boundingBox = new THREE.Box3().setFromObject(nodes.Mesh_0003);
  const center = boundingBox.getCenter(new THREE.Vector3());

  // Ajuste de posição no eixo Y com base na resolução
// Ajuste de posição no eixo Y com base na resolução
const positionY = isMobile ? center.y - 1.0 : center.y - 0.5;

  return (
    <group
      ref={group}
      {...props}
      position={[-center.x, -positionY, -center.z]} // Posição centralizada no eixo Y
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003.geometry}
        material={materials['clear glass.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_1.geometry}
        material={materials['color-5.003']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_2.geometry}
        material={materials['clear thick glass.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_3.geometry}
        material={materials['color-3.003']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_4.geometry}
        material={materials['color-8.002']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_5.geometry}
        material={materials['VIDRO PINTADO.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_6.geometry}
        material={materials['color-1.003']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_7.geometry}
        material={materials['color.002']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_8.geometry}
        material={materials['satin finish nickel.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_9.geometry}
        material={materials['amber led.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0003_10.geometry}
        material={materials['color-9.002']}
      />
    </group>
  );
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Expositor/Viseu/viseu.glb');
