import React, { useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const UpdatesSection = () => {
  const updates = [
    {
      title: "Nova Fase da AER Refrigeração",
      description: "A AER Refrigeração entra em uma nova fase com o lançamento de seu site interativo, agora com tecnologia 3D! Explore nossos produtos de forma inovadora e imersiva, aprimorando a experiência e conexão com nossos clientes.",
      date: "25 de Agosto de 2024",
      image: "/images/aer.png",
      tags: ["Inovação", "Tecnologia 3D"]
    },
    {
      title: "Vitrines Exclusivas",
      description: "Apresentamos nossas vitrines exclusivas, projetadas para potencializar a apresentação dos produtos com elegância e destaque. Ideais para ambientes modernos, essas vitrines elevam o visual e aprimoram a experiência do cliente, tornando cada exposição única e impactante.",
      date: "15 de Agosto de 2024",
      image: "/images/menu_images/render.jpg",
      tags: ["Novidade", "Vitrines"]
    },
  ];

  return (
    <UpdatesContainer>
      <Fade bottom cascade>
        <SectionTitle>Atualizações</SectionTitle>

        {updates.map((update, index) => (
          <UpdateRow key={index}>
            <UpdateImageWrapper>
              <UpdateImage src={update.image} alt={update.title} />
            </UpdateImageWrapper>
            <UpdateContent>
              <UpdateDate>{update.date}</UpdateDate>
              <UpdateTags>
                {update.tags.map((tag, idx) => (
                  <Tag key={idx}>{tag}</Tag>
                ))}
              </UpdateTags>
              <UpdateTitle>{update.title}</UpdateTitle>
              <ReadMore update={update} />
            </UpdateContent>
          </UpdateRow>
        ))}
      </Fade>
    </UpdatesContainer>
  );
};

const ReadMore = ({ update }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <DescriptionContainer>
        <Fade collapse when={isExpanded}>
          <Description>{update.description}</Description>
        </Fade>
      </DescriptionContainer>
      <ReadMoreButton onClick={toggleDescription}>
        {isExpanded ? 'Ler Menos' : 'Ler Mais'}
      </ReadMoreButton>
    </div>
  );
};

export default UpdatesSection;

// Styled Components

const UpdatesContainer = styled.div`
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: left;
  margin-bottom: 100px;
  transform: translateY(-20px);

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 50px;
  }
`;

const UpdateRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

const UpdateImageWrapper = styled.div`
  width: 50%;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const UpdateImage = styled.img`
  width: 70%;
  height: 280px; // Define uma altura fixa para padronizar o tamanho das imagens
  object-fit: cover; // Garante que a imagem se ajuste ao contêiner sem distorção
  max-width: 100%; 

  @media (max-width: 768px) {
    height: 180px; // Ajusta a altura em telas menores para uma visualização mais compacta
     width: 70%;
  }
`;

const UpdateContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const UpdateDate = styled.p`
  font-size: 0.9rem;
  color: #888;
  margin-top: -10px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const UpdateTags = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Tag = styled.span`
  background-color: #e6e6e6;
  color: #333;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 20px;
`;

const UpdateTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 15px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const ReadMoreButton = styled.button`
  background-color: white;
  border: 1px solid black;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const DescriptionContainer = styled.div`
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #444;
  text-align: left;
  margin-top: 0;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
