import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Lavadora(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Mobiliario/Lavadora/MESA%20LAVADORA%20INOX.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.65, 0.58, -0.5]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0001.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0001_1.geometry}
          material={materials['matte aluminum']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0001_2.geometry}
          material={materials['color-4.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0001_3.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0001_4.geometry}
          material={materials['satin finish nickel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0001_5.geometry}
          material={materials['pw-mt11000-4']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Mobiliario/Lavadora/MESA%20LAVADORA%20INOX.glb')