/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Higienizacao(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Estantes/Higienizacao/ESTANTE%20HIGENIZA%C3%87%C3%83O.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 11.24]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0020.geometry}
          material={materials['matte aluminum']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0020_1.geometry}
          material={materials['satin finish nickel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0020_2.geometry}
          material={materials['pw-mt11000-2']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Estantes/Higienizacao/ESTANTE%20HIGENIZA%C3%87%C3%83O.glb')