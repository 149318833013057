import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Buffet(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Mobiliario/Buffet/BUFFET.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.66, 0.58, -3.8]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0003.geometry}
          material={materials['satin finish nickel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0003_1.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0003_2.geometry}
          material={materials.granite}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0003_3.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0003_4.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0003_5.geometry}
          material={materials['pw-mt11000-4']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Mobiliario/Buffet/BUFFET.glb')