import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import { KeyboardArrowRight, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { selectCars } from "../features/car/carSlice";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Lottie from "react-lottie";
import animationData from '../assets/lottie/X.json';
import { useLocation } from 'react-router-dom';

const categoryMapping = {
  "BALCÃO": ["BALCÃO PORTO HORIZONTAL", "BALCÃO PORTO VERTICAL"],
  "ESTANTES": ["DEPÓSITO", "HIGIENIZAÇÃO", "ESTOCAGEM", "CREMALHEIRAS"],
  "VITRINES": ["VITRINE PORTO GALICIA", "VITRINE PORTO PARIS"],
  "EXPOSITORES": ["EXPOSITOR PORTO VISEU"],
  "MOBILIÁRIO": ["MESA", "BUFFET", "MESA COM PIA", "LAVADORA", "LAVATÓRIO", "PRATELEIRA"],
  "ACESSÓRIOS": ["CAIXA DE GORDURA", "GRELHA DE PISO"]
};

const normalize = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
};

const getCurrentProduct = (location) => {
  const pathParts = location.pathname.split('/');
  return decodeURIComponent(pathParts[pathParts.length - 1]).replace(/%20/g, ' ').toLowerCase();
};


const getRelatedProducts = (product) => {
  product = normalize(product);
  for (const [category, products] of Object.entries(categoryMapping)) {
    const normalizedProducts = products.map(p => normalize(p));
    if (normalizedProducts.includes(product)) {
      return products.filter(p => normalize(p) !== product);
    }
  }
  return [];
};

const getProductCategory = (product) => {
  product = normalize(product);
  for (const [category, products] of Object.entries(categoryMapping)) {
    const normalizedProducts = products.map(p => normalize(p));
    if (normalizedProducts.includes(product)) {
      return {
        category: category,
        relatedProducts: products.filter(p => normalize(p) !== product)
      };
    }
  }
  return { category: '', relatedProducts: [] };
};


const Header = ({ menuVisible, setMenuVisible }) => {

  const [burgerStatus, setBurgerStatus] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [previousHoveredItem, setPreviousHoveredItem] = useState(null);
  const [hoveredSubItem, setHoveredSubItem] = useState(null);
  const [hoveredNestedSubItem, setHoveredNestedSubItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [expandedSubItem, setExpandedSubItem] = useState(null);
  const [showLottie, setShowLottie] = useState(false);
  const [lottiePosition, setLottiePosition] = useState(420);
  const [lottieMoved, setLottieMoved] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [nextMedia, setNextMedia] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [mediaTransitionVisible, setMediaTransitionVisible] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const [showProductList, setShowProductList] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollCount, setScrollCount] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [slideOut, setSlideOut] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMainMenu, setIsMainMenu] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const burgerNavRef = useRef(null); // Referência para o BurgerNav
  const lottieRef = useRef(null);
  const combinedNavRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        burgerNavRef.current && !burgerNavRef.current.contains(event.target) &&
        lottieRef.current && !lottieRef.current.contains(event.target) &&
        combinedNavRef.current && !combinedNavRef.current.contains(event.target)
      ) {
        setBurgerStatus(false); // Fecha o BurgerNav
        setShowLottie(false); // Fecha o LottieContainer
        setMenuOpen(false); // Fecha o CombinedNav
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [burgerNavRef, lottieRef, combinedNavRef, setBurgerStatus, setShowLottie, setMenuOpen]);


  const currentProduct = getCurrentProduct(location);
  const { category, relatedProducts } = currentProduct
    ? getProductCategory(currentProduct)
    : { category: '', relatedProducts: [] };


  const isProductPage = location.pathname.startsWith('/product');

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      setScrollDirection("down");
      setScrollCount(0);
      setMenuVisible(false);
    } else if (currentScrollY < lastScrollY) {
      setScrollDirection("up");
      setScrollCount((prev) => prev + 1);
    }

    setLastScrollY(currentScrollY);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
  };



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define a resolução para celular
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleProductClick = () => {
    setIsMainMenu(false); // Alterna para o menu de subcategorias ao clicar em "PRODUTOS"
  };

  const handleBackClick = () => {
    setIsMainMenu(true); // Retorna ao menu principal ao clicar em "Voltar"
  };

  useEffect(() => {
    if (isProductPage) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, isProductPage]);

  useEffect(() => {
    if (scrollDirection === "up" && scrollCount >= 2) {
      setMenuVisible(true);
      setScrollCount(0);
    }
  }, [scrollDirection, scrollCount]);

  useEffect(() => {
    if (['/equipe', '/servicos', '/contato'].includes(location.pathname)) {
      // setShowProductList(false);  // Comente esta linha se não quiser esconder a lista de produtos
      // setBurgerStatus(false);     // Comente esta linha se não quiser esconder o ícone burger
    } else {
      setMenuVisible(true); // Torna o menu visível em todas as outras rotas
    }
  }, [location.pathname]);


  const handleCloseMenu = () => {
    setMenuVisible(false);
    setTimeout(() => {
      setBurgerStatus(false);
      setMenuOpen(false); // Fecha o CombinedNav também
    }, 500);
  };
  const [isAccessoriesExpanded, setIsAccessoriesExpanded] = useState(false);
  const [isShelfExpanded, setIsShelfExpanded] = useState(false);

  const prod = useSelector(selectCars);
  const navigate = useNavigate();
  const hideTimeout = useRef(null);
  const itemRefs = useRef([]);
  const submenuContainerRef = useRef(null);


  const toggleProductList = () => {
    setShowProductList(!showProductList);
  };

  const media = {
    0: '/images/image/img1.jpg',
    "SERVIÇOS": '/images/image/img3.jpg',
    "CONTATO": '/images/image/img1.jpg',
    "SOBRE NÓS": '/images/image/img2.jpg',
  };

  const subItems = {
    "PRODUTOS": {
      'VITRINES': ['VITRINE PORTO GALICIA', 'VITRINE PORTO PARIS'],
      'EXPOSITORES': ['EXPOSITOR PORTO VISEU'],
      'BALCÃO': ['BALCÃO PORTO HORIZONTAL', 'BALCÃO PORTO VERTICAL'],
      // 'ESTANTES': ['DEPOSITO', 'HIGIENIZAÇÃO', 'ESTOCAGEM', 'CREMALHEIRAS'],
      // 'MOBILIÁRIO': [
      //   'MESA',
      //   'BUFFET',
      //   'MESA COM PIA',
      //   'LAVADORA',
      //   'LAVATORIO',
      //   'MESA GAVETEIRO',
      //   'MESA LIXEIRA',
      //   'PRATELEIRA DE PAREDE',
      //   {
      //     name: 'ACESSÓRIOS',
      //     subItems: ['CAIXA DE GORDURA', 'GRELHA DE PISO']
      //   }
      // ]
    },
    "SOBRE NÓS": {},
    // "SERVIÇOS": {},
    "CONTATO": {}
  };

  const handleItemHover = (index, item) => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }

    if (item === "SOBRE NÓS" || item === "SERVIÇOS" || item === "CONTATO") {
      setPreviousHoveredItem(hoveredItem);
      setHoveredItem(index);
      setMenuOpen(false);
      setShowLottie(true);
      setShowContent(true);
      setMediaTransitionVisible(true);
      const sectionMedia = media[item];
      if (!lottieMoved) {
        setLottiePosition(20);
        setLottieMoved(true);
      }
      if (currentMedia !== sectionMedia) {
        setNextMedia(sectionMedia);
        setFadeOut(false);
      } else {
        setCurrentMedia(sectionMedia);
        setFadeIn(true);
      }
      setSlideOut(true);
    } else {
      setPreviousHoveredItem(hoveredItem);
      setHoveredItem(index);
      setMenuOpen(true);
      setShowLottie(true);
      setShowContent(true);
      setMediaTransitionVisible(true);
      if (!lottieMoved) {
        setLottiePosition(300);
        setLottieMoved(true);
      }
      if (currentMedia !== media[index]) {
        setNextMedia(media[index]);
        setFadeOut(false);
      } else {
        setCurrentMedia(media[index]);
        setFadeIn(true);
      }
      setSlideOut(false);
    }
  };


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    let timeout;
    if (fadeOut) {
      timeout = setTimeout(() => {
        setCurrentMedia(nextMedia);
        setFadeOut(false);
        setFadeIn(true);
      }, 200);
    } else if (fadeIn) {
      timeout = setTimeout(() => {
        setFadeIn(false);
      }, 200);
    }
    return () => clearTimeout(timeout);
  }, [fadeOut, fadeIn, nextMedia, burgerStatus]);

  const handleSubItemClick = (subItem) => {
    if (subItem.name === 'ACESSÓRIOS') {
      setIsAccessoriesExpanded(!isAccessoriesExpanded);
    } else if (subItem.name === 'PRATELEIRAS') {
      setIsShelfExpanded(!isShelfExpanded);
    } else {
      handleNavigation('/product', subItem.name || subItem);
    }
  };

  const handleItemLeave = () => { };

  const handleSubItemHover = (subItem) => {
    setHoveredSubItem(subItem);
    setShowLottie(true);
  };

  const handleSubItemLeave = () => {
    setHoveredSubItem(null);
  };

  const handleNestedSubItemHover = (nestedSubItem) => {
    setHoveredNestedSubItem(nestedSubItem);
  };

  const handleNestedSubItemLeave = () => {
    setHoveredNestedSubItem(null);
  };

  const handleTextClick = (item) => {
    if (item === 'ACESSÓRIOS' || item === 'PRATELEIRAS') {
      return;
    } else if (item === 'SERVIÇOS') {
      handleNavigation('/servicos');
    } else if (item === 'SOBRE NÓS') {
      handleNavigation('/equipe');
    } else if (item === 'CONTATO') {
      handleNavigation('/contato');
    } else {
      if (expandedSubItem === item) {
        setExpandedSubItem(null);
      } else {
        setExpandedSubItem(item);
      }
    }
  };

  const handleMenuToggle = () => {
    if (burgerStatus) {
      setShowLottie(true);
      setTimeout(() => {
        setBurgerStatus(false);
        setShowLottie(false);
      }, 500);
    } else {
      setBurgerStatus(true);
      setShowLottie(true);
    }
  };

  const handleLottieClick = () => {
    setBurgerStatus(false);
    setShowLottie(false);
    resetMenuState();
  };

  const resetMenuState = () => {
    setHoveredItem(null);
    setMenuOpen(false);
    setExpandedSubItem(null);
    setLottiePosition(420);
    setLottieMoved(false);
    setShowContent(false);
    setMediaTransitionVisible(false);
    setIsAccessoriesExpanded(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    if (burgerStatus && menuOpen) {
      setLottiePosition(840);
    } else if (burgerStatus && !menuOpen) {
      setLottiePosition(420);
    } else {
      setLottiePosition(20);
    }
  }, [burgerStatus, menuOpen]);

  useEffect(() => {
    if (hoveredItem !== null && submenuContainerRef.current && itemRefs.current[hoveredItem]) {
      const itemOffsetTop = itemRefs.current[hoveredItem].offsetTop;
      submenuContainerRef.current.style.paddingTop = `${itemOffsetTop}px`;
    }
  }, [hoveredItem]);

  const handleMediaMouseEnter = () => setShowButton(true);
  const handleMediaMouseLeave = () => setShowButton(false);
  const handleMouseMove = (e) => setMousePosition({ x: e.clientX, y: e.clientY });

  const handleNavigation = (path, product = null, subProduct = null) => {
    let newPath;
    if (!product) {
      newPath = path;
    } else if ((product === 'ACESSÓRIOS' || product === 'PRATELEIRAS') && subProduct) {
      newPath = `/product/${encodeURIComponent(subProduct)}`;
    } else if (subProduct) {
      newPath = `/product/${encodeURIComponent(product)}/${encodeURIComponent(subProduct)}`;
    } else {
      newPath = `/product/${encodeURIComponent(product)}`;
    }

    navigate(newPath); // Use navigate ao invés de window.location.href
    setBurgerStatus(false);
    setShowLottie(false);
    resetMenuState();
    window.scrollTo(0, 0); // Ainda rola para o topo da página
  };

  const setCombinedRefs = (...refs) => (node) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
  };


  return (
    <Container
      style={{
        visibility: menuVisible ? 'visible' : 'hidden',
        opacity: menuVisible ? 1 : 0,
        transition: 'opacity 0.5s ease, visibility 0.5s ease',
        zIndex: 1005,
        top: 0,
        left: 0,
      }}
    >
      {menuVisible && (
        <>
          <RightMenu>
            <MenuContainer onClick={handleMenuToggle}>
              <MenuIcon />
              <TextMenu><a>Menu</a></TextMenu>
            </MenuContainer>
          </RightMenu>

          <BurgerNav ref={burgerNavRef} show={burgerStatus}>
            <MenuHeader>
              <MenuImage src="/images/logo/logomenu.png" alt="Menu Icon" />
              <CloseButton onClick={handleMenuToggle}>✕</CloseButton>
            </MenuHeader>
            <TextMenu2><a>MENU</a></TextMenu2>

            {isMobile ? (
              selectedCategory ? (
                <>
                  {/* Produtos da categoria selecionada */}
                  <BackButton onClick={handleBackToCategories}>Voltar</BackButton>
                  {categoryMapping[selectedCategory]?.map((product, index) => (
                    <Li key={index} onClick={() => handleNavigation("/product", product)}>
                      <a>{product}</a>
                    </Li>
                  ))}
                </>
              ) : isMainMenu ? (
                <>
                  {/* Menu principal com opções "PRODUTOS", "SOBRE NÓS", "SERVIÇOS" e "CONTATO" */}
                  <Li onClick={handleProductClick}>
                    <a>PRODUTOS</a>
                    <StyledArrow><KeyboardArrowRight /></StyledArrow>
                  </Li>
                  <Li><a onClick={() => handleNavigation("/equipe")}>SOBRE NÓS</a></Li>
                  {/* <Li><a onClick={() => handleNavigation("/servicos")}>SERVIÇOS</a></Li> */}
                  <Li><a onClick={() => handleNavigation("/contato")}>CONTATO</a></Li>
                </>
              ) : (
                <>
                  {/* Subcategorias de "PRODUTOS" com botão "Voltar" */}
                  <BackButton onClick={() => setIsMainMenu(true)}>Voltar</BackButton>
                  <Li onClick={() => handleCategoryClick("VITRINES")}><a>VITRINES</a></Li>
                  <Li onClick={() => handleCategoryClick("EXPOSITORES")}><a>EXPOSITORES</a></Li>
                  <Li onClick={() => handleCategoryClick("BALCÃO")}><a>BALCÃO</a></Li>
                  {/* <Li onClick={() => handleCategoryClick("ESTANTES")}><a>ESTANTES</a></Li> */}
                </>
              )
            ) : (
              // Menu Desktop
              <>
                {prod && prod.map((pro, index) => (
                  <Li
                    key={index}
                    ref={el => itemRefs.current[index] = el}
                    active={hoveredItem === index}
                  >
                    <a
                      onMouseEnter={() => handleItemHover(index, pro)}
                      onMouseLeave={handleItemLeave}
                      onClick={() => handleTextClick(pro)}
                      style={{ cursor: (pro === "SERVIÇOS" || pro === "CONTATO" || pro === "SOBRE NÓS") ? "pointer" : "default" }}
                    >
                      {pro}
                    </a>
                    <StyledArrow>
                      {hoveredItem === index ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </StyledArrow>
                  </Li>
                ))}
              </>
            )}

            <FooterImage src="/images/logo/menu.png" alt="Footer Image" />
          </BurgerNav>

          {showLottie && (
            <LottieContainer ref={lottieRef} lottiePosition={lottiePosition} onClick={handleLottieClick}>
              <Lottie options={defaultOptions} height={50} width={50} />
            </LottieContainer>
          )}

          {mediaTransitionVisible && currentMedia && (
            <MediaTransitionContainer onMouseEnter={handleMediaMouseEnter} onMouseLeave={handleMediaMouseLeave} onMouseMove={handleMouseMove}>
              {fadeIn && nextMedia && (
                <TransitionImage src={nextMedia} fadeIn={fadeIn} />
              )}
              {fadeOut && currentMedia && (
                <TransitionImage src={currentMedia} fadeOut={fadeOut} />
              )}
              {!fadeIn && !fadeOut && currentMedia && (
                <TransitionImage src={currentMedia} />
              )}
            </MediaTransitionContainer>
          )}

          {isProductPage && !burgerStatus && menuVisible && (
            <>
              <ProductListButton onClick={toggleProductList}>
                <p>{category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}</p>
                {showProductList ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </ProductListButton>

              {showProductList && (
                <ProductList show={showProductList}>
                  {relatedProducts.length > 0 ? (
                    relatedProducts.map((product, index) => (
                      <ProductItem
                        key={index}
                        index={index}
                        onClick={() => handleNavigation("/product", product)}
                        style={{ userSelect: 'none' }}
                      >
                        {capitalizeFirstLetter(product)}
                      </ProductItem>
                    ))
                  ) : (
                    <p>Nenhum produto relacionado encontrado.</p>
                  )}
                </ProductList>
              )}
            </>
          )}

          {/* CombinedNav para Desktop */}
          {!isMobile && (
            <CombinedNav
              show={menuOpen}
              ref={setCombinedRefs(combinedNavRef, submenuContainerRef)}
            >
              <SubItemsWrapper>
                {hoveredItem !== null && (
                  <SubItems show={hoveredItem !== null}>
                    {subItems[prod[hoveredItem]] && (
                      Object.entries(subItems[prod[hoveredItem]]).map(([subItemKey, subItemValue], subIndex) => (
                        <MenuItem
                          key={subIndex}
                          onMouseLeave={handleSubItemLeave}
                        >
                          <ItemWrapper onClick={() => setExpandedSubItem(subItemKey)}>
                            <span>{typeof subItemValue === 'object' && subItemValue.name ? subItemValue.name : subItemKey}</span>
                            <StyledArrow>
                              {expandedSubItem === subItemKey ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                            </StyledArrow>
                          </ItemWrapper>
                          {expandedSubItem === subItemKey && (
                            <NestedSubItems show={expandedSubItem === subItemKey}>
                              {Array.isArray(subItemValue) ? (
                                subItemValue.map((nestedSubItem, nestedIndex) => (
                                  typeof nestedSubItem === 'object' ? (
                                    <>
                                      <MenuItem key={nestedIndex} onClick={() => handleSubItemClick(nestedSubItem)}>
                                        <ItemWrapper>
                                          <span>{nestedSubItem.name}</span>
                                          {nestedSubItem.name === 'ACESSÓRIOS' && (
                                            <StyledArrow>
                                              {isAccessoriesExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </StyledArrow>
                                          )}
                                          {nestedSubItem.name === 'PRATELEIRAS' && (
                                            <StyledArrow>
                                              {isShelfExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </StyledArrow>
                                          )}
                                        </ItemWrapper>
                                      </MenuItem>

                                      {nestedSubItem.name === 'ACESSÓRIOS' && isAccessoriesExpanded && (
                                        <NestedSubItems show={true}>
                                          {nestedSubItem.subItems.map((subNestedItem, subNestedIndex) => (
                                            <MenuItem
                                              key={subNestedIndex}
                                              onClick={() => handleNavigation('/product', 'ACESSÓRIOS', subNestedItem)}
                                            >
                                              <span>{subNestedItem}</span>
                                            </MenuItem>
                                          ))}
                                        </NestedSubItems>
                                      )}

                                      {nestedSubItem.name === 'PRATELEIRAS' && isShelfExpanded && (
                                        <NestedSubItems show={true}>
                                          {nestedSubItem.subItems.map((subNestedItem, subNestedIndex) => (
                                            <MenuItem
                                              key={subNestedIndex}
                                              onClick={() => handleNavigation('/product', 'PRATELEIRAS', subNestedItem)}
                                            >
                                              <span>{subNestedItem}</span>
                                            </MenuItem>
                                          ))}
                                        </NestedSubItems>
                                      )}
                                    </>
                                  ) : (
                                    <MenuItem
                                      key={nestedIndex}
                                      onClick={() => handleNavigation('/product', nestedSubItem)}
                                    >
                                      <span>{nestedSubItem}</span>
                                    </MenuItem>
                                  )
                                ))
                              ) : (
                                subItemValue.subItems.map((subNestedItem, subNestedIndex) => (
                                  <MenuItem
                                    key={subNestedIndex}
                                    onClick={() => handleNavigation('/product', 'ACESSÓRIOS', subNestedItem)}
                                  >
                                    <span>{subNestedItem}</span>
                                  </MenuItem>
                                ))
                              )}
                            </NestedSubItems>
                          )}
                        </MenuItem>
                      ))
                    )}
                  </SubItems>
                )}
              </SubItemsWrapper>
            </CombinedNav>
          )}
          <Overlay show={burgerStatus} onClick={handleCloseMenu} />
          <LogoContainer>
            <a href="/"><img src="/images/logo/header2.png" alt="Logo" style={{ width: '200px', height: 'auto', marginRight: 120, userSelect: 'none' }} /></a>
          </LogoContainer>
        </>
      )}
    </Container>
  );
}

export default Header;

const dropDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100px);
  }
`;

const slideInRight = keyframes`
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0);
  }
`;

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOutAnimation = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeInSlide = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOutSlide = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100px);
  }
`;

const zoomIn = keyframes`
  from { transform: scale(1); }
  to { transform: scale(1.05); }
`;

const Container = styled.div`
  min-height: 70px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background: linear-gradient(to bottom, rgba(36, 37, 37), rgba(0, 0, 0, 0));

  @media (max-width: 768px) {
    padding: 0 10px;
    justify-content: space-around;
  }

  @media (max-width: 480px) {
    padding: 0 5px;
    justify-content: space-between;
  }
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  margin-left: 30px;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 5px;
    margin-top: 5px;
  }
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;
  bottom: 6px;
  right: -5px;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    z-index: -1;
    border-radius: 5px;
  }

  &:hover::after {
    background-color: #404040;
  }

  svg {
    width: 22px;
    height: 24px;
    color: #fff;

    @media (max-width: 768px) {
      width: 18px;
      height: 20px;
    }

    @media (max-width: 480px) {
      width: 16px;
      height: 18px;
    }
  }
`;

const TextMenu = styled.div`
  font-size: 14px;

  a {
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Ginza Heavy', sans-serif;
    margin-left: 5px;
    user-select: none;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }
`;

const TextMenu2 = styled.div`
  font-size: 15px;

  a {
    color: #fff;
    font-family: 'Brmalls';
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

   @media (max-width: 480px) {
    margin-right: -50px; /* Aplica o margin-right diretamente no LogoContainer */
  }

  img {
    width: 100%;
    max-width: 200px; /* Limite máximo para telas grandes */
    height: auto;
    margin-right: 120px;
    user-select: none;

    @media (max-width: 768px) {
      max-width: 150px; /* Ajusta para telas de tablet */
      margin-right: 0;
    }

    @media (max-width: 480px) {
  max-width: 140px; /* Ajusta para telas de celular */

}
  }
`;


const BurgerNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(14,14,14,1) 0%, rgba(14,14,14,1) 70%, rgba(20,20,20,1) 100%);
  width: 400px;
  height: 100vh;  /* Altura total da tela */
  z-index: 20;
  list-style: none;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.5s ease-in-out;

  li {
    padding-top: 25px;

    a {
      font-weight: 600;
      font-family: 'Ginza Heavy', sans-serif;
      color: ${({ active }) => (active ? "#fff" : "#d4d4d4")};
      user-select: none;
    }
  }

 @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px; /* Aumente o padding lateral para 20px em dispositivos menores */
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 20px 15px; /* Aumente o padding lateral para 15px em dispositivos móveis */
  }
`;


const Li = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px; // Aumenta a área de clique em volta dos itens
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  border-radius: 5px;

  &:hover {
  }

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    z-index: -1;
  }

  a {
    color: ${({ active }) => (active ? "#fff" : "#d4d4d4")};
    user-select: none;
    width: 100%;
  }
  
  &:hover a {
    color: #fff;
    opacity: 1;
  }
`;

const StyledArrow = styled.div`
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;

  svg {
    transition: transform 0.3s ease;
  }

  &.expanded svg {
    transform: rotate(180deg);
  }
`;

const MenuImage = styled.img`
  width: 230px;
  height: auto;
  margin-left: 50px;
  user-select: none;

  @media (max-width: 768px) {
    width: 270px;
    margin-left: 125px;
    margin-top: -30px;
  }

  @media (max-width: 480px) {
    width: 220px;
    margin-left: 50px;
    margin-top: -20px;
  }
`;

const LottieContainer = styled.div`
  position: absolute;
  top: 20px;
  left: ${({ lottiePosition }) => `${lottiePosition}px`};
  z-index: 15;
  cursor: pointer;
  transition: left 0.5s ease;
  user-select: none;

  @media (max-width: 768px) {
  display: none; /* Esconde o Lottie em resolução de celular */
  }
`;



const MediaTransitionContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ burgerStatus }) => (burgerStatus ? '400px' : '350px')};
  width: calc(100% - ${({ burgerStatus }) => (burgerStatus ? '400px' : '300px')});
  height: 100%;
  z-index: 5;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.5s ease;
`;

const TransitionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ fadeIn, fadeOut }) => (fadeIn || !fadeOut ? 1 : 0)};
  animation: ${({ fadeIn, fadeOut }) =>
    fadeIn ? fadeInAnimation : fadeOut ? fadeOutAnimation : 'none'} 0.2s ease-in-out;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  display: ${({ show }) => (show ? 'block' : 'none')};
  onClick: handleCloseMenu;
`;

const FooterImage = styled.img`
  width: 12%;
  margin-top: auto;
  user-select: none;
`;

const CombinedNav = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${({ show }) => (show ? '400px' : '-400px')};
  background: linear-gradient(90deg, rgba(14,14,14,1) 0%, rgba(14,14,14,1) 70%, rgba(20,20,20,1) 100%);
  width: 400px;
  z-index: 10;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: left 0.5s ease-in-out;
  user-select: none;
`;

const SubItemsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SubItems = styled.ul`
  list-style-type: none;
  padding-left: 30px;
  margin-top: 15px;
  font-family: 'Brmalls';

  li {
    padding: 5px 0;
    color: #d4d4d4;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: 'Ginza Heavy', sans-serif;
`;

const MenuItem = styled.li`
  font-family: 'Brmalls';
  cursor: pointer;
  padding: 10px 0;
  margin-left: 0px;
  display: block;
`;

const NestedSubItems = styled.ul`
  list-style-type: none;
  padding-left: 20px;
  margin: 5px 0 5px 20px;
  max-height: ${({ show }) => (show ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-out;

  li {
    padding: 5px 0;
    font-size: 13px;
    color: #d4d4d4;
    cursor: pointer;
    animation: ${({ show }) => (show ? css`${fadeInSlide}` : css`${fadeOutSlide}`)} 0.5s ease-out;
    margin-right: 80px;
    text-align: start;

    &:hover {
      color: #fff;
      opacity: 1;
    }
  }
`;

const ProductListButton = styled.button`
  position: fixed;
  background-color: white;
  right: 20px;
  top: 40px;
  padding: 10px 40px;
  color: #000;
  cursor: pointer;
  z-index: 1002;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, background-color 0.3s ease, border 0.3s ease;
  user-select: none;

  &:hover {
    transform: scale(1.05);
  }

  svg {
    transition: transform 0.3s ease;
    transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  // Ajustes para responsividade
  @media (max-width: 1024px) {
    padding: 8px 32px;
    font-size: 0.9em;
    right: 15px;
    top: 30px;
  }

  @media (max-width: 768px) {
   padding: 4px 8px;
    font-size: 0.6em;
    right: 15px;
    top: 15px;
  }

  @media (max-width: 480px) {
    padding: 4px 4px;
    font-size: 0.6em;
    right: 15px;
    top: 15px;
  }
`;

const ProductList = styled.ul`
  position: fixed;
  right: 25px;
  top: 95px;
  width: 160px;
  background-color: #333;
  color: #fff;
  list-style-type: none;
  padding: 10px;
  z-index: 1001;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  max-height: ${({ show }) => (show ? '500px' : '0')};
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(300px)')};
  transition: max-height 0.3s ease;
  user-select: none;
  animation: ${dropDown} 0.4s ease forwards;

  // Ajustes para responsividade
  @media (max-width: 1024px) {
    width: 10px;
    right: 15px;
    top: 85px;
  }

  @media (max-width: 768px) {
    width: 100px;
    right: 10px;
    top: 70px;
    padding: 8px;
  }

  @media (max-width: 480px) {
    width: 80px;
    right: 8px;
    top: 60px;
    padding: 5px;
    animation: none;
  }
`;


const ProductItem = styled.li`
  padding: 8px;
  font-size: 0.9em; /* Reduz o tamanho da fonte para dispositivos móveis */
  border-bottom: 0px solid #fff;
  animation: ${dropDown} 0.4s ease forwards;
  animation-delay: ${({ index }) => index * 0.1}s;

  &:hover {
    transform: scale(1.05);
  }

  // Ajustes para responsividade
  @media (max-width: 768px) {
    font-size: 0.8em;
    padding: 6px;
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    padding: 4px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
`;

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  width: 100%;

  @media (max-width: 768px) {
    &::after {
      content: "";
      position: absolute;
      bottom: 25px; /* Ajuste para controlar a posição vertical */
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2); /* Cor da linha */
    }
  }
`;


const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: none; /* Esconde o botão em resoluções maiores */
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 10px;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 768px) {
    display: flex; /* Mostra o botão apenas em celular */
    font-size: 20px;
    margin-top: -20px;
  }
`;
const BackButton = styled.div`
  padding: 10px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
`;