/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Cremalheiras(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Estantes/Cremalheiras/ESTANTE%20CREMALHEIRA.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0014.geometry}
          material={materials['matte aluminum']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0014_1.geometry}
          material={materials['pw-mt11040-4.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0014_2.geometry}
          material={materials['satin finish nickel']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Estantes/Cremalheiras/ESTANTE%20CREMALHEIRA.glb')