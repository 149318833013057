import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { faUser, faEnvelope, faPhone, faInfoCircle, faPencilAlt, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'; // Importando framer-motion
import Footer from '../../Screens/Home/Footer'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useInView } from 'react-intersection-observer';

const Contato = () => {
  const [formData, setFormData] = useState({
    nome: '',
    empresa: '',
    email: '',
    telefone: '',
    assunto: '',
    mensagem: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: -23.481146613401844, lng: -46.70706457627787 });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (window.grecaptcha) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute('6LdIwzsqAAAAAOH9_jNMcfSVtXuYRRDmjVUh-uKo', { action: 'submit' })
          .then((token) => {
            setRecaptchaValue(token);
            enviarFormulario(token);
          })
          .catch((error) => {
            console.error('Erro no reCAPTCHA:', error);
            alert('Erro ao verificar o reCAPTCHA.');
            setLoading(false);
          });
      });
    } else {
      console.error('grecaptcha não foi carregado.');
      alert('Erro ao carregar o reCAPTCHA.');
      setLoading(false);
    }
  };

  const enviarFormulario = (token) => {
    fetch('http://localhost:3000/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData: formData,
        token: token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setEmailEnviado(true);
          setLoading(false);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
            setEmailEnviado(false);
          }, 4000);
        } else {
          alert('Falha ao enviar o e-mail.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Erro:', error);
        alert('Erro ao enviar o e-mail.');
        setLoading(false);
      });
  };

  useEffect(() => {
    // Verifique se o objeto google e google.maps estão disponíveis
    if (window.google && window.google.maps) {
      // Função para chamar a API de Geocoding
      const geocodeAddress = (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            setCoordinates({ lat: location.lat(), lng: location.lng() });
          } else {
            console.error('Geocode não foi bem-sucedido pelo seguinte motivo: ' + status);
          }
        });
      };

      // Chame a função para buscar as coordenadas de um endereço
      geocodeAddress('Aer Refrigeração');
    } else {
      console.error('Google Maps API não está disponível.');
    }
  }, []);

  const { ref: mapRef, inView: mapInView } = useInView({
    triggerOnce: true, // Garante que a animação aconteça apenas uma vez
    threshold: 0.1, // Define a porcentagem do elemento visível para iniciar a animação
  });


  const mapStyles = {
    height: "50vh",
    width: "60%",
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        style={{ fontFamily: 'Arial, sans-serif', color: '#000' }}
      >
        <Section2 id="historia">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            style={{
              position: 'relative',
              backgroundImage: `url('images/image/img1.jpg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              zIndex: 1,
            }}></div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              style={{ zIndex: 2 }}
            >
              <h1 style={{
                fontSize: '4rem',
                marginBottom: '20px',
                fontWeight: 'bold',
                textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              }}>Contato</h1>
            </motion.div>
          </motion.div>
        </Section2>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <GridContainer>
            {/* Formulário de Contato */}
            <ContactSection>
              <h2>Entre em contato</h2>
              <p>Preencha o formulário para entrar em contato com nossa equipe.</p>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="nome">
                    <FontAwesomeIcon icon={faUser} className="icon-spacing" /> Nome: <span>*</span>
                  </label>
                  <input type="text" id="nome" placeholder="Digite o seu nome..." onChange={handleChange} required />
                </div>

                <div className="form-group">
                  <label htmlFor="empresa">
                    <FontAwesomeIcon icon={faUser} className="icon-spacing" /> Empresa: <span>*</span>
                  </label>
                  <input type="text" id="empresa" placeholder="Digite o nome de sua empresa..." onChange={handleChange} required />
                </div>

                <div className="form-group">
                  <label htmlFor="email">
                    <FontAwesomeIcon icon={faEnvelope} className="icon-spacing" /> E-mail: <span>*</span>
                  </label>
                  <input type="email" id="email" placeholder="Informe um e-mail para contato" onChange={handleChange} required />
                </div>

                <div className="form-group">
                  <label htmlFor="telefone">
                    <FontAwesomeIcon icon={faPhone} className="icon-spacing" /> Telefone:
                  </label>
                  <input type="tel" id="telefone" placeholder="(00) 0000-0000" onChange={handleChange} />
                </div>

                <div className="form-group">
                  <label htmlFor="assunto">
                    <FontAwesomeIcon icon={faInfoCircle} className="icon-spacing" /> Assunto: <span>*</span>
                  </label>
                  <select id="assunto" onChange={handleChange} required>
                    <option value="">Selecione...</option>
                    <option value="duvida">Dúvida</option>
                    <option value="suporte">Suporte</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="mensagem">
                    <FontAwesomeIcon icon={faPencilAlt} className="icon-spacing" /> Mensagem: <span>*</span>
                  </label>
                  <textarea id="mensagem" rows="6" placeholder="Digite a sua mensagem..." onChange={handleChange} required></textarea>
                </div>

                <div className="form-group form-privacy">
                  <input type="checkbox" id="privacy" required />
                  <label htmlFor="privacy">Li e concordo com os termos de Política de Privacidade.</label>
                </div>

                <button type="submit">Enviar</button>
              </form>
            </ContactSection>

            {/* Informações de Contato à Direita */}
            <ContactInfo>
              <h2>Mais Contatos</h2>
              <p style={{ fontFamily: 'Brmalls' }}>Ligue ou envie seu e-mail ou mensagem</p>

              <ContactInfoItem>
                <FontAwesomeIcon icon={faPhone} />
                <div>
                  <h3>Telefone</h3>
                  <span>( 11) 3991-8457 </span>
                </div>
              </ContactInfoItem>

              <ContactInfoItem>
                <FontAwesomeIcon icon={faWhatsapp} />
                <div>
                  <h3>WhatsApp</h3>
                  <span>(11) 98136-4824</span>
                </div>
              </ContactInfoItem>

              <ContactInfoItem>
                <FontAwesomeIcon icon={faClock} />
                <div>
                  <h3>Horário de Atendimento</h3>
                  <span>Seg à Sex 08:00 às 18:00</span><br />
                </div>
              </ContactInfoItem>

              <ContactInfoItem>
                <FontAwesomeIcon icon={faEnvelope} />
                <div>
                  <h3>Email</h3>
                  <span>sac@aer.com.br</span><br />
                </div>
              </ContactInfoItem>
            </ContactInfo>
          </GridContainer>
        </motion.div>
        {/* Seção do Mapa */}
        <MapContainer ref={mapRef}>
         
            <LoadScript googleMapsApiKey="AIzaSyBGSfMssFrLbj7qeRzzyYgOnFzEiVwm0_4" libraries={['places']}> {/* Insira a sua chave API aqui */}
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={20}
                center={coordinates}
              >
                <Marker position={coordinates} />
              </GoogleMap>
            </LoadScript>
        
          <InfoSection>
            <motion.div
              initial={{ opacity: 0, x: 50 }} // Animação inicial: invisível e deslocado para a direita
              animate={mapInView ? { opacity: 1, x: 0 } : {}} // Animação de entrada
              transition={{ duration: 1.5, delay: 0.3 }} // Pequeno atraso para o texto
            >
              <h1>Aer Refrigeração</h1>
              <p>Faça uma visita em nossa fábrica:</p>
              <Address>
                Rua Américo Briza, 153 - Vila Miriam, São Paulo - SP, 02968-070
              </Address>
              <button onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=Rua+Américo+Briza,+153+-+Vila+Miriam,+São+Paulo+-+SP,+02968-070', '_blank')}>
                Traçar Rota
              </button>
            </motion.div>
          </InfoSection>
        </MapContainer>

      </motion.div>

      <Footer />

      {loading && (
        <Overlay>
          <LoadingAnimation>
            <motion.img
              src="/images/logo/menu.png"
              alt="Loading..."
              animate={{ opacity: [0.3, 1, 0.3] }}
              transition={{ repeat: Infinity, duration: 1 }}
            />
          </LoadingAnimation>
        </Overlay>
      )}

      {emailEnviado && showSuccessMessage && (
        <FadeOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <SuccessMessage>
            <h2>E-mail enviado com sucesso!</h2>
            <p>Entraremos em contato em breve.</p>
          </SuccessMessage>
        </FadeOverlay>
      )}
    </>

  );
};

// Estilos personalizados
const Section2 = styled.section`
  height: 50vh;
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    height: 50vh; /* Reduz altura no mobile */
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 250px;
  max-width: 1500px;
  margin: 0 auto;
  padding: 40px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 20px;
  }
`;

const ContactSection = styled.section`
  padding: 20px;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Brmalls';

    @media (max-width: 768px) {
      font-size: 1rem;
      text-align: center;
    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    align-items: center;
    width: 100%;
    font-family: 'Brmalls';

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Altera para uma coluna no mobile */
      gap: 15px;
    }
  }

  .icon-spacing {
    margin-right: 10px;
  }

  .form-group {
    display: contents;
    font-family: 'Brmalls';
  }

  label {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-family: 'Brmalls';

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  input, select, textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #ccc;
    font-family: 'Brmalls';

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  /* Garantir que todos os campos ocupem uma linha inteira no mobile */
  #nome,
  #empresa,
  #email,
  #telefone,
  #assunto,
  #mensagem {
    grid-column: span 2;

    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }

  button {
    grid-column: span 2;
    background-color: #333;
    padding: 12px 25px;
    transition: background-color 0.3s ease;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    justify-self: end;

    @media (max-width: 768px) {
      width: 100%;
      font-size: 0.9rem;
      padding: 10px;
    }
  }

  button:hover {
    background-color: #555;
  }

  .form-privacy {
    grid-column: span 2;
    display: flex;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  label[for="privacy"] {
    font-size: 0.9rem;
  }
`;


const ContactInfo = styled.div`
  padding: 120px;
  border-radius: 5px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1rem;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-left: 2px solid #e0e0e0;
  padding-left: 15px;
  min-height: 100px;

  svg {
    font-size: 2rem;
    color: #555;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  div {
    margin-left: 20px;
  }

  h3 {
    font-size: 1.2rem;
    font-family: 'Brmalls';
    margin-bottom: 5px;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  span {
    font-size: 1rem;
    color: #555;
    font-family: 'Brmalls';

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const FadeOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingAnimation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessMessage = styled.div`
  text-align: center;
  color: #fff;

  h2 {
    font-size: 2rem;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 1.2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const MapSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 200px;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

const InfoSection = styled.div`
  flex: 1;
  padding: 20px;
  text-align: left;

  h1 {
    font-size: 1.9rem;
    margin-bottom: 10px;
    font-weight: bold;
    margin-left: 200px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-left: 0;
      text-align: center;
    }
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-left: 200px;
    font-family: 'Brmalls';

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-left: 0;
      text-align: center;
    }
  }

  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    margin-left: 200px;

    &:hover {
      background-color: #555;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
      padding: 8px 16px;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const Address = styled.div`
  font-size: 1.1rem;
  margin-bottom: 10px;
  margin-left: 200px;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-left: 0;
    text-align: center;
  }
`;

export default Contato;
