import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaFacebookF, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

const Footer = ({ disableAnimations }) => {
  const [isVisible, setIsVisible] = useState(false);
  const footerRef = useRef(null);

  useEffect(() => {
    if (!disableAnimations) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true); // Dispara a animação quando o rodapé for visível
          }
        },
        { threshold: 0.1 } // Ativa quando 10% do rodapé estiver visível
      );

      if (footerRef.current) {
        observer.observe(footerRef.current); // Observa o rodapé
      }

      return () => {
        if (footerRef.current) {
          observer.unobserve(footerRef.current);
        }
      };
    } else {
      // Se as animações estiverem desabilitadas, tornamos o rodapé visível diretamente
      setIsVisible(true);
    }
  }, [disableAnimations]);

  return (
    <FooterContainer ref={footerRef} isVisible={isVisible} disableAnimations={disableAnimations}>
      <FooterContent>
        {/* Redes sociais à esquerda */}
        <Column>
          <ColumnTitle>Mídia</ColumnTitle>
          <SocialMedia>
            <a href="#" aria-label="Facebook"><FaFacebookF size={30} /></a>
            <a href="#" aria-label="Instagram"><FaInstagram size={30} /></a>
            <a href="#" aria-label="LinkedIn"><FaLinkedin size={25} /></a>
            <a href="#" aria-label="YouTube"><FaYoutube size={30} /></a>
          </SocialMedia>
        </Column>

        {/* Logo no centro */}
        <LogoColumn>
          <Logo src="/images/icon/logo.png" alt="Logo" />
        </LogoColumn>

        {/* Endereço e Contato à direita */}
        <Column>
          <ColumnTitle>Endereço e Contato</ColumnTitle>
          <Text>Rua Américo Briza, 153</Text>
          <Text>Vila Miriam</Text>
          <Text>São Paulo - SP, 02968-070</Text>
          <Text>Telefone: ( 11) 3991-8457</Text>
          <Text>Email: sac@aer.com.br</Text>
          <Text>Horário de Atendimento: Seg-Sex 9h às 18h</Text>
        </Column>
      </FooterContent>

      {/* Menu de navegação no rodapé */}
      <FooterMenu>
      </FooterMenu>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FooterContainer = styled.footer`
  background-color: #000;
  padding: 40px 90px;
  color: white;
  width: 100%;
  position: relative;
  z-index: 10;

  /* Controle de visibilidade baseado na animação */
  opacity: ${({ isVisible, disableAnimations }) => (disableAnimations || isVisible ? 1 : 0)};
  
  ${({ isVisible, disableAnimations }) =>
    !disableAnimations &&
    isVisible &&
    css`
      animation: ${fadeIn} 1s ease forwards;
    `}
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  flex-wrap: wrap;
  text-align: center;
`;

const FooterMenu = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  border-top: 1px solid #444;
  margin-top: 40px;
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-family: 'Brmalls';
  transition: color 0.3s;

  &:hover {
    color: #3498db;
  }
`;

const Column = styled.div`
  flex: 1;
  min-width: 220px;
  text-align: center;
`;

const LogoColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 200px;
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;

  a {
    color: white;
    transition: color 0.3s;

    &:hover {
      color: #354A81;
    }
  }
`;

const ColumnTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 20px;
`;

const Text = styled.p`
  color: #ccc;
  font-size: 0.9rem;
  margin-bottom: 8px;
`;
