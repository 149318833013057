import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';

const SobreCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [progress, setProgress] = useState(0);
  const [inTransition, setInTransition] = useState(false);
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const slides = [
    {
      image: '/images/menu_images/render.jpg',
      title: 'Nossa Missão',
      text: 'Soluções Sustentáveis',
      description: 'Prover soluções sustentáveis e eficientes em refrigeração, ajudando empresas a preservarem produtos e reduzirem custos.',
    },
    {
      image: '/images/menu_images/vitrine2.png',
      title: 'Diferenciais Competitivos',
      text: 'Tecnologia e Atendimento',
      description: 'Utilizamos tecnologia de ponta para garantir eficiência energética, e oferecemos atendimento técnico 24/7.',
    },
    {
      image: '/images/aer2.png',
      title: 'Serviços Especializados',
      text: 'Instalação e Manutenção',
      description: 'Oferecemos instalação e manutenção preventiva e corretiva de sistemas de refrigeração, assegurando a operação contínua dos nossos clientes.',
    },
  ];

  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 1 : 0));
      }, 50);

      if (progress === 100 && !inTransition) {
        setInTransition(true);
        setFadeIn(false);
        setTimeout(() => {
          goToNextSlide();
          setFadeIn(true);
          setInTransition(false);
          setProgress(0);
        }, 500);
      }

      return () => clearInterval(interval);
    }
  }, [inView, progress, inTransition]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <PageContainer ref={inViewRef}>
      <Title1>Quem Somos</Title1>
      <CarouselContainer isVisible={inView}>
        <Arrow left onClick={goToPreviousSlide}>
          &lsaquo;
        </Arrow>
        <SlideContainer>
          {slides.map((slide, index) => (
            <Slide
              key={index}
              fadeIn={fadeIn && currentIndex === index}
              visible={currentIndex === index}
            >
              <TextContainer>
                <Title exit={!fadeIn}>{slide.title}</Title>
                <Subtitle exit={!fadeIn}>{slide.text}</Subtitle>
                <Description exit={!fadeIn}>{slide.description}</Description>
                {/* <ReadMoreButton exit={!fadeIn}>LER MAIS &#10140;</ReadMoreButton> */}
              </TextContainer>
              <ImageContainer>
                <Image src={slide.image} alt={slide.title} fadeIn={fadeIn && currentIndex === index} />
              </ImageContainer>
            </Slide>
          ))}
        </SlideContainer>
        <Arrow right onClick={goToNextSlide}>
          &rsaquo;
        </Arrow>
      </CarouselContainer>

      <DotsContainer>
        {slides.map((_, idx) => (
          <Dot key={idx} active={currentIndex === idx}>
            {currentIndex === idx && (
              <CircularProgress progress={progress} />
            )}
          </Dot>
        ))}
        <Line />
        <ViewAllNews>SOBRE</ViewAllNews>
      </DotsContainer>
    </PageContainer>
  );
};

export default SobreCarousel;

// Circular Progress using SVG
const CircularProgress = ({ progress }) => {
  const radius = 80;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg width={200} height={200} viewBox="0 0 200 200">
      <circle
        stroke="#354A81"
        fill="transparent"
        strokeWidth={16}
        r={radius}
        cx={100}
        cy={100}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{ transition: 'stroke-dashoffset 0.3s ease' }}
      />
    </svg>
  );
};

// Animações atualizadas

const slideOutRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
`;

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styled Components

const PageContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1.5s ease-in-out;
  background: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);


  @media (max-width: 768px) {
    height: 500px;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    height: 400px;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  text-align: center;
  opacity: ${(props) => (props.fadeIn ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  animation: ${(props) => (props.fadeIn ? slideInLeft : slideOutRight)} 1s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 700px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`;

const TextContainer = styled.div`
  width: 45%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 10px;
  animation: ${(props) => (props.exit ? slideOutRight : slideInLeft)} 0.8s ease-in-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 20px;
  animation: ${(props) => (props.exit ? slideOutRight : slideInLeft)} 0.8s ease-in-out;
font-family: 'Brmalls';
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 80%;
  animation: ${(props) => (props.exit ? slideOutRight : slideInLeft)} 0.8s ease-in-out;
font-family: 'Brmalls';
  @media (max-width: 768px) {
    font-size: 1rem;
    max-width: 100%;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const ReadMoreButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 30px;
  border: 2px solid #000;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  animation: ${(props) => (props.exit ? slideOutRight : slideInLeft)} 0.8s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? 'left: -40px;' : 'right: -40px;')}
  transform: translateY(-50%);
  font-size: 3rem;
  font-weight: 100;
  color: black;
  cursor: pointer;
  z-index: 10;
  user-select: none;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const DotsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 768px) {
    gap: 6px;
  }

  @media (max-width: 480px) {
    gap: 4px;
    margin-top: 100px;
  }
`;

const Dot = styled.div`
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  position: relative;
`;

const Line = styled.hr`
  height: 1px;
  width: 40px;
  margin: 0 10px;
  background-color: #bbb;
  border: none;
`;

const ViewAllNews = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
font-family: 'Brmalls';
  &:hover {
    text-decoration: underline;
  }
`;

const Title1 = styled.div`
font-size: 2.5rem;
 margin-top: 10px;
margin-bottom: 100px;
font-family: 'Ginza Heavy', sans-serif;
`;

