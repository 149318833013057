import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { motion, useViewportScroll } from 'framer-motion';
import styled, { keyframes, css } from 'styled-components';
import NextSection from './NextSection';
import Details from '../Screens/Product/Design';
import Rotate from '../Screens/Product/Rotate';
import Video from '../Screens/Product/Video';
import Galeria from '../Screens/Product/Galeria';
import Footer from '../Screens/Home/Footer';


const productContent = {
  'BALCÃO PORTO HORIZONTAL': {
    words: ['BALCÃO'],
    smallText: 'PORTO HORIZONTAL',
    video: 'https://storage.googleapis.com/videos-aer/Balcao/boxhz/boxhz.mp4',
    videoMobile: 'https://storage.googleapis.com/videos-aer/Balcao/boxhz/boxhzmobile.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'DEPOSITO': {
    words: ['ESTANTES'],
    smallText: 'DEPÓSITO',
    video: 'https://storage.googleapis.com/videos-aer/Estantes/Deposito/deposito8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'BALCÃO PORTO VERTICAL': {
    words: ['BALCÃO'],
    smallText: 'PORTO VERTICAL',
    video: 'https://storage.googleapis.com/videos-aer/Balcao/boxvt/boxvt.mp4',
    videoMobile: 'https://storage.googleapis.com/videos-aer/Balcao/boxvt/boxvtmobile.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'HIGIENIZAÇÃO': {
    words: ['ESTANTES'],
    smallText: 'HIGIENIZAÇÃO',
    video: 'https://storage.googleapis.com/videos-aer/Estantes/Higienizacao/higienizacao8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'ESTOCAGEM': {
    words: ['ESTANTES'],
    smallText: 'ESTOCAGEM',
    video: 'https://storage.googleapis.com/videos-aer/Estantes/Estocagem/estocagem8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'CREMALHEIRAS': {
    words: ['ESTANTES'],
    smallText: 'CREMALHEIRAS',
    video: 'https://storage.googleapis.com/videos-aer/Estantes/Cremalheiras/cremalheira8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'VITRINE PORTO GALICIA': {
    words: ['VITRINE'],
    smallText: ' PORTO GALICIA',
    video: 'https://storage.googleapis.com/videos-aer/Vitrines/PG/vitrinepg8k.mp4',
    videoMobile: 'https://storage.googleapis.com/videos-aer/Vitrines/PG/pgmobile.mp4', 
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'VITRINE PORTO PARIS': {
    words: ['VITRINE'],
    smallText: 'PORTO PARIS',
    video: 'https://storage.googleapis.com/videos-aer/Vitrines/PP/vitrinepp8k.mp4',
    videoMobile: 'https://storage.googleapis.com/videos-aer/Vitrines/PP/ppmobile.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'EXPOSITOR PORTO VISEU': {
    words: ['EXPOSITOR'],
    smallText: 'PORTO VISEU',
    video: 'https://storage.googleapis.com/videos-aer/Expositor/viseu8k.mp4',
    videoMobile: 'https://storage.googleapis.com/videos-aer/Expositor/viseumobileinicio.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'MESA': {
    words: ['MOBILIÁRIO'],
    smallText: 'MESA',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa/mesa8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'BUFFET': {
    words: ['MOBILIÁRIO'],
    smallText: 'BUFFET',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Buffet/buffetparis8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'MESA COM PIA': {
    words: ['MOBILIÁRIO'],
    smallText: 'MESA COM PIA',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa%20com%20pia/mesacompia8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'LAVADORA': {
    words: ['MOBILIÁRIO'],
    smallText: 'LAVADORA',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Lavadora/mesalavadora8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'LAVATORIO': {
    words: ['MOBILIÁRIO'],
    smallText: 'LAVATORIO',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Lavat%C3%B3rio/lavatorio8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'MESA GAVETEIRO': {
    words: ['MOBILIÁRIO'],
    smallText: 'MESA GAVETEIRO',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa%20Gaveteiro/mesagaveteiro8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'MESA LIXEIRA': {
    words: ['MOBILIÁRIO'],
    smallText: 'MESA LIXEIRA',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa%20lixeira/mesalixeira8k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'PRATELEIRA DE PAREDE': {
    words: ['MOBILIÁRIO'],
    smallText: 'PRATELEIRA DE PAREDE',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Prateleira%20de%20parede/prateleiraparede4k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'CAIXA DE GORDURA': {
    words: ['ACESSÓRIOS'],
    smallText: 'CAIXA DE GORDURA',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Acessorios/Caixa%20de%20gordura/caixadegordura4k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  },
  'GRELHA DE PISO': {
    words: ['ACESSÓRIOS'],
    smallText: 'GRELHA DE PISO',
    video: 'https://storage.googleapis.com/videos-aer/Mobiliario/Acessorios/Grelha%20de%20piso/grelhapiso4k.mp4',
    sections: ['Sobre', 'Design', 'Rotate', 'Galeria']
  }
};


const CartItem = ({ productName, productDetails, onRemove }) => {
  return (
    <CartItemWrapper>
      {/* Carregando a imagem do produto */}
      <ProductImage src={productDetails.image} alt={productName} />
      <ProductDetails>
        <ProductName>{productName}</ProductName>
      </ProductDetails>
      <RemoveButton onClick={onRemove}>X</RemoveButton>
    </CartItemWrapper>
  );
};

const Cart = ({ productName, productDetails, removeProduct }) => {
  return (
    <CartWrapper>
      <CartItem
        productName={productName}
        productDetails={productDetails}
        onRemove={removeProduct}
      />
    </CartWrapper>
  );
};

const allSections = ['Sobre', 'Design', 'Rotate', 'Video', 'Galeria',];

const ProductPage = ({ setMenuVisible }) => {
  const { productName } = useParams();

  const [currentSection, setCurrentSection] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [useModelViewer2, setUseModelViewer2] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [selectedService, setSelectedService] = useState('Aplicativo');
  const [isBlurExiting, setIsBlurExiting] = useState(false);
  const [productInCart, setProductInCart] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [isOutsideHome, setIsOutsideHome] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [isAutoHover, setIsAutoHover] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [remainingTimeShowContent, setRemainingTimeShowContent] = useState(2500);
  const [remainingTimeEndAutoHover, setRemainingTimeEndAutoHover] = useState(4700);
  const [showNewContent, setShowNewContent] = useState(false);
  const [previousProductName, setPreviousProductName] = useState(productName);
  const [showTitleAndDescription, setShowTitleAndDescription] = useState(false);
  const [previousProductDetails, setPreviousProductDetails] = useState(productContent[productName] || {}); // Armazena o produto anterior
  const [currentProductDetails, setCurrentProductDetails] = useState(productContent[productName] || {}); // Produto atual exibido
  const [showButton, setShowButton] = useState(false);

  const isMobile = window.innerWidth <= 768;


  // Referências para os timers
  const timerShowContentRef = useRef(null);
  const timerEndAutoHoverRef = useRef(null);

  // Controle para verificar se o usuário está na sessão do vídeo
  const isInVideoSection = useRef(true);

  // Referências de tempo inicial
  const showContentStartTimeRef = useRef(Date.now());
  const autoHoverStartTimeRef = useRef(Date.now());

  const startTimers = () => {
    timerShowContentRef.current = setTimeout(() => setShowContent(true), remainingTimeShowContent);
    timerEndAutoHoverRef.current = setTimeout(() => setIsAutoHover(false), remainingTimeEndAutoHover);
  };

  const clearTimers = () => {
    clearTimeout(timerShowContentRef.current);
    clearTimeout(timerEndAutoHoverRef.current);
  };

  const pauseTimers = () => {
    const elapsedShowContent = Date.now() - showContentStartTimeRef.current;
    const elapsedAutoHover = Date.now() - autoHoverStartTimeRef.current;
    setRemainingTimeShowContent(Math.max(0, remainingTimeShowContent - elapsedShowContent));
    setRemainingTimeEndAutoHover(Math.max(0, remainingTimeEndAutoHover - elapsedAutoHover));
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearTimers();
    } else {
      showContentStartTimeRef.current = Date.now();
      autoHoverStartTimeRef.current = Date.now();
      startTimers();
    }
  };

  useEffect(() => {
    if (productName !== currentProductDetails.name) {
      setPreviousProductDetails(currentProductDetails); // Salva os detalhes do produto atual como "anterior"

      setIsExiting(true); // Inicia a animação de saída
      setShowNewContent(false); // Reseta o novo conteúdo
      setShowTitleAndDescription(false); // Esconde o título e descrição ao trocar de produto
      setShowButton(false);

      const exitTimeout = setTimeout(() => {
        setIsExiting(false); // Termina a saída
        setCurrentProductDetails(productContent[productName] || {}); // Atualiza para o novo produto
        setShowNewContent(true); // Exibe o novo conteúdo (vídeo e seções)

        // Timeout para exibir título e descrição com atraso
        const titleDescriptionTimeout = setTimeout(() => {
          setShowTitleAndDescription(true); // Exibe o título e a descrição com o atraso desejado

          // Timeout adicional para o botão, após o texto
          const buttonTimeout = setTimeout(() => {
            setShowButton(true); // Exibe o botão "Orçamento"
          }, 0); // Atraso de 0,5 segundo após os textos
  
          return () => clearTimeout(buttonTimeout); // Limpa o timeout do botão
  
        }, 2500); // 2,5 segundos para o título e descrição
  
        return () => clearTimeout(titleDescriptionTimeout);
      }, 900); // Tempo da animação de saída
  
      return () => clearTimeout(exitTimeout);
    }
  }, [productName, currentProductDetails]);


  const newProductDetails = productContent[productName] || {};

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    startTimers();

    return () => {
      clearTimers();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    scrollYProgress.onChange((progress) => {
      const inVideoSection = progress < 0.1; // Ajuste conforme a posição da sessão do vídeo

      if (inVideoSection && !isInVideoSection.current) {
        // Retoma os temporizadores ao retornar à sessão
        isInVideoSection.current = true;
        showContentStartTimeRef.current = Date.now();
        autoHoverStartTimeRef.current = Date.now();
        startTimers();
      } else if (!inVideoSection && isInVideoSection.current) {
        // Pausa os temporizadores ao sair da sessão
        isInVideoSection.current = false;
        clearTimers();
        pauseTimers();
      }

      // Define o estado se está fora da sessão "home" para mostrar a seta para subir
      setIsOutsideHome(progress > 0.1);
    });
  }, [scrollYProgress]);

  useEffect(() => {
    const loadResources = async () => {
      try {
        await Promise.all([new Promise((resolve) => setTimeout(resolve, 2000))]);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar recursos:', error);
      }
    };

    setLoading(true);
    loadResources();

    return () => { };
  }, [productName]);

  useEffect(() => {
    if (isModalOpen && !isExiting) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isModalOpen, isExiting]);

  const productDetails = productContent[productName] || {};


  if (!productDetails) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', textAlign: 'center' }}>
        <h1>Produto não encontrado</h1>
      </div>
    );
  }

  const words = productDetails.words || [];
  const smallText = productDetails.smallText || "";
  const availableSections = productDetails.sections.includes('Todes') ? allSections : productDetails.sections || [];
  const videoUrl = productDetails.video;

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const handleToggleModelViewer = () => {
    setUseModelViewer2(!useModelViewer2);
  };

  const handleOpenModal = () => {
    setProductInCart({ productName, productDetails });
    setIsModalOpen(true);
    setIsExiting(false);
  };

  const handleCloseModal = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 1000);
  };

  const handleRemoveFromCart = () => {
    setProductInCart(null);
  };

  const toggleServiceSelection = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter((s) => s !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const handleVideoEnd = () => {
    setShowContent(true); // Ativa o conteúdo para exibir com o efeito de fade-in
    setIsFading(true); // Inicia o efeito de clareamento
    setTimeout(() => {
      setVideoEnded(true); // Após o efeito, exibe a imagem
    }, 1000);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <PageMenu onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <MenuItem onClick={() => scrollToSection('home')}>
          <MenuDot className={`menu-dot`}>
            <ProgressCircle />
          </MenuDot>
          <MenuText as={motion.span} initial={{ opacity: 0 }} animate={{ opacity: isHovered ? 1 : 0 }} transition={{ duration: 0.5 }}>
            {productName}
          </MenuText>

        </MenuItem>

        {availableSections.includes('Sobre') && (
          <MenuItem onClick={() => scrollToSection('about')}>
            <MenuDot />
            <MenuText as={motion.span} initial={{ opacity: 0 }} animate={{ opacity: isHovered ? 1 : 0 }} transition={{ duration: 0.5 }}>
              Sobre
            </MenuText>
          </MenuItem>
        )}

        {availableSections.includes('Design') && (
          <MenuItem onClick={() => scrollToSection('Design')}>
            <MenuDot />
            <MenuText as={motion.span} initial={{ opacity: 0 }} animate={{ opacity: isHovered ? 1 : 0 }} transition={{ duration: 0.5 }}>
              Design
            </MenuText>
          </MenuItem>
        )}
        {availableSections.includes('Rotate') && (
          <MenuItem onClick={() => scrollToSection('Rotate')}>
            <MenuDot />
            <MenuText as={motion.span} initial={{ opacity: 0 }} animate={{ opacity: isHovered ? 1 : 0 }} transition={{ duration: 0.5 }}>
              3D
            </MenuText>
          </MenuItem>
        )}
        {availableSections.includes('Video') && (
          <MenuItem onClick={() => scrollToSection('Video')}>
            <MenuDot />
            <MenuText as={motion.span} initial={{ opacity: 0 }} animate={{ opacity: isHovered ? 1 : 0 }} transition={{ duration: 0.5 }}>
              Video
            </MenuText>
          </MenuItem>
        )}
        {availableSections.includes('Galeria') && (
          <MenuItem onClick={() => scrollToSection('Galeria')}>
            <MenuDot />
            <MenuText as={motion.span} initial={{ opacity: 0 }} animate={{ opacity: isHovered ? 1 : 0 }} transition={{ duration: 0.5 }}>
              Galeria
            </MenuText>

          </MenuItem>

        )}
        {isOutsideHome && <ArrowUp onClick={scrollToTop} initial={{ opacity: 0 }} animate={{ opacity: 1 }} />}  {/* Mostra a seta */}
      </PageMenu>

      <Section id="home">
        <BackgroundOverlay />

        {/* Vídeo de saída (produto anterior) */}
        <motion.div

        >
          <VideoBackground productDetails={productContent[productName]} />
        </motion.div>

        {/* Conteúdo do produto com transição de saída */}
        <ContentWrapper productName={productName}>

          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: showTitleAndDescription ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            {productDetails.words && productDetails.words.map((word, i) => (
              <motion.span
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: showTitleAndDescription ? 1 : 0 }}
                transition={{ duration: 1, delay: i * 0.2 }}
              >
                {word}{" "}
              </motion.span>
            ))}
          </motion.h1>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: showTitleAndDescription ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            {productDetails.smallText}
          </motion.p>

          {showButton && (
            <StyledButton
              href="#"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: showNewContent ? 1 : 0, x: 0 }}
              transition={{ duration: 1.5, delay: 0, ease: "easeOut" }}
              onClick={handleOpenModal}
            >
              <span>Orçamento</span>
              <svg width="13px" height="10px" viewBox="0 0 13 10">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </StyledButton>
          )}


          {isModalOpen && (
            <>
              <BlurBackground as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }} />
              <ModalWrapper as={motion.div} initial={{ x: '100vw' }} animate={{ x: 0 }} transition={{ duration: 1.5 }}>
                <CloseButton onClick={handleCloseModal}>X</CloseButton>
                <Form>
                  <Input placeholder="Nome" />
                  <Input placeholder="Empresa" />
                  <DoubleInputWrapper>
                    <Input placeholder="E-mail" />
                    <Input placeholder="Telefone" />
                  </DoubleInputWrapper>
                  <ServiceOptions>
                    <span>Escolha um ou mais serviços:</span>
                    <ServiceButton
                      type="button"
                      selected={selectedServices.includes('Venda única')}
                      onClick={() => toggleServiceSelection('Venda única')}
                    >
                      Loja nova
                    </ServiceButton>
                    <ServiceButton
                      type="button"
                      selected={selectedServices.includes('Reforma de loja')}
                      onClick={() => toggleServiceSelection('Reforma de loja')}
                    >
                      Reforma de loja
                    </ServiceButton>
                    <ServiceButton
                      type="button"
                      selected={selectedServices.includes('Troca de equipamento')}
                      onClick={() => toggleServiceSelection('Troca de equipamento')}
                    >
                      Troca de equipamento
                    </ServiceButton>
                  </ServiceOptions>

                  <TextArea placeholder="Fale um pouco mais sobre o seu pedido" />
                  <CaptchaWrapper>
                    <span>Não sou um robô</span>
                    <Captcha>reCAPTCHA</Captcha>
                  </CaptchaWrapper>
                  <SubmitButton>Enviar</SubmitButton>
                  <Terms>
                    Ao clicar em 'Enviar' você concorda com nossos <a href="/">termos de uso</a> e <a href="/">política de privacidade</a>.
                  </Terms>
                </Form>
              </ModalWrapper>
            </>
          )}
        </ContentWrapper>
      </Section>



      {!isMobile && (
        <DownArrowWrapper>
          <DownArrow onClick={() => scrollToSection('about')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="6 9 12 15 18 9" />
          </DownArrow>
        </DownArrowWrapper>
      )}

      {
        availableSections.includes('Sobre') && (
          <SectionAbout id="about">
            <NextSection />
          </SectionAbout>
        )
      }
      {
        availableSections.includes('Design') && (
          <Section id="Design">
            <Details />
          </Section>
        )
      }
      {
        availableSections.includes('Rotate') && currentSection !== 'Rotate' && (
          <ViewerSection id="Rotate">
            {/* Passando o productName como selectedProduct */}
            <Rotate setMenuVisible={setMenuVisible} selectedProduct={productName} />
          </ViewerSection>
        )
      }


      {
        availableSections.includes('Video') && (
          <Section id="Video">
            <Video />
          </Section>
        )
      }
      {
        availableSections.includes('Galeria') && (
          <SectionEsp id="Galeria">
            <Galeria />
          </SectionEsp>
        )
      }

      <FooterSection>
        <Footer disableAnimations={true} />
      </FooterSection>


    </>
  );
};

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
`;

const DownArrowWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  @media (max-width: 768px) {
    bottom: 10px; /* Ajusta a posição da seta em dispositivos móveis */
  }
`;

const DownArrow = styled.svg`
  width: 40px;
  height: 40px;
  color: #fff;
  animation: ${bounce} 2s infinite;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px; /* Reduz o tamanho da seta em dispositivos móveis */
  }
`;

const PageMenu = styled.div`
  position: fixed;
  top: 90%;
  left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform: translateY(-50%);
  padding: 20px;
  background: none;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 0% 100%, #ffffff, rgba(255, 255, 255, 0.75) 33%, rgba(255, 255, 255, 0) 60%);
    filter: blur(60px);
    z-index: -1;
    opacity: 0;
    transform: scale(0.8); // Escala inicial menor
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &:hover::before {
    opacity: 1;
    transform: scale(4);
  }

  @media (max-width: 768px) {
    display: none; // Oculta o menu em resoluções de celular
  }
`;


const ArrowUp = styled(motion.div)`
  width: 10px;
  height: 10px;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  transform: rotate(135deg);  /* Rotaciona a seta para cima */
  margin-top: 10px;
  margin-left: -4px;
  cursor: pointer;  /* Adiciona um cursor pointer */
  user-select: none; /* Impede a seleção de texto */
  
  &:hover {
    opacity: 1;  /* A seta aparece ao passar o mouse */
  }
`;




const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  &:hover ${ArrowUp} {
    opacity: 1;  /* A seta aparece ao passar o mouse */
  }
`;


const MenuDot = styled.div`
  position: relative;
  width: 3px;
  height: 3px;
  background-color: #354A81;
  border-radius: 50%;
  font-family: 'Ginza Heavy', sans-serif;
  &.active {
    background-color: #fff;
  }
`;

const MenuText = styled(motion.span)`
  margin-left: 15px;
  color: #CBCACA;
  font-family: 'Ginza Heavy', sans-serif;
  transition: transform 0.3s ease; // Transição suave para o movimento

  &.active {
    color: #f8f8f8;
  }

  &:hover {
    transform: translateX(5px); // Move 5px para a direita ao passar o mouse
  }

  &:not(:hover) {
    transform: translateX(0); // Retorna à posição original ao remover o hover
  }
`;


const Section = styled.section`
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 100vh;
  }
`;



const SectionEsp = styled.section`
  min-height: 50vh;
  margin: 0;
  padding: 0;
   background-color: #000; /* Fundo preto para todas as seções */
`;

const SectionAbout = styled.section`
  min-height: 100vh;
  margin: 0;
  padding: 0;
  
`;

const ViewerSection = styled.section`
  min-height: 50vh;
  margin: 0;
  padding: 0;
`;

const FooterSection = styled.section`
  min-height: 20vh;
  margin: 0;
  padding: 0;
  background-color: #000; /* Certifique-se de que o rodapé tenha fundo */
  z-index: 10; /* Mantém o rodapé acima do fundo */
`;


const ProgressCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  box-sizing: border-box;
  pointer-events: none;
  transform: rotate(-90deg);

  ${(props) =>
    props.active &&
    css`
      border-top-color: #fff;
      border-right-color: #fff;
      border-bottom-color: #fff;
      border-left-color: transparent;
    `}
`;



const SmallText = styled.p`
  font-size: clamp(14px, 2vw, 20px); // Responsivo entre 14px e 20px
  color: #FFF;
  text-align: center;
  margin: 0;
  z-index: 10;
  
  @media (max-width: 768px) {
    margin-top: 1px; // Desce o texto
    text-align: center;
  }
`;


const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 999;
`;

const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const CartItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const ProductImage = styled.img`
  width: 50px;
  height: auto;
  border-radius: 4px;
`;

const ProductDetails = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const ProductName = styled.h3`
  margin: 0;
  font-size: 14px;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  color: red;
  font-size: 16px;
  cursor: pointer;
`;



const SmallTextContainer = styled(motion.p)`
  font-size: clamp(14px, 2vw, 40px);
  font-weight: 100;
  color: #FFF;
  text-align: left;
  margin-right: 40vw;
  user-select: none;

 
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
`;

const Input = styled.input`
  padding: 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  transition: border-bottom-color 0.3s ease;

  &:hover, &:focus {
    border-bottom-color: #333;
  }
`;

const DoubleInputWrapper = styled.div`
  display: flex;
  gap: 10px;

  input {
    flex: 1;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 100px;
  margin-top: 20px;
  resize: none;
`;

const ServiceOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
  span {
    width: 100%;
    font-size: 20px;
    color: black;
  }
`;

const ServiceButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => (props.selected ? '#000' : '#e8e4e4')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 14px;
  }
`;

const Captcha = styled.div`
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  padding: 12px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: #949595;
  }
`;

const Terms = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  color: #000;
  a {
    color: #000;
    text-decoration: underline;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 90vw; // Adapta a largura em telas pequenas
  max-width: 500px; // Limita a largura máxima em telas grandes
  padding: 20px;
  background: white;
  z-index: 10000;
  overflow-y: auto;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;  // Mantém o vídeo atrás de outros elementos
`;

const VideoBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 100vh; // Garante que ocupa toda a altura em dispositivos móveis
  }
`;

const VideoBackground = ({ productDetails }) => {
  const [videoUrl, setVideoUrl] = useState(productDetails.video);
  const [videoStyles, setVideoStyles] = useState({
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    marginTop: '0', // Estilo padrão
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setVideoUrl(productDetails.videoMobile || productDetails.video);

        let newMarginTop = '0';
        if (productDetails.smallText === 'PORTO VERTICAL') {
          newMarginTop = '10vh';
        } else if (productDetails.smallText === 'PORTO VISEU') {
          newMarginTop = '5vh';
        }

        setVideoStyles({
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
          marginTop: newMarginTop,
        });
      } else {
        setVideoUrl(productDetails.video);
        setVideoStyles({
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
          marginTop: '0', // Sem ajuste de margem para desktop
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [productDetails]);

  return (
    <VideoBackgroundContainer>
      <video
        src={videoUrl}
        autoPlay
        muted
        playsInline
        style={videoStyles}
      />
    </VideoBackgroundContainer>
  );
};


const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Fundo preto para a transição */
  z-index: -1;
`;


const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Escurece o vídeo
  z-index: 100; // Coloca o overlay acima do vídeo
`;

const VideoAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  text-align: left;
  color: #FFF;
  position: relative;
  margin-top: 0; /* Remova qualquer margem superior */
  padding-bottom: 0; /* Certifique-se de que não há padding na parte inferior */


  h1 {
    font-size: clamp(110px, 5vw, 40px);
    font-weight: 700;
    color: #393c41;
    user-select: none;
    margin-bottom: 0px;
    margin-top: 30vh;  
    margin-left: 15vh;
    
    @media (max-width: 768px) {
      font-size: 60px;
      text-align: center; // Centraliza em telas menores
      margin-top: ${(props) =>
        props.productName === 'BALCÃO PORTO VERTICAL' ? '-1vh' : 
        props.productName === 'EXPOSITOR PORTO VISEU' ? '-1vh' : '5vh'};
      margin-left: 1vh;
    }
  }

p {
    font-size: clamp(35px, 4vw, 24px);
    font-weight: 100;
    color: #FFF;
    margin: 5px 0 10px 0;
    margin-left: 18vh;

    @media (max-width: 768px) {
      font-size: 20px;
      text-align: center;  // Centraliza em telas menores
      margin: 2vh auto; // Centraliza e ajusta o espaçamento em dispositivos móveis
      margin-top: ${(props) =>
        props.productName === 'BALCÃO PORTO VERTICAL' ? '66vh' :
        props.productName === 'EXPOSITOR PORTO VISEU' ? '68vh' : '55vh'}; // Ajuste a margem superior para "PORTO VERTICAL" e "PORTO VISEU"
    }
  }


  @media (max-width: 768px) {
    align-items: center;  // Alinha centralizado em telas menores
    margin-top: 18vh;  
     margin-right: 40vh; 
     margin: 12vh auto; // Centraliza e ajusta o espaçamento em dispositivos móveis
  }
`;


const StyledButton = styled(motion.a)`
  position: relative;
  margin: auto;
  font-size: clamp(12px, 2vw, 16px); 
  padding: clamp(8px, 1.5vh, 16px) clamp(12px, 4vw, 20px); 
  display: inline-flex; /* Usa inline-flex para manter o conteúdo alinhado sem expandir o botão */
  align-items: center; /* Centraliza o conteúdo verticalmente */
  color: white;
  font-family: 'Ginza Heavy', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 28px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  z-index: 100;

  margin-left: 20vh;
  margin-top: 1vh;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 28px;
    background: rgba(53, 74, 129, 0.5);
    width: ${({ isAutoHover }) => (isAutoHover ? '100%' : '56px')};
    height: clamp(30px, 7vw, 56px); 
    z-index: -1;
    transition: width ${({ isAutoHover }) => (isAutoHover ? '3s' : '1s')} ease;
  }

  svg {
    position: relative;
    top: 0;
    margin-left: 8px; 
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: white;
    stroke-width: 2;
    transform: ${({ isAutoHover }) => (isAutoHover ? 'translateX(0)' : 'translateX(-5px)')};
    transition: all 0.3s ease;
  }

  &:hover:before {
    width: 100%;
    transition: width 0.3s ease;
    background: rgba(53, 74, 129, 1);
  }

  &:hover svg {
    transform: translateX(0);
  }

  &:active {
    transform: scale(0.96);
  }

  @media (max-width: 768px) {
    width: auto; /* Define o botão como auto para não expandir a largura */
    padding: 10px 16px; /* Ajusta o padding para manter o tamanho proporcional */
    margin-left: 150px;
    margin-top: 0;

     &:before {
      width: 40px; /* Reduz o tamanho da bolinha em telas menores */
      height: 40px;
    }
  }
`;


const Title = styled(motion.h1)`
  align-self: flex-start;
  margin-top: 1vh; // Posição inicial em telas maiores
  margin-left: 10vw;
  font-size: 5vw;
  font-weight: 700;
  color: #393c41;
  user-select: none;
`;



export default ProductPage;
