import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FaMedal } from 'react-icons/fa';
import { GiBreakingChain } from "react-icons/gi";
import { FaLightbulb } from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable';


const productDetails = {
  'BALCÃO PORTO HORIZONTAL': {
    images: ['https://storage.googleapis.com/videos-aer/Design/Balcao/boxhz/design1.png', 'https://storage.googleapis.com/videos-aer/Design/Balcao/boxhz/design2.png', 'https://storage.googleapis.com/videos-aer/Design/Balcao/boxhz/design3.png'],
    descriptions: ['Produzido com materiais de primeira linha e atenção aos mínimos detalhes, garantindo um acabamento impecável e alta resistência.', 'Projetado para uso intenso e prolongado, com estrutura robusta que assegura sua integridade e desempenho ao longo do tempo.', 'Combina design inovador e tecnologia avançada para oferecer uma estética sofisticada, alinhada às tendências contemporâneas e às necessidades atuais do mercado.'],
    titles: ['Qualidade', 'Durabilidade', 'Modernidade'],
  },
  'BALCÃO PORTO VERTICAL': {
    images: ['https://storage.googleapis.com/videos-aer/Design/Balcao/boxvt/1.png', 'https://storage.googleapis.com/videos-aer/Design/Balcao/boxvt/2.png', 'https://storage.googleapis.com/videos-aer/Design/Balcao/boxvt/3.png'],
    descriptions: ['Produzido com materiais de primeira linha e atenção aos mínimos detalhes, garantindo um acabamento impecável e alta resistência.', 'Projetado para uso intenso e prolongado, com estrutura robusta que assegura sua integridade e desempenho ao longo do tempo.', 'Combina design inovador e tecnologia avançada para oferecer uma estética sofisticada, alinhada às tendências contemporâneas e às necessidades atuais do mercado.'],
    titles: ['Qualidade', 'Durabilidade', 'Modernidade'],
  },
  'DEPOSITO': {
    images: ['/images/image/Design/Estantes/Deposito/design1.png', '/images/image/Design/Estantes/Deposito/design2.png', '/images/image/Design/Estantes/Deposito/design3.png'],
    descriptions: ['Imagem 1 do Depósito', 'Imagem 2 do Depósito', 'Imagem 3 do Depósito'],
    titles: ['Título 1 do Depósito', 'Título 2 do Depósito', 'Título 3 do Depósito'],
  },
  'EXPOSITOR PORTO VISEU': {
    images: ['https://storage.googleapis.com/videos-aer/Design/Expositor/1.png', 'https://storage.googleapis.com/videos-aer/Design/Expositor/3.png', 'https://storage.googleapis.com/videos-aer/Design/Expositor/2.png'],
    descriptions: ['Produzido com materiais de primeira linha e atenção aos mínimos detalhes, garantindo um acabamento impecável e alta resistência.', 'Projetado para uso intenso e prolongado, com estrutura robusta que assegura sua integridade e desempenho ao longo do tempo.', 'Combina design inovador e tecnologia avançada para oferecer uma estética sofisticada, alinhada às tendências contemporâneas e às necessidades atuais do mercado.'],
    titles: ['Qualidade', 'Durabilidade', 'Modernidade'],
  },
  'VITRINE PORTO PARIS': {
    images: ['https://storage.googleapis.com/videos-aer/Design/Vitrines/PP/pp2.png', 'https://storage.googleapis.com/videos-aer/Design/Vitrines/PP/pp3.png', 'https://storage.googleapis.com/videos-aer/Design/Vitrines/PP/pp1.png'],
    descriptions: ['Materiais premium e design eficiente para máxima preservação e segurança dos alimentos.', 'Estrutura robusta e resistente, garantindo longa vida útil com mínima manutenção.', 'Design moderno e elegante, perfeito para ambientes que buscam inovação e estilo.'],
    titles: ['Qualidade', 'Durabilidade', 'Modernidade'],
  },
  'VITRINE PORTO GALICIA': {
    images: ['https://storage.googleapis.com/videos-aer/Design/Vitrines/PG/design2.png', 'https://storage.googleapis.com/videos-aer/Design/Vitrines/PG/design3.png', 'https://storage.googleapis.com/videos-aer/Design/Vitrines/PG/design1.png'],
    descriptions: ['Produzido com materiais de primeira linha e atenção aos mínimos detalhes, garantindo um acabamento impecável e alta resistência.', 'Projetado para uso intenso e prolongado, com estrutura robusta que assegura sua integridade e desempenho ao longo do tempo.', 'Combina design inovador e tecnologia avançada para oferecer uma estética sofisticada, alinhada às tendências contemporâneas e às necessidades atuais do mercado.'],
    titles: ['Qualidade', 'Durabilidade', 'Modernidade'],
  },
  'CREMALHEIRAS': {
    images: ['/images/image/Design/Estantes/Cremalheiras/3.png', '/images/image/Design/Estantes/Cremalheiras/2.png', '/images/image/Design/Estantes/Cremalheiras/1.png'],
    descriptions: ['Imagem 1 da Cremalheira', 'Imagem 2 da Cremalheira', 'Imagem 3 da Cremalheira'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'ESTOCAGEM': {
    images: ['/images/image/Design/Estantes/Estocagem/3.png', '/images/image/Design/Estantes/Estocagem/2.png', '/images/image/Design/Estantes/Estocagem/1.png'],
    descriptions: ['Imagem 1 da Estocagem', 'Imagem 2 da Estocagem', 'Imagem 3 da Estocagem'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'HIGIENIZAÇÃO': {
    images: ['/images/image/Design/Estantes/Higienizacao/3.png', '/images/image/Design/Estantes/Higienizacao/2.png', '/images/image/Design/Estantes/Higienizacao/1.png'],
    descriptions: ['Imagem 1 da Higienização', 'Imagem 2 da Higienização', 'Imagem 3 da Higienização'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'MESA': {
    images: ['/images/image/Design/Mobiliario/Mesa/3.png', '/images/image/Design/Mobiliario/Mesa/2.png', '/images/image/Design/Mobiliario/Mesa/1.png'],
    descriptions: ['Imagem 1 da Mesa', 'Imagem 2 da Mesa', 'Imagem 3 da Mesa'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'BUFFET': {
    images: ['/images/image/Design/Mobiliario/Buffet/3.png', '/images/image/Design/Mobiliario/Buffet/2.png', '/images/image/Design/Mobiliario/Buffet/1.png'],
    descriptions: ['Imagem 1 do Buffet', 'Imagem 2 do Buffet', 'Imagem 3 do Buffet'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'MESA COM PIA': {
    images: ['/images/image/Design/Mobiliario/Mesacmpia/3.png', '/images/image/Design/Mobiliario/Mesacmpia/2.png', '/images/image/Design/Mobiliario/Mesacmpia/1.png'],
    descriptions: ['Imagem 1 da Mesa com Pia', 'Imagem 2 da Mesa com Pia', 'Imagem 3 da Mesa com Pia'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'LAVADORA': {
    images: ['/images/image/Design/Mobiliario/Lavadora/3.png', '/images/image/Design/Mobiliario/Lavadora/2.png', '/images/image/Design/Mobiliario/Lavadora/1.png'],
    descriptions: ['Imagem 1 da Lavadora', 'Imagem 2 da Lavadora', 'Imagem 3 da Lavadora'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'LAVATORIO': {
    images: ['/images/image/Design/Mobiliario/Lavatorio/3.png', '/images/image/Design/Mobiliario/Lavatorio/2.png', '/images/image/Design/Mobiliario/Lavatorio/1.png'],
    descriptions: ['Imagem 1 do Lavatório', 'Imagem 2 do Lavatório', 'Imagem 3 do Lavatório'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'MESA GAVETEIRO': {
    images: ['/images/image/Design/Mobiliario/MesaGav/3.png', '/images/image/Design/Mobiliario/MesaGav/2.png', '/images/image/Design/Mobiliario/MesaGav/1.png'],
    descriptions: ['Imagem 1 da Mesa Gaveteiro', 'Imagem 2 da Mesa Gaveteiro', 'Imagem 3 da Mesa Gaveteiro'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'MESA LIXEIRA': {
    images: ['/images/image/Design/Mobiliario/MesaLixo/3.png', '/images/image/Design/Mobiliario/MesaLixo/2.png', '/images/image/Design/Mobiliario/MesaLixo/1.png'],
    descriptions: ['Imagem 1 da Mesa Lixeira', 'Imagem 2 da Mesa Lixeira', 'Imagem 3 da Mesa Lixeira'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'PRATELEIRA DE PAREDE': {
    images: ['/images/image/Design/Mobiliario/PrateleiraP/3.png', '/images/image/Design/Mobiliario/PrateleiraP/2.png', '/images/image/Design/Mobiliario/PrateleiraP/1.png'],
    descriptions: ['Imagem 1 da Prateleira de Parede', 'Imagem 2 da Prateleira de Parede', 'Imagem 3 da Prateleira de Parede'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'CAIXA DE GORDURA': {
    images: ['/images/image/Design/Mobiliario/Acessorios/CaixaGordura/3.png', '/images/image/Design/Mobiliario/Acessorios/CaixaGordura/2.png', '/images/image/Design/Mobiliario/Acessorios/CaixaGordura/1.png'],
    descriptions: ['Imagem 1 da Caixa de Gordura', 'Imagem 2 da Caixa de Gordura', 'Imagem 3 da Caixa de Gordura'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
  'GRELHA DE PISO': {
    images: ['/images/image/Design/Mobiliario/Acessorios/GrelhaPiso/3.png', '/images/image/Design/Mobiliario/Acessorios/GrelhaPiso/1.png', '/images/image/Design/Mobiliario/Acessorios/GrelhaPiso/2.png'],
    descriptions: ['Imagem 1 da Grelha de Piso', 'Imagem 2 da Grelha de Piso', 'Imagem 3 da Grelha de Piso'],
    titles: ['Título 1', 'Título 2', 'Título 3'],
  },
};


const Design = () => {
  const { productName } = useParams();
  const [isDesignVisible, setIsDesignVisible] = useState(false);
  const [focusedImage, setFocusedImage] = useState(1); // Começar na imagem do meio
  const [hoveredImage, setHoveredImage] = useState(null);
  const [isDesignExited, setIsDesignExited] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const intervalRef = useRef(null);
  const [direction, setDirection] = useState(0); // Adicionando direção
  const [topPosition, setTopPosition] = useState(window.innerWidth <= 768 ? '-15%' : '100%');

  const content = productDetails[productName] || { images: [], descriptions: [] };

  const startCarousel = () => {
    if (!isMobile) {
      stopCarousel();
      intervalRef.current = setInterval(() => {
        setDirection(1);
        setFocusedImage((prevIndex) => (prevIndex + 1) % content.images.length);
      }, 3000);
    }
  };

  const stopCarousel = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setDirection(1);
      setFocusedImage((prev) => (prev + 1) % content.images.length);
      if (!isMobile) startCarousel();
    },
    onSwipedRight: () => {
      setDirection(-1);
      setFocusedImage((prev) => (prev - 1 + content.images.length) % content.images.length);
      if (!isMobile) startCarousel();
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  useEffect(() => {
    if (isDesignVisible && !isMobile) {
      startCarousel();
    } else {
      stopCarousel();
    }

    return () => {
      stopCarousel();
    };
  }, [isDesignVisible, isMobile, content.images.length]);

  const Dots = () => (
    <DotsContainer>
      {content.images.map((_, index) => (
        <Dot key={index} isActive={focusedImage === index} />
      ))}
    </DotsContainer>
  );

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    }),
  };

  useEffect(() => {
    const designSection = document.getElementById('design-section');

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsDesignVisible(true);
          setIsDesignExited(false);
        } else {
          setIsDesignExited(true);
        }
      },
      {
        threshold: 0.2,
      }
    );

    if (designSection) {
      observer.observe(designSection);
    }

    return () => {
      if (designSection) observer.unobserve(designSection);
    };
  }, []);

  useEffect(() => {
    if (isDesignVisible) {
      startCarousel();
    } else {
      stopCarousel();
    }

    return () => {
      stopCarousel();
    };
  }, [isDesignVisible, content.images.length]);

  const getAnimationForImage = (index) => {
    if (isDesignExited) {
      if (index === 0) {
        return { initial: {}, animate: { opacity: 0, x: -100 } };
      } else if (index === 1) {
        return { initial: {}, animate: { opacity: 0, scale: 0.8 } };
      } else if (index === 2) {
        return { initial: {}, animate: { opacity: 0, x: 100 } };
      }
    } else {
      if (index === 0) {
        return { initial: { opacity: 0, x: -100 }, animate: { opacity: 1, x: 0 } };
      } else if (index === 1) {
        return { initial: { opacity: 0, scale: 0.8 }, animate: { opacity: 1, scale: 1 } };
      } else if (index === 2) {
        return { initial: { opacity: 0, x: 100 }, animate: { opacity: 1, x: 0 } };
      }
    }
    return { initial: {}, animate: {} };
  };

  const handleMouseEnter = (index) => {
    stopCarousel(); // Pausa o carrossel
    setFocusedImage(index); // Foca na imagem atual
    setHoveredImage(index); // Indica que a imagem está sendo focada
  };

  const handleMouseLeave = () => {
    setHoveredImage(null); // Remove o foco da imagem
    if (!intervalRef.current) {
      startCarousel(); // Reinicia o carrossel se não estiver ativo
    }
  };

  return (
  <SectionContainer {...handlers} isMobile={isMobile} id="design-section">
    {isDesignVisible && (
      <ImagesGrid>
        {isMobile || window.innerWidth <= 1118 ? (
          // Renderização para resoluções menores: exibe o carrossel com animação de entrada e saída lateral
          <>
          <CarouselContainer>
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={focusedImage}
                variants={{
                  enter: (direction) => ({
                    x: direction > 0 ? 300 : -300,
                    opacity: 0,
                  }),
                  center: { x: 0, opacity: 1 },
                  exit: (direction) => ({
                    x: direction < 0 ? 300 : -300,
                    opacity: 0,
                  }),
                }}
                initial="enter"
                animate="center"
                exit="exit"
                custom={direction}
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.5 },
                }}
                style={{
                  position: 'absolute',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ImageContainer isFocused>
                  {/* Título */}
                  <motion.h2
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      marginBottom: 20,
                      fontSize: '5vw',
                      textShadow: '0 0 5px #354A81, 0 0 10px #354A81, 0 0 1px #354A81',
                    }}
                  >
                    {content.titles[focusedImage]}
                  </motion.h2>

                  {/* Ícone */}
                  <motion.div
                    style={{
                      position: 'absolute',
                      top: window.innerWidth <= 768 ? '-15%' : '-10%',
                      left: '50%',
                      width: '60px',
                      height: '60px',
                      border: '2px solid #fff',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '30px',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {focusedImage === 0 && <FaMedal />}
                    {focusedImage === 1 && <GiBreakingChain />}
                    {focusedImage === 2 && <FaLightbulb />}
                  </motion.div>

                  {/* Imagem com animação de entrada e saída */}
                  <motion.img
                    src={content.images[focusedImage]}
                    alt={`Imagem ${focusedImage + 1}`}
                    style={{
                      borderRadius: '15px',
                      objectFit: 'cover',
                      width: '80%',
                    }}
                  />

                  {/* Descrição com animação de entrada e saída */}
                  <motion.div
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      marginTop: 20,
                      fontFamily: 'Brmalls',
                      fontSize: '4.5vw',
                      maxWidth: '80%',
                      lineHeight: 1.2,
                      zIndex: 5000,
                      position: 'relative',
                    }}
                  >
                    {content.descriptions[focusedImage]}
                  </motion.div>
                  
                </ImageContainer>
                
              </motion.div>
              
            </AnimatePresence>

          
           
          </CarouselContainer>
          <Dots />
          </>
         
        ) : (
            // Renderização para resoluções maiores (com múltiplas imagens)
            content.images.map((image, index) => (
              <ImageWrapper
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                isFocused={hoveredImage === index || focusedImage === index}
              >
                <ImageContainer
                  isFocused={hoveredImage === index || focusedImage === index}
                  isBlurred={hoveredImage !== null ? hoveredImage !== index : focusedImage !== index}
                  productName={productName}
                >
                  <motion.h2
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      marginBottom: 20,
                      fontSize: 40,
                      textShadow: '0 0 5px #354A81, 0 0 10px #354A81, 0 0 1px #354A81',
                    }}
                    initial={{ opacity: 0 }}
                    animate={isDesignVisible && focusedImage === index ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 2 }}
                  >
                    {content.titles[index]}
                  </motion.h2>
  
                  <motion.div
                    style={{
                      position: 'absolute',
                      top: -30,
                      right: '45%',
                      width: '60px',
                      height: '60px',
                      border: '2px solid #fff',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '30px',
                      color: '#fff',
                      backgroundColor: 'transparent',
                    }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={isDesignVisible && focusedImage === index ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }}
                    transition={{ duration: 1 }}
                  >
                    {focusedImage === index && index === 0 && <FaMedal />}
                    {focusedImage === index && index === 1 && <GiBreakingChain />}
                    {focusedImage === index && index === 2 && <FaLightbulb />}
                  </motion.div>
  
                  <motion.img
                    src={image}
                    alt={`Imagem ${index + 1}`}
                    initial={getAnimationForImage(index).initial}
                    animate={isDesignVisible ? getAnimationForImage(index).animate : {}}
                    transition={{ duration: 1 }}
                  />
  
                  <motion.div
                    style={{ color: 'white', textAlign: 'center', marginTop: 50, fontFamily: 'Brmalls', fontSize: 22 }}
                    initial={{ opacity: 0 }}
                    animate={isDesignVisible && focusedImage === index ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 1, delay: 0.4 }}
                  >
                    {content.descriptions[index]}
                  </motion.div>
                  
                </ImageContainer>
                
              </ImageWrapper>
              
            ))
          )}
        </ImagesGrid>
      )}
    </SectionContainer>
  );
  
};
export default Design;

// Styled Components

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* Ocupa a altura total da viewport */
  background: #000;
  overflow: hidden;
  position: relative;
  perspective: 1000px;

  @media (max-width: 768px) {
    height: 120%; /* Ajusta para a altura do conteúdo em mobile */
    padding: 0;
    padding-top: 10px;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
`;

const ImagesGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 70vh;
  margin-top: 5%;
  gap: 130px;

  @media (max-width: 1118px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.7s ease;
  z-index: 2;
  margin-bottom: 120px;

  ${({ isFocused }) => isFocused && `transform: scale(1.2);`}
  ${({ isBlurred }) => isBlurred && `filter: blur(7px);`}

  img {
    width: ${({ productName }) => productName === 'BALCÃO PORTO VERTICAL' ? '120%' : '100%'};
    max-width: 100%;
    height: auto;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 75vw; /* Ajuste para dispositivos móveis */
      
    }
  }

 h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5vw;
  font-weight: 600; /* Aumenta o peso da fonte para melhorar a nitidez */
  text-shadow: 0 0 2px #354A81; /* Reduzindo o desfoque do text-shadow */

  @media (max-width: 768px) {
    font-size: 5vw;
    text-shadow: none; /* Remove o text-shadow em dispositivos móveis */
  }
}

.description {
  color: white;
  text-align: center;
  margin-top: 20px;
  font-family: 'Brmalls';
  font-size: clamp(1rem, 1.5vw, 2rem);
  font-weight: 400; /* Define o peso para deixar o texto nítido */
  z-index: 6000;
  position: relative;
  filter: none; /* Remove qualquer desfoque */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @media (max-width: 768px) {
    font-size: clamp(1rem, 2vw, 1.8rem);
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
}

`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${({ isActive }) => (isActive ? '#fff' : '#555')};
  transition: background-color 0.3s;
`;

