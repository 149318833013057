/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Lavatorio(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Mobiliario/Lavatorio/LAVATORIO.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.5, 0.15, -5]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0004.geometry}
          material={materials['color-1']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0004_1.geometry}
          material={materials['satin finish nickel']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Mobiliario/Lavatorio/LAVATORIO.glb')