import React, { useRef, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { Box3, Vector3, Color } from 'three';

export function BalcaoVt(props) { // Adicione props aqui
  const group = useRef();
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Balcao/boxvt/balc%C3%A3o_vertical.glb');

  // Variável para controlar a posição Y
  const positionY = -0.71;

  useEffect(() => {
    const box = new Box3().setFromObject(group.current); // Calcula as dimensões do grupo
    const center = new Vector3();
    box.getCenter(center); // Pega o centro do modelo

    // Centraliza apenas no eixo X e Z, deixando Y livre para manipulação
    group.current.position.set(-center.x, positionY, -center.z);
  }, [positionY]);

  // Atualizando a cor dos materiais 'pintar' e 'pintar 2' com o color recebido
  useEffect(() => {
    if (materials['pintar']) {
      materials['pintar'].color = new Color(props.color);
    }
    if (materials['pintar 2']) {
      materials['pintar 2'].color = new Color(props.color);
    }
  }, [props.color, materials]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012.geometry}
          material={materials['color-3']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_1.geometry}
          material={materials['color-5.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_2.geometry}
          material={materials.pintar}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_3.geometry}
          material={materials['pw-mt11040-4.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_4.geometry}
          material={materials['color-9.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_5.geometry}
          material={materials['pintar 2']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_6.geometry}
          material={materials['satin finish nickel.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_7.geometry}
          material={materials['amber led.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_8.geometry}
          material={materials['clear glass.006']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_9.geometry}
          material={materials['color-6.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_10.geometry}
          material={materials['color-7.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_11.geometry}
          material={materials['color-10']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0012_12.geometry}
          material={materials['color-2']}
        />
      </group>
    </group>
  );
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Balcao/boxvt/balc%C3%A3o_vertical.glb');
