import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { useParams } from 'react-router-dom';

const productDetails = {
  'BALCÃO PORTO HORIZONTAL': {
    title: 'Balcão Porto Horizontal',
    aboutText: 'Balcão projetado para oferecer excelente capacidade de armazenamento e refrigeração, com uma estrutura robusta que suporta tampos para acomodar massas, pias e outros equipamentos, proporcionando versatilidade e eficiência.',
    description: 'Descrição',
    descriptionText: 'Balcão com área interna ampla, permitindo a instalação de prateleiras internas, iluminação contínua e ponto de drenagem na caixa de manutenção. O tampo pode ser finalizado em aço inoxidável ou granito, proporcionando sofisticação e durabilidade ao ambiente.',
    videoAboutSrc: 'https://storage.googleapis.com/videos-aer/Balcao/boxhz/boxhzsobre.mp4',
    videoMobileSrc: 'https://storage.googleapis.com/videos-aer/Balcao/boxhz/boxdesignmobile.mp4',
    imageDescriptionSrc: '/images/image/NextSection/Balcao/boxhz/1.png',
    extraText: 'Design',
    extraImageSrc: '/images/image/NextSection/Balcao/boxhz/2.png',
    extraText2: 'Balcão elegante com portas de vidro que proporcionam visibilidade interna e fácil acesso aos itens. Fabricado em aço inoxidável 304, oferece alta durabilidade, resistência à corrosão e fácil manutenção, unindo funcionalidade e sofisticação em um design moderno.'
  },
  'BALCÃO PORTO VERTICAL': {
    title: 'Balcão Porto Vertical',
    aboutText: 'Balcão projetado para oferecer excelente capacidade de armazenamento e refrigeração, com uma estrutura robusta e resistente para suportar trabalho pesado, garantindo versatilidade e eficiência no ambiente.',
    description: 'Descrição',
    descriptionText: 'Balcão com área interna ampla, permitindo a instalação de braços e prateleiras internas, iluminação contínua.',
    videoAboutSrc: 'https://storage.googleapis.com/videos-aer/Balcao/boxvt/boxvtsobre.mp4',
    videoMobileSrc: 'https://storage.googleapis.com/videos-aer/Balcao/boxvt/boxmobiledesign.mp4', // Novo vídeo para mobile
    imageDescriptionSrc: '/images/image/NextSection/Balcao/boxvt/1.png',
    extraText: 'Design',
    extraImageSrc: '/images/image/NextSection/Balcao/boxvt/2.png',
    extraText2: 'Balcão elegante com portas de vidro que proporcionam visibilidade interna e fácil acesso aos itens. Fabricado em aço inoxidável 304, oferece alta durabilidade, resistência à corrosão e fácil manutenção, unindo funcionalidade e sofisticação em um design moderno.'
  },
  'DEPOSITO': {
    title: 'Depósito Refrigerado',
    aboutText: 'O depósito refrigerado é a escolha perfeita para armazenar grandes volumes de mercadorias.',
    description: 'Descrição do Depósito',
    descriptionText: 'Com temperatura controlada e espaço modular, o depósito é versátil e eficiente.',
    videoAboutSrc: null,
    imageDescriptionSrc: '/images/image/Estantes/Deposito/depositosbr.png',
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Estantes/Deposito/deposito.png',
    extraText: 'Texto adicional sobre o Depósito.',
    extraImageSrc: '/images/image/Estantes/Deposito/depositodesc.png',
    extraText2: 'Aqui está um texto adicional para o Depósito Refrigerado.'
  },
  'CREMALHEIRAS': {
    title: 'Cremalheira',
    aboutText: 'A cremalheira é ideal para armazenamento de cargas pesadas.',
    description: 'Cremalheira Descrição',
    descriptionText: 'Alta resistência e durabilidade.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Estantes/Cremalheiras/cremalheira.png',
    extraText: 'Texto adicional sobre a Cremalheira.',
    extraImageSrc: '/images/image/Estantes/Cremalheira/cremalheiradesc.png',
    extraText2: 'Aqui está um texto adicional para a Cremalheira.'
  },
  'ESTOCAGEM': {
    title: 'Estocagem',
    aboutText: 'Soluções inteligentes para estocagem eficiente.',
    description: 'Estocagem Descrição',
    descriptionText: 'Estocagem de alta capacidade.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Estantes/Estocagem/estocagem.png',
    extraText: 'Texto adicional sobre Estocagem.',
    extraImageSrc: '/images/image/Estantes/Estocagem/estocagemdesc.png',
    extraText2: 'Aqui está um texto adicional para Estocagem.'
  },
  'HIGIENIZAÇÃO': {
    title: 'Higienização',
    aboutText: 'Ferramentas de higienização eficientes.',
    description: 'Higienização Descrição',
    descriptionText: 'Higienização para diferentes superfícies.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Estantes/Higienizacao/higienizacao.png',
    extraText: 'Texto adicional sobre Higienização.',
    extraImageSrc: '/images/image/Estantes/Higienizacao/higienizacaodesc.png',
    extraText2: 'Aqui está um texto adicional para Higienização.'
  },
  'VITRINE PORTO GALICIA': {
    title: 'Vitrine Porto Galicia',
    aboutText: 'Vitrine alta, cuidadosamente projetada para maximizar a capacidade de exposição de produtos e aprimorar sua visibilidade.',
    description: 'Descrição',
    descriptionText: 'Vitrine com design inovador de vidro infinito, sem emendas frontais, proporcionando um visual contínuo e elegante. As partes internas são móveis para facilitar a limpeza, e as prateleiras podem ser removidas, garantindo praticidade e uma manutenção simplificada.',
    videoAboutSrc: 'https://storage.googleapis.com/videos-aer/Vitrines/PG/vitrinepgdesc8k.mp4',
    videoMobileSrc: 'https://storage.googleapis.com/videos-aer/Vitrines/PG/galiciadesign.mp4',
    imageDescriptionSrc: '/images/image/NextSection/Vitrines/PG/sobre.png',
    pngImageSrc: '/images/image/Vitrine/galiciadesign.png',
    extraText: 'Armazenamento',
    extraImageSrc: '/images/image/NextSection/Vitrines/PG/descricao.png',
    extraText2: 'Vitrine equipada com três gavetas versáteis para armazenamento, cada uma com capacidade de 30 litros, permitindo acomodar diversos itens conforme a necessidade. Conta com tecnologia de porta de correr slow motion, proporcionando um fechamento suave e silencioso, e gavetas também equipadas com sistema slow motion, oferecendo uma experiência de uso ainda mais prática e sofisticada.'
  },
  'VITRINE PORTO PARIS': {
    title: 'Vitrine Porto Paris',
    aboutText: 'Qualidade excepcional e durabilidade incomparável projetados para valorizar sua loja.',
    description: 'Descrição da Vitrine Porto Paris',
    descriptionText: 'Design refinado que maximiza a visibilidade dos produtos.',
    videoAboutSrc: 'https://storage.googleapis.com/videos-aer/Vitrines/PP/vitrinedesc.mp4',
    videoMobileSrc: 'https://storage.googleapis.com/videos-aer/Vitrines/PP/vitrineppdesignmobile.mp4',
    imageDescriptionSrc: '/images/image/NextSection/Vitrines/PP/1.png',
    pngImageSrc: '/images/image/NextSection/Vitrines/PP/1.png',
    extraText: 'Estrutura da vitrine',
    extraImageSrc: '/images/image/NextSection/Vitrines/PP/2.png',
    extraText2: 'Aqui está um texto adicional para a Vitrine Porto Paris.'
  },
  'EXPOSITOR PORTO VISEU': {
    title: 'Expositor Porto Viseu',
    aboutText: 'Expositor cuidadosamente projetado para maximizar a capacidade de exposição de produtos e aprimorar sua visibilidade.',
    description: 'Descrição',
    descriptionText: 'Expositor com design inovador de vidro infinito, sem frontão aparente, proporcionando um visual contínuo e elegante. As partes internas são móveis para facilitar a limpeza, e as prateleiras podem ser removidas, garantindo praticidade e uma manutenção simplificada.',
    videoAboutSrc: 'https://storage.googleapis.com/videos-aer/Expositor/expositor4ksobre.115.mp4',
    videoMobileSrc: 'https://storage.googleapis.com/videos-aer/Expositor/expositordesignmobile.mp4',
    imageDescriptionSrc: '/images/image/NextSection/Expositor/Viseu/sobre.png',
    pngImageSrc: '/images/image/Expositor/viseudesign.png',
    extraText: 'Design',
    extraImageSrc: '/images/image/NextSection/Expositor/Viseu/descricao.png',
    extraText2: 'Expositor refrigerado sem batente interno, proporcionando máxima visibilidade dos produtos ao ser aberto. Com base em vidro temperado, oferece um toque de elegância e sofisticação ao ambiente.'
  },
  'MESA': {
    title: 'Mesa',
    aboutText: 'Mesa resistente e durável, ideal para diversos ambientes comerciais.',
    description: 'Mesa Descrição',
    descriptionText: 'Projetada para suportar alto uso com estilo e praticidade.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa/MESA%20-%20FRAME%20DESCRI%C3%87%C3%83O.130.png',
    extraText: 'Texto adicional sobre a Mesa.',
    extraImageSrc: '/images/image/Mobiliario/mesadesc.png',
    extraText2: 'Aqui está um texto adicional para a Mesa.'
  },
  'BUFFET': {
    title: 'Buffet',
    aboutText: 'Buffet versátil e espaçoso para organização de alimentos e utensílios.',
    description: 'Buffet Descrição',
    descriptionText: 'Ideal para eventos e áreas de alimentação.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Buffet/BUFFET%20-%20FRAME%20DESCRI%C3%87%C3%83O.128.png',
    extraText: 'Texto adicional sobre o Buffet.',
    extraImageSrc: '/images/image/Mobiliario/buffetdesc.png',
    extraText2: 'Aqui está um texto adicional para o Buffet.'
  },
  'MESA COM PIA': {
    title: 'Mesa com Pia',
    aboutText: 'Mesa com pia integrada para facilitar a higienização em áreas de preparo.',
    description: 'Mesa com Pia Descrição',
    descriptionText: 'Prática para operações que demandam limpeza constante.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa%20com%20pia/MESA%20PIA%20-%20FRAME%20DESCRI%C3%87%C3%83O.131.png',
    extraText: 'Texto adicional sobre a Mesa com Pia.',
    extraImageSrc: '/images/image/Mobiliario/mesacompia_desc.png',
    extraText2: 'Aqui está um texto adicional para a Mesa com Pia.'
  },
  'LAVADORA': {
    title: 'Lavadora',
    aboutText: 'Lavadora industrial de alta eficiência para cozinhas comerciais.',
    description: 'Lavadora Descrição',
    descriptionText: 'Facilita a limpeza rápida e eficiente de utensílios.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Lavadora/LAVADORA%20-%20FRAME%20DESCRI%C3%87%C3%83O.129.png',
    extraText: 'Texto adicional sobre a Lavadora.',
    extraImageSrc: '/images/image/Mobiliario/lavadoradesc.png',
    extraText2: 'Aqui está um texto adicional para a Lavadora.'
  },
  'LAVATORIO': {
    title: 'Lavatório',
    aboutText: 'Lavatório prático para higienização de mãos em ambientes de preparo.',
    description: 'Lavatório Descrição',
    descriptionText: 'Ideal para garantir a higiene pessoal em áreas comerciais.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Lavat%C3%B3rio/lavatorio%20-%20FRAME%20DESCRI%C3%87%C3%83O.135.png',
    extraText: 'Texto adicional sobre o Lavatório.',
    extraImageSrc: '/images/image/Mobiliario/lavatoriodesc.png',
    extraText2: 'Aqui está um texto adicional para o Lavatório.'
  },
  'MESA GAVETEIRO': {
    title: 'Mesa Gaveteiro',
    aboutText: 'Mesa com gaveteiro integrado para melhor organização de utensílios.',
    description: 'Mesa Gaveteiro Descrição',
    descriptionText: 'Facilita o armazenamento de itens menores.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa%20Gaveteiro/MESA%20GAVETA%20-%20FRAME%20DESCRI%C3%87%C3%83O.132.png',
    extraText: 'Texto adicional sobre a Mesa Gaveteiro.',
    extraImageSrc: '/images/image/Mobiliario/mesagaveteirodesc.png',
    extraText2: 'Aqui está um texto adicional para a Mesa Gaveteiro.'
  },
  'MESA LIXEIRA': {
    title: 'Mesa Lixeira',
    aboutText: 'Mesa com compartimento para descarte de resíduos.',
    description: 'Mesa Lixeira Descrição',
    descriptionText: 'Ideal para ambientes que requerem facilidade no descarte.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Mesa%20lixeira/MESA%20LIXEIRA%20-%20FRAME%20DESCRI%C3%87%C3%83O.133.png',
    extraText: 'Texto adicional sobre a Mesa Lixeira.',
    extraImageSrc: '/images/image/Mobiliario/mesalixeiradesc.png',
    extraText2: 'Aqui está um texto adicional para a Mesa Lixeira.'
  },
  'PRATELEIRA DE PAREDE': {
    title: 'Prateleira de Parede',
    aboutText: 'Prateleira para armazenamento otimizado em paredes.',
    description: 'Prateleira de Parede Descrição',
    descriptionText: 'Perfeita para otimizar o espaço em cozinhas e áreas de armazenamento.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Prateleira%20de%20parede/prat%20parede%20-%20FRAME%20DESCRI%C3%87%C3%83O.136.png',
    extraText: 'Texto adicional sobre a Prateleira de Parede.',
    extraImageSrc: '/images/image/Mobiliario/prateleiradesc.png',
    extraText2: 'Aqui está um texto adicional para a Prateleira de Parede.'
  },
  'CAIXA DE GORDURA': {
    title: 'Caixa de Gordura',
    aboutText: 'Equipamento para retenção de gorduras em sistemas de esgoto.',
    description: 'Caixa de Gordura Descrição',
    descriptionText: 'Indispensável para locais que trabalham com alimentos e óleos.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Acessorios/Caixa%20de%20gordura/CAIXA%20GORDURA%20-%20FRAME%20DESCRI%C3%87%C3%83O.134.png',
    extraText: 'Texto adicional sobre a Caixa de Gordura.',
    extraImageSrc: '/images/image/Acessorios/caixagorduradesc.png',
    extraText2: 'Aqui está um texto adicional para a Caixa de Gordura.'
  },
  'GRELHA DE PISO': {
    title: 'Grelha de Piso',
    aboutText: 'Grelha para escoamento de água em áreas molhadas.',
    description: 'Grelha de Piso Descrição',
    descriptionText: 'Ideal para garantir o escoamento eficiente em cozinhas e áreas de serviço.',
    videoAboutSrc: null,
    pngImageSrc: 'https://storage.googleapis.com/videos-aer/Mobiliario/Acessorios/Grelha%20de%20piso/grelha%20-%20FRAME%20DESCRI%C3%87%C3%83O.137.png',
    extraText: 'Texto adicional sobre a Grelha de Piso.',
    extraImageSrc: '/images/image/Acessorios/grelhapisodesc.png',
    extraText2: 'Aqui está um texto adicional para a Grelha de Piso.'
  }
};


const NextSection = () => {
  const { productName } = useParams();
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [showTextAbout, setShowTextAbout] = useState(false);
  const [showTextDescription, setShowTextDescription] = useState(false);
  const [showImageAnimation, setShowImageAnimation] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isExtraSectionVisible, setIsExtraSectionVisible] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [hasAnimatedTextAbout, setHasAnimatedTextAbout] = useState(false);
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const extraSectionRef = useRef(null);
  const aboutVideoRef = useRef(null);
  const descriptionSectionRef = useRef(null);

  const normalizeProductName = (name) =>
    typeof name === 'string' ? name.trim().toLowerCase() : '';

  const normalizedProductName = normalizeProductName(productName);
  const productKey = Object.keys(productDetails).find(
    (key) => normalizeProductName(key) === normalizedProductName
  );
  const productData = productKey ? productDetails[productKey] : null;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Considere mobile se largura <= 768px
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Resetar todos os estados para refletir a troca de produto
    setIsAboutVisible(false);
    setIsDescriptionVisible(false);
    setShowTextAbout(false);
    setHasPlayed(false);
    setIsExtraSectionVisible(false);
    setIsImageVisible(false);
    setHasAnimatedTextAbout(false);
  }, [productName]);

  useEffect(() => {
    if (aboutVideoRef.current && isAboutVisible && !hasPlayed) {
      aboutVideoRef.current.playbackRate = 1.5;
    }
  }, [isAboutVisible, hasPlayed]);


  // Controlar a exibição do vídeo da seção "Sobre" uma única vez
  useEffect(() => {
    const aboutSection = document.querySelector('#about-section');
    const aboutObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.id === 'about-section') {
            if (productData.videoAboutSrc && !hasPlayed) {
              setIsAboutVisible(true);
              aboutVideoRef.current.play();
              setHasPlayed(true); // Marca como executado para que não se repita
            } else if (!productData.videoAboutSrc && !hasAnimatedTextAbout) {
              setIsImageVisible(true);
              setShowTextAbout(true);
              setHasAnimatedTextAbout(true); // Marca como executado para que não se repita
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (aboutSection) aboutObserver.observe(aboutSection);
    return () => {
      if (aboutSection) aboutObserver.unobserve(aboutSection);
    };
  }, [hasPlayed, productData?.videoAboutSrc, hasAnimatedTextAbout, productName]);

  useEffect(() => {
    // Observador para a seção "Description"
    const descriptionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsDescriptionVisible(true);
            descriptionObserver.disconnect(); // Desconecta após a primeira exibição
          }
        });
      },
      { threshold: 0.5 }
    );

    if (descriptionSectionRef.current) {
      descriptionObserver.observe(descriptionSectionRef.current);
    }

    return () => {
      descriptionObserver.disconnect();
    };
  }, [productName]);

  useEffect(() => {
    // Observador para a seção "Extra"
    const extraObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsExtraSectionVisible(true);
            extraObserver.disconnect(); // Desconecta após a primeira execução
          }
        });
      },
      { threshold: 0.5 }
    );

    if (extraSectionRef.current) {
      extraObserver.observe(extraSectionRef.current);
    }
    return () => {
      extraObserver.disconnect();
    };
  }, [productName]);


  useEffect(() => {
    // Resetar todos os estados para refletir a troca de produto
    setIsAboutVisible(false);
    setIsDescriptionVisible(false);
    setShowTextAbout(false);
    setShowTextDescription(false);
    setShowImageAnimation(false);
    setHasPlayed(false);
    setIsExtraSectionVisible(false);
    setIsImageVisible(false);
    setHasAnimatedTextAbout(false);
    setIsHighResLoaded(false);
  }, [productName]);

  // Controla o texto para aparecer faltando 1 segundo no vídeo "Sobre"
  const handleVideoAboutTimeUpdate = () => {
    const aboutVideo = aboutVideoRef.current;
    if (aboutVideo && aboutVideo.duration - aboutVideo.currentTime <= 1) {
      setShowTextAbout(true);
    }
  };

  if (!productData) return <h2>Produto não encontrado</h2>;


  return (
    <>
      <Element name="about-section" id="about-section">
        <SectionRight>
          {isMobile && productData.videoMobileSrc ? (
            // Vídeo para dispositivos móveis
            <VideoBackground
              key={`video-mobile-${productName}`} 
              ref={aboutVideoRef}
              autoPlay={true}
              muted
              loop={false}
              onTimeUpdate={handleVideoAboutTimeUpdate}
              isMobile={isMobile} // Passando a propriedade
            >
              <source src={productData.videoMobileSrc} type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </VideoBackground>
          ) : productData.videoAboutSrc ? (
            // Vídeo para desktop
            <VideoBackground
              key={`video-${productName}`}
              ref={aboutVideoRef}
              autoPlay={true}
              muted
              loop={false}
              onTimeUpdate={handleVideoAboutTimeUpdate}
              style={{ opacity: 1, transition: 'none' }}
            >
              <source src={productData.videoAboutSrc} type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </VideoBackground>
          ) : (
            // Imagem alternativa se não houver vídeo
            <motion.img
              src={productData.pngImageSrc}
              alt="Imagem sem vídeo"
              loading="lazy"
              initial={{ opacity: 0 }}
              animate={isImageVisible ? { opacity: 1 } : {}}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          )}

          <ContentRight
            isSpecialProduct={
              productData?.title === 'Expositor Porto Viseu' ||
              productData?.title === 'Balcão Porto Vertical'
            }
          >
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={showTextAbout ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 1, delay: isImageVisible ? 0.3 : 0 }}
            >
              <SubTitle>Sobre</SubTitle>
              <Title>{productData.title}</Title>
              <MotionUnderline
                initial={{ width: 0 }}
                animate={showTextAbout ? { width: '100px' } : {}}
                transition={{ duration: 1, ease: 'easeInOut' }}
              />
              <Text>{productData.aboutText}</Text>
            </motion.div>
          </ContentRight>


        </SectionRight>
      </Element>



      {productData.videoAboutSrc && (
        <>
          <Element name="description-section" id="description-section">
            <SectionDescription ref={descriptionSectionRef}>
              <DescriptionContentRow>
                <motion.div
                  initial={{ opacity: 0, x: -30, color: '#555' }}
                  animate={isDescriptionVisible ? { opacity: 1, x: 0, color: '#CBCACA' } : {}}
                  transition={{ duration: 1, ease: 'easeInOut' }}
                  style={{
                    flex: 1,
                    maxWidth: '50%',
                    textAlign: 'center',
                    fontSize: '18px',
                    lineHeight: '1.5',
                    padding: '20px',
                    margin: '0 auto',
                    ...(window.innerWidth <= 768 && {
                      fontSize: '14px', // Tamanho reduzido para dispositivos móveis
                      maxWidth: '90%', // Ajusta a largura máxima para centralizar
                      padding: '10px 15px', // Padding para ajuste nas bordas
                    }),
                    ...(window.innerWidth <= 480 && {
                      fontSize: '12px', // Fonte ainda menor para dispositivos muito pequenos
                      lineHeight: '1.4', // Linha mais compacta para encaixar melhor o texto
                      maxWidth: '95%',
                    })
                  }}
                >

                  <SubTitle1>{productData.description}</SubTitle1>
                  <MotionUnderline1
                    initial={{ width: 0 }}
                    animate={isDescriptionVisible ? { width: '100px' } : {}}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                  />
                  <Text1>{productData.descriptionText}</Text1>
                </motion.div>



                <motion.img
                  src={productData.imageDescriptionSrc}
                  alt={`Imagem de ${productData.title}`}
                  loading="lazy"
                  initial={{ opacity: 0 }}
                  animate={isDescriptionVisible ? { opacity: 1 } : {}}
                  transition={{ duration: 1, ease: 'easeInOut' }}
                  style={{
                    textAlign: 'center',
                    color: '#fff',
                    padding: '20px',
                    margin: '0 auto',
                    maxWidth: '900px',
                    width: '100%',
                    ...(window.innerWidth <= 768 && {
                      maxWidth: '90%',       // Ajusta a largura para dispositivos móveis
                      padding: '0',
                      margin: '20px auto',   // Centraliza a imagem na tela
                      display: 'block',
                    }),
                  }}
                />



              </DescriptionContentRow>
            </SectionDescription>
          </Element>

          <Element name="extra-section" id="extra-section">
            <SectionNew ref={extraSectionRef}>
              <ContentRow>
                <ContentCenter>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={isExtraSectionVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1 }}
                  >

                    <SubTitle2>{productData.extraText}</SubTitle2>
                    <MotionUnderline2
                      initial={{ width: 0 }}
                      animate={isExtraSectionVisible ? { width: '100px' } : {}}
                      transition={{ duration: 1, ease: 'easeInOut' }}
                    />
                    <Text2>{productData.extraText2}</Text2>
                  </motion.div>
                </ContentCenter>

                <StyledExtraImage
                  src={productData.extraImageSrc}
                  alt={`Imagem adicional de ${productData.title}`}
                  initial={{ opacity: 0, filter: 'blur(30px)' }}
                  animate={isExtraSectionVisible ? { opacity: 1, filter: 'blur(0px)' } : {}}
                  transition={{ duration: 1, ease: 'easeInOut' }}
                />
              </ContentRow>


            </SectionNew>
          </Element>
        </>
      )}
    </>
  );
}

// Styled Components
const SectionRight = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 110vh;
  position: relative;
  padding: 0 120px;
  background-color: black;
  z-index: 0;
  margin-bottom: 0;
  
`;

const ContentRight = styled.div`
  width: 55%;
  padding-left: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end; // Alinha o conteúdo à direita em telas grandes
  text-align: right; // Garante que o texto seja alinhado à direita
  z-index: 3;

  @media (max-width: 768px) {
    width: 100%; // Usa toda a largura em dispositivos móveis
    padding-left: 0;
    align-items: center; // Centraliza o conteúdo em dispositivos móveis
    text-align: center; // Centraliza o texto em dispositivos móveis
    margin-bottom: ${(props) =>
    props.isSpecialProduct ? '75vh' : '50vh'}; // Aplica a margem conforme o produto
  }
`;




const SubTitle = styled.h3`
  font-family: 'Brmalls';
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
  letter-spacing: 4px;
  margin-bottom: 8px;
  text-align: center; // Alinhamento à direita para garantir a consistência
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  transition: text-shadow 0.5s ease-in-out;

  &:hover {
    text-shadow: 0 0 25px rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 768px) {
    font-size: 12px;
    text-align: center; // Centraliza o texto em dispositivos móveis
  }

  @media (max-width: 480px) {
    font-size: 12px;
    text-align: center; /
  }
`;


const SubTitle1 = styled.h3`
  font-family: 'Brmalls';
   font-size: 40px;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
  letter-spacing: 4px;
  margin-bottom: 8px;
  position: relative;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  transition: text-shadow 0.5s ease-in-out;

  &:hover {
    text-shadow: 0 0 25px rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 768px) {
    text-align: center; /* Centraliza o subtítulo */
    padding-left: 0;
    font-size: 25px;
  }
`;

const SubTitle2 = styled.h3`
  font-family: 'Brmalls';
  font-size: 40px;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
  letter-spacing: 4px;
  margin-bottom: 8px;
  top: -2px;
  position: relative;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  transition: text-shadow 0.5s ease-in-out;
  
  &:hover {
    text-shadow: 0 0 25px rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 768px) {
    text-align: center;
    padding-left: 10px;
    font-size: 25px;
  }
`;

const Title = styled.h1`
  font-family: 'Brmalls';
  font-size: 40px;
  font-weight: bold;
  color: #e0e0e0;
  text-align: center; // Alinhamento à direita para garantir a consistência
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center; // Centraliza o título em dispositivos móveis
  }
`;

const Title1 = styled.h1`
  font-family: 'Brmalls';
  font-size: 40px;
  font-weight: bold;
  color: #e0e0e0;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center; /* Centraliza o título */
  }
`;

const Title2 = styled.h1`
  font-family: 'Brmalls';
  font-size: 40px;
  font-weight: bold;
  color: #e0e0e0;
  text-align: center; // Alinhamento à direita para garantir a consistência
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center;
  }
`;

const MotionUnderline = styled(motion.div)`
  height: 3px;
  background-color: #354A81;
  margin: 10px auto;

  @media (max-width: 768px) {
    margin-center: auto; // Move o underline para a direita
    margin-center: 0; // Garante que não haja centralização residual
  }
`;

const MotionUnderline1 = styled(motion.div)`
  height: 3px;
  background-color: #354A81;
  margin: 10px auto;

  @media (max-width: 768px) {
    margin: 10px auto; /* Centraliza o underline */
  }
`;

const MotionUnderline2 = styled(motion.div)`
  height: 3px;
  background-color: #354A81;
  margin: 10px auto;

  @media (max-width: 768px) {
    margin-left: auto; // Move o underline para a direita
    margin-center: 0; // Garante que não haja centralização residual
  }
`;


const Text = styled.p`
  font-family: 'Brmalls';
  font-size: 18px;
  line-height: 1.5;
  color: #CBCACA;
  text-align: center; // Alinhamento à direita para garantir a consistência
  max-width: 600px;
  margin: 20px auto;

   @media (max-width: 768px) {
    font-size: 14px;
    max-width: 100%;
    text-align: center; // Centraliza o texto em dispositivos móveis
  }
`;

const Text1 = styled.p`
  font-family: 'Brmalls';
  font-size: 18px;
  line-height: 1.5;
  color: #CBCACA;
  text-align: center;
  max-width: 600px;
  margin: 20px auto;

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 90%;
    text-align: center; /* Centraliza o texto */
  }
`;

const Text2 = styled.p`
  font-family: 'Brmalls';
  font-size: 18px;
  line-height: 1.5;
  color: #CBCACA;
  text-align: center; // Alinhamento à direita para garantir a consistência
  max-width: 600px;
  margin: 20px auto;

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 90%;
    text-align: center;
  }
`;


const VideoBackground = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 1;

  /* Para monitores (resoluções maiores que 768px) */
  @media (min-width: 768px) {
    width: 100vw;
    height: 100vh;
  }

  /* Para dispositivos móveis (resoluções de 768px ou menores) */
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    max-width: 100vw;
    max-height: 100vh;
    margin-top: 60px;
  }
`;



const SectionNew = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  padding: 0 40px;
  background-color: #000;
  z-index: 0;

  @media (max-width: 768px) {
    margin-top: -50px; /* Ajuste este valor conforme necessário */
    padding-top: 10px; /* Pode adicionar padding superior para controlar a posição */
  }
`;


const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  flex-direction: column; // Padrão para dispositivos móveis: texto em cima, imagem embaixo

  @media (min-width: 768px) {
    flex-direction: row-reverse; // Inverte a ordem para resolução de PC
  }
`;


const ContentCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; // Centraliza o texto em dispositivos móveis
  z-index: 3;

  @media (min-width: 768px) {
    width: 50%; // Usa metade da largura em resoluções maiores
    padding: 10px;
  }
`;


const SectionDescription = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: #000;
  color: #fff;
  padding-top: 180px;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 20px; /* Reduz o padding superior para subir a seção */
    margin-top: -50px; /* Ajuste este valor para controlar a posição */
    text-align: center; /* Centraliza o conteúdo em dispositivos móveis */
  }
`;


const DescriptionContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column; /* Alinha o texto acima da imagem */
    text-align: center; /* Centraliza o texto */
    align-items: center; /* Centraliza tudo horizontalmente */
  }
`;

// Defina StyledExtraImage para aplicar os estilos personalizados
const StyledExtraImage = styled(motion.img)`
  width: 50%; // Largura padrão para telas maiores
  height: auto;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 90%; // Ajusta a largura para dispositivos móveis
    margin-right: 0; // Remove margem direita para centralizar
    margin-left: auto; // Empurra a imagem para a direita
    margin-top: 10px; // Espaço entre o texto e a imagem
    display: block;
  }
`;



export default NextSection;