// src/components/Equipe.jsx

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faHandsHelping, faRecycle } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie'; // Certifique-se de que react-lottie está instalado
import scrollAnimation from '../../assets/lottie/scrollAnimation.json'; // Atualize o caminho conforme necessário
import { useInView } from 'react-intersection-observer'; // Importando o hook
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from '../Home/Footer';

const letterAnimation = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.05, // Delay entre as letras
    },
  }),
};

const fadeInLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};




const Equipe = () => {

  const { ref: quemSomosRef, inView: isQuemSomosInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: missaoRef, inView: isMissaoInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: servicosRef, inView: isServicosInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: clientesRef, inView: isClientesInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: inovacaoRef, inView: isInovacaoInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: sobreRef, inView: isSobreInView } = useInView({ triggerOnce: true, threshold: 0.2 });

  const sectionRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
      const observer = new IntersectionObserver(
          (entries) => {
              entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                      entry.target.classList.add("is-visible");
                  } else {
                      entry.target.classList.remove("is-visible");
                  }
              });
          },
          { threshold: 0.1 }
      );

      const section = sectionRef.current;
      if (section) observer.observe(section);

      return () => {
          if (section) observer.unobserve(section);
      };
  }, []);

  const title = "Sobre";

  const values = [
    {
      title: 'Compromisso com a Qualidade',
      description: 'Nossos sistemas de refrigeração são desenvolvidos com os mais altos padrões de qualidade, garantindo desempenho superior e durabilidade.',
      icon: faLightbulb // Ícone de inovação
    },
    {
      title: 'Inovação e Sustentabilidade',
      description: 'Buscamos continuamente soluções inovadoras que minimizem o impacto ambiental, utilizando tecnologias que promovem eficiência energética e sustentabilidade.',
      icon: faRecycle // Ícone de sustentabilidade
    },
    {
      title: 'Atendimento Personalizado',
      description: 'Cada cliente é único. Oferecemos consultoria e suporte personalizados para garantir que cada projeto atenda perfeitamente às necessidades específicas.',
      icon: faHandsHelping // Ícone de atendimento
    }
  ];

  const services = [
    {
      title: 'Qualidade do Início ao Fim',
      description: 'Mantemos o controle total do processo de fabricação, do começo ao fim, para assegurar que cada sistema de refrigeração entregue esteja alinhado aos mais altos padrões de qualidade.',
      image: 'images/image/img1.jpg',
      altText: 'Qualidade do Início ao Fim'
    },
    {
      title: 'Consultoria e Projetos Personalizados',
      description: 'Oferecemos soluções sob medida para atender as demandas específicas de cada cliente, garantindo que cada projeto de refrigeração seja otimizado para suas necessidades.',
      image: 'images/image/img1.jpg',
      altText: 'Consultoria e Projetos Personalizados'
    },
    {
      title: 'Manutenção Preventiva e Corretiva',
      description: 'Nossos serviços de manutenção garantem que seus sistemas de refrigeração estejam sempre funcionando com a máxima eficiência, evitando problemas futuros e custos desnecessários.',
      image: 'images/image/img1.jpg',
      altText: 'Manutenção Preventiva e Corretiva'
    }
  ];

  const clients = [
    {
        name: "Big bread",
        image: "/images/3d/bigbread.png",
        link: "https://www.instagram.com/bigbreadipiranga/"
    },
    {
        name: "Cepam",
        image: "/images/3d/cepam.jpg",
        link: "https://www.instagram.com/padocacepam/?hl=pt"
    },
    {
        name: "Carlo´s Bakery",
        image: "/images/3d/carlos.png",
        link: "https://carlosbakery.com.br/"
    },
    {
        name: "Padaria Colonial",
        image: "/images/3d/colonial.jpg",
        link: "https://www.instagram.com/colonialpadariaoficial/"
    }
];



const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  responsive: [
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
          }
      }
  ],
  appendDots: dots => (
      <Dots>
          <ul> {dots} </ul>
      </Dots>
  ),
  customPaging: i => (
      <RectDotWrapper>
          <RectDot className={i === currentSlide ? 'slick-active' : ''} />
      </RectDotWrapper>
  ),
  beforeChange: (current, next) => setCurrentSlide(next),
};

  const [currentSection, setCurrentSection] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);


  const scrollToHistoria = () => {
    const section = document.getElementById('historia');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setCurrentSection('historia');
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: scrollAnimation, // Seu arquivo de animação Lottie
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Container>
      {/* Seção "História da Empresa" */}
      <Section2 id="historia">
        <motion.div
          style={{
            position: 'relative',
            backgroundImage: `url('images/aer.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            textAlign: 'center',
          }}
          initial={{ opacity: 0, scale: 1.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.2 }} // Duração da animação
        >
          {/* Overlay escuro */}
          <motion.div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 1,
            }}
          ></motion.div>

          {/* Texto focado */}
          <motion.div
            style={{ zIndex: 2 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }} // Duração e delay para entrada do texto
          >
            <h1
              style={{
                fontSize: '4rem',
                marginBottom: '20px',
                fontWeight: 'bold',
                textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              }}
            >
              História
            </h1>
          </motion.div>
        </motion.div>
      </Section2>

      {/* Seção "Quem Somos" */}
      {/* Seção "Quem Somos" */}
      <Section id="quem-somos" ref={quemSomosRef}>
  
    <SectionTitle>
      <motion.img
        src="/images/logo/menu.png"
        alt="Logo"
        style={{ width: '50px', height: '50px' }}
        initial="hidden"
        animate={isQuemSomosInView ? "visible" : "hidden"}
        variants={fadeInLeft}
        transition={{ duration: 0.9, ease: "easeOut" }}
      />
      <motion.h1
        initial="hidden"
        animate={isQuemSomosInView ? "visible" : "hidden"}
        variants={fadeIn}
        transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
      >
        Quem Somos
      </motion.h1>
    </SectionTitle>
    <QuemSomosContainer>
    <TitleText
      initial="hidden"
      animate={isQuemSomosInView ? 'visible' : 'hidden'}
    >
      Sobre
    </TitleText>

    <MissionText
      initial="hidden"
      animate={isQuemSomosInView ? 'visible' : 'hidden'}
      variants={fadeIn}
      transition={{ duration: 1, delay: 0.5 }}
    >
      Nossa missão é proporcionar serviços de alta qualidade e inovadores para nossos clientes, promovendo um ambiente sustentável e inclusivo.
    </MissionText>

    <ImageContainer
      initial={{ opacity: 0, x: 50 }}
      animate={isQuemSomosInView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1.2, ease: "easeOut", delay: 0.5 }}
    >
      <img
        src="/images/ursopreto.png"
        alt="Imagem da Missão"
      />
    </ImageContainer>
  </QuemSomosContainer>
</Section>

      {/* Seção "Nossos Valores" */}
      <Section id="missao" ref={missaoRef}>
        <SectionTitle>
          <motion.img
            src="/images/logo/menu.png"
            alt="Logo"
            style={{ width: '40px', height: '40px' }}
            initial="hidden"
            animate={isMissaoInView ? "visible" : "hidden"}
            variants={fadeInLeft}
            transition={{ duration: 0.9, ease: "easeOut" }}
          />

          <motion.h1
            initial="hidden"
            animate={isMissaoInView ? "visible" : "hidden"}
            variants={fadeIn}
            transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          >
            Nossos Valores
          </motion.h1>
        </SectionTitle>

        <h2 style={{
          fontSize: '2.5rem',
          fontWeight: 'bold',
          marginBottom: '50px',
          marginTop: '30px',
          color: '#000',
          textAlign: 'center'
        }}>Porque escolher a AER?</h2>

        {/* Conteúdo da seção "Valores" */}
        <ValoresContainer>
          {values.map((value, index) => (
            <Valor key={index}>
              <FontAwesomeIcon icon={value.icon} />
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </Valor>
          ))}
        </ValoresContainer>
      </Section>

      {/* Seção "Serviços" */}
      {/* <Section id="servicos" ref={servicosRef}>
        <SectionTitle>
          <motion.img
            src="/images/logo/menu.png"
            alt="Logo"
            style={{ width: '40px', height: '40px' }}
            initial="hidden"
            animate={isServicosInView ? "visible" : "hidden"}
            variants={fadeInLeft}
            transition={{ duration: 0.9, ease: "easeOut" }}
          />

          <motion.h1
            initial="hidden"
            animate={isServicosInView ? "visible" : "hidden"}
            variants={fadeIn}
            transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          >
            Serviços
          </motion.h1>
        </SectionTitle>

        <ServiceContainer>
          {services.map((service, index) => (
            <ServiceItem key={index} reverse={index % 2 !== 0}>
              <ServiceText>
                <ServiceSubtitle>THE NATURAL CHOICE</ServiceSubtitle>
                <h3>{service.title}</h3>
                <Underline />
                <p>{service.description}</p>
              </ServiceText>
              <ServiceImage>
                <img src={service.image} alt={service.altText} />
              </ServiceImage>
            </ServiceItem>
          ))}
        </ServiceContainer>
      </Section> */}

      {/* Seção "Clientes e Parcerias" */}
      <Section id="clientes" ref={clientesRef}>
        <SectionTitle>
          <motion.img
            src="/images/logo/menu.png"
            alt="Logo"
            style={{ width: '40px', height: '40px' }}
            initial="hidden"
            animate={isClientesInView ? "visible" : "hidden"}
            variants={fadeInLeft}
            transition={{ duration: 0.9, ease: "easeOut" }}
          />

          <motion.h1
            initial="hidden"
            animate={isClientesInView ? "visible" : "hidden"}
            variants={fadeIn}
            transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          >
            Clientes
          </motion.h1>
        </SectionTitle>
        <SectionContainer ref={sectionRef}>
            <Title>Padarias</Title>
            <SliderContainer>
                <Slider {...settings}>
                    {clients.map((client, index) => (
                        <ClientCard key={index} className="client-card">
                            <a href={client.link} target="_blank" rel="noopener noreferrer">
                                <ClientImage src={client.image} alt={client.name} />
                                <ClientName>{client.name}</ClientName>
                            </a>
                        </ClientCard>
                    ))}
                </Slider>
            </SliderContainer>
        </SectionContainer>
      </Section>
      <Footer />
    </Container>
  );
};

// Styled Components

const Container = styled.div`
  font-family: 'Arial, sans-serif';
  color: #000;
  position: relative;
`;

// Estilos para o menu com animações
const PageMenu = styled.div`
  position: fixed;
  top: 50%;
  left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform: translateY(-50%);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const MenuDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 50%;
  position: relative;

  &.active {
    background-color: #fff;
  }
`;

const MenuText = styled(motion.span)`
  margin-left: 10px;
  color: #999;
  font-family: 'Arial, sans-serif';

  &.active {
    color: #fff;
  }
`;

const Section = styled.section`
  min-height: 50vh;
  margin: 0;
  padding: 40px 50px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const QuemSomosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
    text-align: center;
  }
`;

const Section2 = styled.section`
  /* Seu estilo existente */
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 20px;

    @media (max-width: 768px) {
      font-size: 1.2rem; /* Ajuste do tamanho da fonte do título */
      margin-left: 10px;
    }
  }
`;

const ClientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  max-width: 45%;
  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 15px;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 50%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CarouselImage = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  ${({ direction }) =>
    direction === 'left' ? 'left: 10px;' : 'right: 10px;'}

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Underline = styled.div`
  width: 100px;
  height: 3px;
  background-color: #0044cc;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 80px;
  }
`;

const ValoresContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
    gap: 15px;
  }
`;

const Valor = styled.div`
  width: 30%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  svg {
    font-size: 50px;
    color: #0044cc;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }

  @media (max-width: 768px) {
    width: 100%;

    svg {
      font-size: 40px;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    gap: 30px;
  }
`;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ServiceText = styled.div`
  max-width: 50%;
  text-align: left;

  h3 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.1rem;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;

    h3 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const ServiceSubtitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #666;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ServiceImage = styled.div`
  max-width: 45%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const LottieContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000; /* Garante que fique acima de outros elementos */

  /* Opcional: adicionar sombra ou outros estilos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
`;

const SectionContainer = styled.div`
  padding: 60px 20px;
  background-color: #fff;
  color: #333;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 80px;
  color: #333;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 50px;
  }
`;

const SliderContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ClientCard = styled.div`
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ClientImage = styled.img`
  width: 150px;
  height: auto;
  margin: 0 auto;
  transition: transform 0.3s ease;

  ${ClientCard}:hover & {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 100px;
  }
`;

const ClientName = styled.h3`
  font-size: 1.2rem;
  margin-top: 15px;
  color: #555;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Dots = styled.div`
  ul {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  li {
    margin: 0 8px;
    button {
      width: 30px;
      height: 10px;
      background-color: #bbb;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333;
      }

      &.slick-active {
        background-color: #333;
      }
    }
  }
`;

const RectDotWrapper = styled.div`
  display: inline-block;
  margin: 0 8px;
`;

const RectDot = styled.div`
  width: 20px;
  height: 5px;
  background-color: #bbb;
  border-radius: 1px;
  transition: background-color 0.3s ease;

  &.slick-active {
    background-color: #333;
  }

  &:hover {
    background-color: #333;
  }
`;
const TitleText = styled(motion.h1)`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2rem; /* Reduz o tamanho do título no mobile */
    line-height: 1.1;
  }
`;

const MissionText = styled(motion.p)`
  font-size: 1rem;
  max-width: 80%;
  line-height: 1.5;
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduz o tamanho do texto da missão no mobile */
    max-width: 90%;
  }
`;

const ImageContainer = styled(motion.div)`
  max-width: 300px;
  margin: 20px 0;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    max-width: 200px; /* Reduz o tamanho da imagem no mobile */
  }
`;

export default Equipe;
