/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MesaCmPia(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Mobiliario/Mesa%20com%20pia/mesa_com_pia.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.12, 0, -5.63]} rotation={[0, Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0005.geometry}
          material={materials.Material_0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0005_1.geometry}
          material={materials['satin finish nickel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0005_2.geometry}
          material={materials['pw-mt11000-4']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Mobiliario/Mesa%20com%20pia/mesa_com_pia.glb')