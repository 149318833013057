/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MesaLixo(props) {
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Mobiliario/Mesa%20lixeira/MESA%20LIXEIRA.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.11, 0, -9.66]} rotation={[0, Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007.geometry}
          material={materials['satin finish nickel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007_1.geometry}
          material={materials['color-6']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007_2.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007_3.geometry}
          material={materials['color-1.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007_4.geometry}
          material={materials['color-5.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007_5.geometry}
          material={materials['pw-mt11000-4']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0007_6.geometry}
          material={materials['pw-mt11000-4']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Mobiliario/Mesa%20lixeira/MESA%20LIXEIRA.glb')

