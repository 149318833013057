import React, { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { Box3, Vector3, Color } from 'three';

export function BalcaoHz({ color, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF('https://storage.googleapis.com/glb-aer/Balcao/boxhz/balc%C3%A3o_horizontal.glb');

  useEffect(() => {
    if (group.current) {
      const box = new Box3().setFromObject(group.current);
      const center = new Vector3();
      box.getCenter(center);
      group.current.position.set(-center.x, -center.y - 0.14, -center.z);
      group.current.geometry && group.current.geometry.translate(center.x, center.y, center.z);
    }
  }, []);

  useEffect(() => {
    if (materials['pintar']) {
      materials['pintar'].color = new Color(color);
    }
    if (materials['pintar 2']) {
      materials['pintar 2'].color = new Color(color);
    }
  }, [color, materials]);

  return (
    <group ref={group} {...props} dispose={null} scale={[1, 1, 1]}>
      <group>
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013.geometry} material={materials['Material_0.001']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_1.geometry} material={materials['pintar 2']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_2.geometry} material={materials['red high gloss plastic.001']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_3.geometry} material={materials['clear glass.006']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_4.geometry} material={materials['amber led.001']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_5.geometry} material={materials['pw-mt11040-4.001']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_6.geometry} material={materials['pw-mt11040-4.001']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_7.geometry} material={materials['color-3.002']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_8.geometry} material={materials['dark grey high gloss plastic']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_9.geometry} material={materials['color-4.004']} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_10.geometry} material={materials.pintar} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_11.geometry} material={materials.pintar} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_0013_12.geometry} material={materials['satin finish nickel.001']} />
      </group>
    </group>
  );
}

useGLTF.preload('https://storage.googleapis.com/glb-aer/Balcao/boxhz/balc%C3%A3o_horizontal.glb');
